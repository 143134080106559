import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { connectModule } from 'redux-modules';
import { compose } from 'recompose';

import loopProvider from '../../../decorators/loopProvider';
import mealsModule from '../../../redux/modules/meals';

import Lifecycle from '../../../decorators/Lifecycle';
import CardWrapper from '../../../components/Navigation/CardWrapper';

const I18n = window.I18n;

const ShopView = ({ shops, match, actions, history }) => {
  const shop = shops.find( e => e.id === parseInt(match.params.id) ) || { private: false, public: false, active: false}
  const shop_roles = shop.shop_roles || []
  const [shopRoleItems, setShopRoleItems] = useState(shop_roles);
  const formRef = useRef();

  useEffect( () => {
    actions.setShopFormElement(formRef.current)
  }, [])

  useEffect( () => {
    setShopRoleItems(shop_roles)
  }, [shops])

  return(
    <Lifecycle
      onMount={()=> {
        if (shops.length === 0) {
          actions.fetchShops()
            .then( () => actions.fetchShop({ id: match.params.id }) )
        } else {
          actions.fetchShop({ id: match.params.id })
        }
      }}
    >
      <CardWrapper title={I18n.t('views.cards.settings')} className={'shops'} match={match} history={history}>
        <h2>{I18n.t('views.cards.shop')}</h2>
        <form ref={formRef} action="">
          <input type="text" name='shop[id]' defaultValue={shop.id} autoComplete="off" hidden={true}/>
          <ul>
            <li>{I18n.t('views.generic.name')}</li>
            <li>{shop.name}</li>
          </ul>
          <ul>
            <li>{I18n.t('views.generic.phone')}</li>
            <li>{shop.phone}</li>
          </ul>
          <ul>
            <li>{I18n.t('views.generic.address')}</li>
            <li>{shop.address}</li>
          </ul>
          <ul>
            <li>{I18n.t('views.generic.private')}</li>
            <li>{I18n.t(Boolean(shop.private).toString(), {scope: 'views.generic.states'})}</li>
          </ul>
          <ul>
            <li>{I18n.t('views.generic.active')}</li>
            <li>{I18n.t(Boolean(shop.active).toString(), {scope: 'views.generic.states'})}</li>
          </ul>
          <ul>
            <li>{I18n.t('views.generic.public')}</li>
            <li>{I18n.t(Boolean(shop.public).toString(), {scope: 'views.generic.states'})}</li>
          </ul>
          <ul className='header'>
            <li>{I18n.t('views.cards.forms.shop.member')}</li>
            <li>{I18n.t('views.cards.forms.shop.role')}</li>
          </ul>
          {
            shopRoleItems.map( (i, index) =>
              <ul key={`${i.id}_${index}`}>
                <li>{i.user.email}</li>
                <li>{I18n.t(i.type, {scope: 'views.cards.forms.shop.roles'})}</li>
              </ul>
            )
          }
        </form>
      </CardWrapper>
    </Lifecycle>
  )
}

ShopView.propTypes = {
  actions: PropTypes.shape({
    fetchShops: PropTypes.func,
    fetchShop: PropTypes.func,
    setShopFormElement: PropTypes.func
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.object
  }),
  shops: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      phone: PropTypes.string,
      address: PropTypes.string,
      shop_roles: PropTypes.array,
    })
  )
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(ShopView);
