import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import {
  translatableName,
  translatableWithIngredientIdName
} from '../../shared/utils';

const I18n = window.I18n;

const IngredientInput = ({ itemName, item, expand_form, ingredients, isOffered = () => false }) =>
  expand_form ?
      <li className='name'>
        <select name={`${itemName}[ingredients][name][]`} defaultValue={item.name}>
          <option key='null' value='' defaultValue=''>{I18n.t('views.cards.actions.quick_select')}</option>
          {
            ingredients.map( e =>
              <option key={`opt${e.id}`} value={e.name} selected={e.name === item.name}>{translatableName(e)}</option> )
          }
        </select>
      </li> :
      <li className='name'>
        <span className={cn({ green: isOffered(item) })}>{translatableWithIngredientIdName(item, ingredients)}</span>
        <input type="text" name={`${itemName}[ingredients][name][]`} defaultValue={item.name} autoComplete="off" hidden={true}/>
      </li>

IngredientInput.propTypes = {
  itemName: PropTypes.string,
  item: PropTypes.shape({
      name: PropTypes.string
  }),
  ingredients: PropTypes.array,
  expand_form: PropTypes.bool,
  isOffered: PropTypes.func,
}

export default IngredientInput;
