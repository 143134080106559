/*
  loopProvider

  Features:
    ModuleProvider
    redux-loop
    shared provider functionality

  Props:
    providerKey
 */
import loopProvider from './loopProvider';
export default loopProvider;
