import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';

import { compose } from 'recompose';
import { connectModule } from 'redux-modules';

import Lifecycle from '../../decorators/Lifecycle';
import loopProvider from '../../decorators/loopProvider';
import mealsModule from '../../redux/modules/meals';
import { parseDate, orderByName, orderBy } from '../../shared/utils';

const I18n = window.I18n;

const MealsForm = ({chef_guests, selectRecipes, meal, meals, user, actions, }) => {
  const { active_diets: { meal_periods } } = (user.reports || { active_diets: { meal_periods: [] } });

  const [mealItem, setMealItem] = useState({});

  const [guests, setGuests ] = useState(meal.meal_dishes.map(e => e.chef_guest.name ));
  const [recipes, setRecipes ] = useState(meal.meal_dishes.map(e => e.recipe.name ));

  const [newGuest, setNewGuest] = useState("");
  const [newRecipe, setNewRecipe] = useState("");

  const formRef = useRef();

  const mealNameRef = useRef();
  const mealDateRef = useRef();

  const guestSelect = useRef();
  const recipeSelect = useRef();

  const guestInput = useRef();
  const recipeInput = useRef();

  const onGuestChange = e => {
    const newValue = e.currentTarget.value;
    guestInput.current.value = newValue;

    setGuests(prev => [...prev, newValue])

    guestInput.current.value = '';
    guestSelect.current.value = '';
  }

  const onRecipeChange = e => {
    const newValue = e.currentTarget.value;
    recipeInput.current.value = newValue;

    setRecipes(prev => [...prev, newValue])

    if (!mealNameRef.current.value) {
      mealNameRef.current.value = newValue;
    }

    recipeInput.current.value = ''
    recipeSelect.current.value = '';
  }

  const removeDish = (e,index) => {
    e.preventDefault();

    setGuests(prev => prev.filter((_,i) => i !== index))
    setRecipes(prev => prev.filter((_,i) => i !== index))
  }

  const selectMeals = uniqBy(orderByName(meals), 'name');
  const mealNameSelectRef = useRef();

  const onMealNameChange = e => {
    const newValue = e.currentTarget.value;

    mealNameRef.current.value = newValue;
    mealNameSelectRef.current.value = '';
  }

  const updateNewRecipe = target => {
    setNewRecipe( () => target.value )
  }

  const appendNewRecipe = () => {
    if (!newRecipe) return
    if (!recipes.indexOf(newRecipe) === -1) return

    setRecipes(prev => [...prev, newRecipe])

    if (!mealNameRef.current.value) {
      mealNameRef.current.value = newRecipe;
    }

    recipeInput.current.value = '';
  }

  const recipeOptions = (selectRecipes, recipes) => {
    if (recipes.slice()[0]) {
      return orderBy(uniq([...selectRecipes,...recipes]))
    }
    return selectRecipes
  }

  const updateNewGuest = target => {
    setNewGuest( () => target.value )
  }

  const appendNewGuest = () => {
    if (!newGuest) return
    if (!guests.indexOf(newGuest) === -1) return

    setGuests(prev => [...prev, newGuest])

    guestInput.current.value = '';
  }

  const guestOptions = (chef_guests, guests) => {
    if (guests.concat().slice()[0]) {
      return orderByName(
        uniqBy([
          ...chef_guests,
          ...guests.map( e => ({name: e}) )
          ],
          'name')
        )
    }
    return chef_guests
  }

  useEffect(() => {
    actions.setMealFormElement({meal: formRef.current})
  }, [])

  useEffect(() => {
    setMealItem(meal)
    setGuests(meal.meal_dishes.map(e => e.chef_guest.name ))
    setRecipes(meal.meal_dishes.map(e => e.recipe.name ))
  }, [meal])

  return(
    <Lifecycle
      onMount={()=> {}}
    >
      <form ref={formRef} action="" className='meal'>
        <input type="text" name='meal[id]' defaultValue={meal.id} autoComplete="off" hidden={true}/>
        <input type="text" name='meal[chef_id]' defaultValue={meal.chef_id || user.chef_id} autoComplete="off" hidden={true}/>
        <div className='fieldset'>
          <label htmlFor="meal-name">{I18n.t('views.cards.forms.meal.name')}</label>
          <div className='meal-name-select'>
            <input
              ref={mealNameRef}
              type="text"
              name='meal[name]'
              defaultValue={meal.name}
              autoComplete="off"
            />
            <select  onChange={onMealNameChange} ref={mealNameSelectRef} disabled={false}>
              <option key='null' value='' defaultValue=''>{I18n.t('views.cards.actions.quick_select')}</option>
              { selectMeals.map( e => <option key={`opt${e.id}`} value={e.name}>{e.name}</option> ) }
            </select>
          </div>
        </div>
        <div className='fieldset'>
          <label htmlFor="meal-name">{I18n.t('views.generic.date')}</label>
          <input
            ref={mealDateRef}
            type='date'
            name='meal[date]'
            autoComplete="off"
            defaultValue={
              parseDate(meal.date)
            }
          />
        </div>
        <div className='fieldset'>
          <label htmlFor="meal-type">{I18n.t('views.cards.forms.meal.meal_period')}</label>
          <select defaultValue={mealItem.meal_period || 'lunch'} name='meal[meal_period]'>
            <option key='null' value='' defaultValue=''></option>
            {
              meal_periods.map( type =>
                <option key={`opt_${type}`} value={type} selected={mealItem.meal_period === type}>{ I18n.t(type, { scope: 'views.cards.forms.diet.meals.meal_periods'})}</option>
              )
            }
          </select>
        </div>
        <div className='fieldset'>
          <label htmlFor="meal-done">{I18n.t('views.cards.forms.meal.done')}</label>
          <input type="checkbox"
            name="meal[done]"
            defaultValue={Boolean(mealItem.done).toString()}
            checked={Boolean(mealItem.done).toString() === 'true'}
            onChange={ () => {
              setMealItem( prev => ({...prev, done: !prev.done }))
            }}
            autoComplete="off"
          />
        </div>
        <ul className='header'>
          <li className='quest-name'>{I18n.t('views.cards.forms.meal.guest')}</li>
          <li className='guest-dish'>{I18n.t('views.cards.forms.meal.recipe')}</li>
        </ul>
        {
          guests.map( (guest,index) =>
            <ul className='input-list' key={`${guest}_${index}`}>
              <li>{guest}</li>
              <input type="text" name='meal[guest][name][]' defaultValue={guest} autoComplete="off" hidden={true}/>
              <li>{recipes[index]}</li>
              <input type="text" name='meal[guest][recipe][]' defaultValue={recipes[index]} autoComplete="off" hidden={true}/>
              <li><button onClick={ e => removeDish(e,index) }>x</button></li>
            </ul>
          )
        }
        <ul>
          <li className='quest-name'>
            <input
              onChange={ e => updateNewGuest(e.target) }
              onBlur={ e => appendNewGuest(e.target) }
              ref={guestInput}
              type="text"
              name=''
              autoComplete="off"
            />
          </li>
          <li className='guest-dish'>
            <input ref={recipeInput}
              onChange={ e => updateNewRecipe(e.target) }
              onBlur={ e => appendNewRecipe(e.target) }
              type="text"
              name=''
              autoComplete="off"
            />
          </li>
        </ul>
        <ul>
          <li className='quest-name'>
            <select onChange={onGuestChange} ref={guestSelect}>
              <option key='null' value='' defaultValue=''>{I18n.t('views.cards.actions.quick_select')}</option>
              { guestOptions(chef_guests, guests).map( e => <option key={`opt_${e.name}`} value={e.name}>{e.name}</option> ) }
            </select>
          </li>
          <li className='quest-dish'>
            <select onChange={onRecipeChange} ref={recipeSelect} disabled={guests.length === recipes.length}>
              <option key='null' value='' defaultValue=''>{I18n.t('views.cards.actions.quick_select')}</option>
              { recipeOptions(selectRecipes, recipes).map( e => <option key={`opt${e}`} value={e}>{e}</option> ) }
            </select>
          </li>
        </ul>
      </form>
    </Lifecycle>
  )
};
MealsForm.defaultProps = {
  selectRecipes: [],
  meal: {
    meal_dishes: []
  },
  chef_guests: []
};

MealsForm.propTypes = {
  selectRecipes: PropTypes.array,
  meal: PropTypes.object,
  actions: PropTypes.shape({
    setMealFormElement: PropTypes.any
  }),
  meals: PropTypes.array,
  user: PropTypes.shape({
    chefs: PropTypes.array,
    chef_id: PropTypes.number,
    reports: PropTypes.shape({
      active_diets: PropTypes.object
    })
  }),
  chef_guests: PropTypes.array,
};

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(MealsForm);
