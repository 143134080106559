import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router';

import ChatMain from '../Chat/ChatMain';
import Appearance from '../../components/Appearance/Appearance';
import CardNavigation from '../../components/CardNavigation';

import Dashboard from '../Dashboard';
import Meals from '../MealsIndex';
import Recipes from '../RecipesIndex';
import Orders from '../OrdersIndex';
import Stock from '../StocksIndex';
import Diets from '../DietsIndex';
import Settings from '../Settings';
import Ingredients from '../Settings/Ingredients';
import Categories from '../Settings/Categories';
import RecipesCategories from '../Settings/RecipesCategories';
import Shops from '../Settings/Shops';
import User from '../Settings/User';
import Members from '../Members';
import Modal from '../../components/Modal';
import { BrowserRouter } from 'react-router-dom';
import { ROOT_URL } from '../../shared/config';

const Authenticated = ({ routeState, setRouteState }) => {
  const root = ROOT_URL.length > 1 ? ROOT_URL : '';
  return(
    <>
      <Appearance />
      <CardNavigation routeState={routeState}/>
      <main>
        <BrowserRouter
          initialEntries={[`${root}`]}
          initialIndex={0}
        >
          <Route path={`${ROOT_URL}`} exact render={  routeProps =>
            <Dashboard setRouteState={ () => setRouteState(routeProps)} routeState={routeState}/>
          }/>
          <Route path={`${root}/meals`} render={  routeProps =>
            <Meals setRouteState={ () => setRouteState(routeProps) } />
          }/>
          <Route path={`${root}/recipes`} render={  routeProps =>
            <Recipes setRouteState={ () => setRouteState(routeProps) } />
          }/>
          <Route path={`${root}/orders`} render={ routeProps =>
            <Orders setRouteState={ () => setRouteState(routeProps) } />
          }/>
          <Route path={`${root}/stock`} render={  routeProps =>
            <Stock setRouteState={ () => setRouteState(routeProps) } />
          }/>
          <Route path={`${root}/diets`} render={  routeProps =>
            <Diets setRouteState={ () => setRouteState(routeProps) } />
          }/>
          <Route path={`${root}/settings`} render={ routeProps =>
            <Settings
              setRouteState={ () => setRouteState(routeProps) }
              {...routeProps}
            />
          }/>
          <Route path={`${root}/ingredients`} render={ routeProps =>
            <Ingredients setRouteState={ () => setRouteState(routeProps) } />
          }/>
          <Route path={`${root}/categories`} render={ routeProps =>
            <Categories setRouteState={ () => setRouteState(routeProps) } />
          }/>
          <Route path={`${root}/recipes_categories`} render={ routeProps =>
            <RecipesCategories setRouteState={ () => setRouteState(routeProps) } />
          }/>
          <Route path={`${root}/shops`} render={ routeProps =>
            <Shops setRouteState={ () => setRouteState(routeProps) } />
          }/>
          <Route path={`${root}/my`} render={ routeProps =>
            <User setRouteState={ () => setRouteState(routeProps) } />
          }/>
          <Route path={`${root}/members`} render={  routeProps =>
            <Members setRouteState={ () => setRouteState(routeProps) } />
          }/>
          <Route path={`${root}/chat`} render={ routeProps =>
            <ChatMain setRouteState={ () => setRouteState(routeProps) } />
          }/>
        </BrowserRouter>
        <Modal />
      </main>
    </>
  )
}

Authenticated.propTypes = {
  routeState: PropTypes.object,
  setRouteState: PropTypes.func
}

export default Authenticated