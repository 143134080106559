import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TrixEditor } from "react-trix";

import { compose } from 'recompose';
import { connectModule } from 'redux-modules';

import Lifecycle from '../../decorators/Lifecycle';

import loopProvider from '../../decorators/loopProvider';
import mealsModule from '../../redux/modules/meals';

import CardWrapper from '../../components/Navigation/CardWrapper';
import { IngredientForm } from '../../components/Ingredients';

import { translatableName, fetchIfEmpty, translatableAttribute } from '../../shared/utils';
import { getCSRFToken } from '../../services/apiService';

const I18n = window.I18n;

const RecipesEdit = ({ recipes=[], ingredients, categories, recipes_categories, actions, match, user, history }) => {
  const recipe = recipes.find( e => e.id === parseInt(match.params.id) ) || {};
  const recipe_ingredients = (recipe.recipe_ingredients || []);
  const [recipeImagesBlobIds, setRecipeImagesBlobIds] = useState([])

  const descRef         = useRef()
  const descFormatedRef = useRef()
  const instRef         = useRef()
  const instFormatedRef = useRef()

  const { expand } = (history.location.state || { expand: false });

  const inTranslation = recipe.locale !== I18n.locale

  const fetchTranslations = () => {
    fetchIfEmpty(ingredients, actions.fetchIngredients)
    fetchIfEmpty(categories, actions.fetchCategories)
    fetchIfEmpty(recipes_categories, actions.fetchRecipesCategories)
  }

  const handleEditorReady = (editor, text, html) => {
    // this is a reference back to the editor if you want to
    // do editing programatically
    if (html) {
      editor.insertHTML(html||'');
    } else {
      editor.insertString(text||'');
    }
  }

  const handleChange = (html, text, target) => {
    // html is the new html content
    // text is the new text content
    switch(target) {
      case 'description':
        descFormatedRef.current.value = html;
        descRef.current.value = text;
        break;
      case 'instruction':
        instFormatedRef.current.value = html;
        instRef.current.value = text;
        break;
    }
  }

  useEffect( () => {
    document.addEventListener("trix-attachment-add", function(event) {
      var file = event.attachment.file;
      if (file) {
        var upload = new window.ActiveStorage.DirectUpload(file,'/rails/active_storage/direct_uploads', window);
        upload.create((error, attributes) => {
          if (error) {
            return false;
          } else {
            setRecipeImagesBlobIds( prev => [...(prev||[]), attributes.id])
            return(
              event.attachment.setAttributes({
                url: `/rails/active_storage/blobs/${attributes.signed_id}/${attributes.filename}`,
                href: `/rails/active_storage/blobs/${attributes.signed_id}/${attributes.filename}`,
              })
            )
          }
        });
      }
    });
  }, [])

  useEffect( () => {
    setRecipeImagesBlobIds(recipe.images_blob_ids||[])
  }, [recipes.length])

  return (
    <Lifecycle
      onMount={()=> {
          actions.fetchRecipe({ id: match.params.id }, { meta: 'whateva' })

          if (recipe.locale !== I18n.locale) {
            fetchTranslations()
          }
        }}
      >
      <CardWrapper title={I18n.t('views.cards.recipes')} className={'recipes'} match={match} history={history}>
        <IngredientForm
          itemName='recipe'
          items={recipe_ingredients}
          recipe={recipe}
          people={recipe.people}
          expand_form={expand}
        >
          <input type="text" name='recipe[id]' defaultValue={recipe.id} autoComplete="off" hidden={true}/>
          <input type="text" name='recipe[chef_id]' defaultValue={user.chef_id} autoComplete="off" hidden={true}/>
          <div className='fieldset'>
            <label htmlFor='recipe-name'>{I18n.t('views.cards.recipe')}</label>
            {
              inTranslation ?
                recipe.name :
                <input type='text' name='recipe[name]' autoComplete="off" defaultValue={recipe.name}/>
            }
          </div>
          {
            inTranslation ?
              <div className='fieldset'>
                <label htmlFor='recipe-name'></label>
                <input type='text' name='recipe[name]' autoComplete="off" defaultValue={translatableName(recipe)}/>
              </div> : null
          }
          <div className='fieldset'>
            <label htmlFor='recipe-description'>{I18n.t('views.cards.forms.recipe.description')}</label>
          </div>
          <div className='fieldset'>
            {
              inTranslation ?
                recipe.description :
                <TrixEditor
                  style={{ width: '100%'}}
                  uploadData={{"authenticity_token": getCSRFToken()}}
                  onChange={ (html, text) => handleChange(html, text, 'description')}
                  onEditorReady={ (e) => handleEditorReady(e, recipe.description, recipe.description_f) }
                />
            }
          </div>
          {
            inTranslation ?
              <div className='fieldset'>
                <TrixEditor
                  style={{ width: '100%'}}
                  uploadData={{"authenticity_token": getCSRFToken()}}
                  onChange={ (html, text) => handleChange(html, text, 'description')}
                  onEditorReady={ (e) => handleEditorReady(e, translatableAttribute(recipe, 'description')) }
                />
              </div> : null
          }
          {
            inTranslation ?
              <textarea ref={descRef} name='recipe[description]' defaultValue={translatableAttribute(recipe, 'description')}/> :
              <textarea ref={descRef} name='recipe[description]' defaultValue={recipe.description}/>
          }
          {
            inTranslation ?
              <textarea ref={descFormatedRef} name='recipe[description_f]'/> :
              <textarea ref={descFormatedRef} name='recipe[description_f]'/>
          }
          <div className='fieldset'>
            <label htmlFor='recipe-description'>{I18n.t('views.cards.forms.recipe.instruction')}</label>
          </div>
          <div className='fieldset'>
            {
              inTranslation ?
                recipe.instruction :
                <TrixEditor
                  style={{ width: '100%'}}
                  uploadData={{"authenticity_token": getCSRFToken()}}
                  onChange={ (html, text) => handleChange(html, text, 'instruction')}
                  onEditorReady={ (e) => handleEditorReady(e, recipe.instruction, recipe.instruction_f) }
                />
            }
          </div>
          {
            inTranslation ?
              <div className='fieldset'>
                <TrixEditor
                  style={{ width: '100%'}}
                  uploadData={{"authenticity_token": getCSRFToken()}}
                  onChange={ (html, text) => handleChange(html, text, 'instruction')}
                  onEditorReady={ (e) => handleEditorReady(e, translatableAttribute(recipe, 'instruction')) }
                />
              </div> : null
          }
          {
            inTranslation ?
              <textarea ref={instRef} name='recipe[instruction]' defaultValue={translatableAttribute(recipe, 'instruction')}/> :
              <textarea ref={instRef} name='recipe[instruction]' defaultValue={recipe.instruction}/>
          }
          {
            inTranslation ?
              <textarea ref={instFormatedRef} name='recipe[instruction_f]'/> :
              <textarea ref={instFormatedRef} name='recipe[instruction_f]'/>
          }
          { recipeImagesBlobIds.map( e => <input key={e} type="text" name='recipe[images_blob_ids][]' defaultValue={e} hidden/>) }
        </IngredientForm>
      </CardWrapper>
    </Lifecycle>
  );
}

RecipesEdit.propTypes = {
  ingredients: PropTypes.array,
  categories: PropTypes.array,
  recipes_categories: PropTypes.array,
  recipes: PropTypes.array,
  actions: PropTypes.shape({
    fetchRecipe: PropTypes.func,
    fetchIngredients: PropTypes.func,
    fetchCategories: PropTypes.func,
    fetchRecipesCategories: PropTypes.func,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }).isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      state: PropTypes.object
    })
  }).isRequired,
  user: PropTypes.shape({
    chef_id: PropTypes.number
  })
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(RecipesEdit);
