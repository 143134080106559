import React from 'react';
import PropTypes from 'prop-types';
import Lifecycle from '../../../decorators/Lifecycle';
import { ROOT_URL } from '../../../shared/config';
import CardWrapper from '../../../components/Navigation/CardWrapper';
import { orderByName } from '../../../shared/utils';

const I18n = window.I18n;

export const ShopList = ({ shops, actions, history, match }) => {
  const root = ROOT_URL.length > 1 ? ROOT_URL : '';

  return(
    <Lifecycle
      onMount={()=> {
        actions.fetchShops()
      }}
    >
      <CardWrapper title={I18n.t('views.cards.settings')} className={'categories'} match={match} history={history}>
        <h2>{I18n.t('views.cards.shops')}</h2>

        {
          orderByName(shops).map( i =>
            <a className='item' key={i.id}
              onClick={ () => history.push(`${root}/shops/${i.id}`)}>{i.name}</a>
          )
        }
      </CardWrapper>
    </Lifecycle>
  )
}

ShopList.propTypes = {
  actions: PropTypes.shape({
    fetchShops: PropTypes.func
  }),
  shops: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      phone: PropTypes.string,
      address: PropTypes.string
    })
  ),
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  match: PropTypes.object
}

export default ShopList
