import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { compose } from 'recompose';
import { connectModule } from 'redux-modules';

import Lifecycle from '../../decorators/Lifecycle';

import loopProvider from '../../decorators/loopProvider';
import mealsModule from '../../redux/modules/meals';

import CardWrapper from '../../components/Navigation/CardWrapper';
import { IngredientForm } from '../../components/Ingredients';

import { parseDate } from '../../shared/utils';

const I18n = window.I18n;

const OrdersEdit = ({orders, actions, match, user, history }) => {
  const order = orders.find( e => e.id === parseInt(match.params.id) ) || { stock_items: [], shop: {} };
  const stock_items = order && order.stock_items || []
  const { expand } = (history.location.state || { expand: false });
  const isShopView  = (user.shop_ids || []).indexOf(order.shop_id) > -1
  const orderChef   = (user.chefs || []).find( e => order.chef_id === e.id) || {}
  const [ orderItem, setOrderItem ] = useState(order)

  const OrderChef = () =>
    isShopView ?
      <>
        <div className='fieldset'>
          <label htmlFor='order-shop-name'>{I18n.t('views.cards.chef')}</label>
          <span>{[orderChef.name, orderChef.full_name].join(', ')}</span>
        </div>
        <div className='fieldset'>
          <label htmlFor="order-date">{I18n.t('views.generic.phone')}</label>
          <span>{orderChef.phone}</span>
        </div>
        <div className='fieldset'>
          <label htmlFor="order-shop-phone">{I18n.t('views.generic.address')}</label>
          <span>{orderChef.address}</span>
        </div>
      </> : null

  useEffect( () => {
    actions.fetchChef({ id: order.chef_id })
  }, [isShopView])

  useEffect( () => {
    setOrderItem(order)
  }, [orders.length])

  return (
    <Lifecycle
      onMount={()=> {
        actions.fetchOrder({ id: match.params.id }, { meta: 'whatever order' })
      }}
    >
      <CardWrapper title={I18n.t('views.cards.orders')} className={'orders'} match={match} history={history}>
        <IngredientForm
          itemName='order'
          items={stock_items}
          expand_form={expand}
        >
          <input type="text" name='order[id]' defaultValue={order.id} autoComplete="off" hidden={true}/>
          <input type="text" name='order[chef_id]' defaultValue={order.chef_id || user.chef_id} autoComplete="off" hidden={true}/>
          <div className='fieldset'>
            <label htmlFor='order-shop-name'>{I18n.t('views.cards.shop')}</label>
            <input type='text' name='order[shop][name]' defaultValue={order.shop.name}autoComplete="off" disabled/>
          </div>
          <div className='fieldset'>
            <label htmlFor="order-date">{I18n.t('views.generic.date')}</label>
            <input
              type='date'
              name='order[date]'
              autoComplete="off"
              defaultValue={
                parseDate(order.date)
              }
            />
          </div>
          <div className='fieldset'>
            <label htmlFor="order-shop-phone">{I18n.t('views.generic.phone')}</label>
            <span>{order.shop.phone}</span>
          </div>
          <div className='fieldset'>
            <label htmlFor="order-shop-address">{I18n.t('views.generic.address')}</label>
            <span>{order.shop.address}</span>
          </div>
          <div className='fieldset'>
            <label htmlFor="order-done">{I18n.t('views.cards.forms.order.done')}</label>
            <input type="checkbox"
              name="order[done]"
              defaultValue={Boolean(orderItem.done).toString()}
              checked={Boolean(orderItem.done).toString() === 'true'}
              onChange={ () => {
                setOrderItem( prev => ({...prev, done: !prev.done }))
              }}
              autoComplete="off"
            />
          </div>
          <OrderChef />
        </IngredientForm>
      </CardWrapper>
    </Lifecycle>
  );
}

OrdersEdit.defaultProps = {
  orders: []
}

OrdersEdit.propTypes = {
  orders: PropTypes.array,
  actions: PropTypes.shape({
    fetchOrder: PropTypes.func,
    fetchChef: PropTypes.func,
  }),
  user: PropTypes.shape({
    chefs: PropTypes.array,
    chef_id: PropTypes.number,
    shop_ids: PropTypes.array
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }).isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      state: PropTypes.object
    })
  }).isRequired
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(OrdersEdit);
