import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Lifecycle from '../../decorators/Lifecycle';
import groupBy from 'lodash/groupBy';

import { orderByName } from '../../shared/utils';
import { DashboardSubHeader } from '../../shared/components';

const I18n = window.I18n;

const AvailableRecipes = ({ fetchAR, chef, match: { path }, history, ingredients }) => {
  const recipes = chef.ar ? orderByName(chef.ar) : [];
  const selectRef = useRef();
  const onChange = e => {
    history.push(e.currentTarget.value)
  }

  const RecipesList = ({recipes, ingredients}) => {
    const getIngredient = ingr => { return(ingredients.find( i => i.id === ingr.id )||{})};

    return(
      <React.Fragment>
        {
          recipes.map( recipe =>
            <React.Fragment key={`recipe_${recipe.id}`}>
              <span className='item' >
                <a>{recipe.name}</a>
                {recipe.missing > 0 ? <span>{getIngredient(recipe.missing_ingredients[0]).name }</span> : null}
              </span>
              {
                recipe.missing > 1 ?
                  <ul>
                    {
                      recipe.missing_ingredients.slice(1,recipe.missing).map( (ingr, index) => {
                        return(
                          <li key={index} className='item'>
                            <span></span>
                            <span>{getIngredient(ingr).name}</span>
                          </li>
                        )
                      })
                    }
                  </ul> : null
              }
            </React.Fragment>
          )
        }
      </React.Fragment>
    )
  }

  RecipesList.propTypes = {
    recipes: PropTypes.array,
    ingredients: PropTypes.array,
  }

  const groupedRecipes = groupBy(recipes, 'missing');
  const groups = Object.keys(groupedRecipes)||[];

  const Group = ({group, groupedRecipes, ingredients}) => {
    return(
      <React.Fragment>
        { group === '0' ? '' : <h3>{group}</h3>}
        <RecipesList
          recipes={groupedRecipes[group]}
          ingredients={ingredients}
        />
      </React.Fragment>
    )
  }

  Group.propTypes = {
    group: PropTypes.string,
    groupedRecipes: PropTypes.object,
    ingredients: PropTypes.array
  }

  return(
    <Lifecycle
      onMount={ () => {
        fetchAR()
      }}
    >
      <DashboardSubHeader
        title={I18n.t('views.reports.available_recipes')}
        onChange={onChange}
        selectRef={selectRef}
        path={path}
      />
      {
        groups.map( (group,i) =>
          <Group
            key={`group_${i}`}
            group={group}
            groupedRecipes={groupedRecipes}
            ingredients={ingredients}
          />
        )
      }
    </Lifecycle>
  )
}

AvailableRecipes.propTypes = {
  fetchAR: PropTypes.func,
  chef: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    ar: PropTypes.any
  }),
  match: PropTypes.shape({
    path: PropTypes.string
  }),
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  ingredients: PropTypes.array
}

export default AvailableRecipes;
