import React from 'react';
import PropTypes from 'prop-types';
import Lifecycle from '../../../decorators/Lifecycle';
import { ROOT_URL } from '../../../shared/config';
import CardWrapper from '../../../components/Navigation/CardWrapper';

import { orderByTranslatableName, translatableName } from '../../../shared/utils';

const I18n = window.I18n;

export const CategoryList = ({ categories, actions, history, match }) => {
  const root = ROOT_URL.length > 1 ? ROOT_URL : '';

  return(
    <Lifecycle
      onMount={()=> {
        actions.fetchCategories()
      }}
    >
      <CardWrapper title={I18n.t('views.cards.settings')} className={'categories'} match={match} history={history}>
        <h2>{I18n.t('views.cards.ingredients_categories')}</h2>

        {
          orderByTranslatableName(categories).map( i =>
            <a className='item' key={i.id}
              onClick={ () => history.push(`${root}/categories/${i.id}`)}>{translatableName(i)}</a>
          )
        }
      </CardWrapper>
    </Lifecycle>
  )
}

CategoryList.propTypes = {
  actions: PropTypes.shape({
    fetchCategories: PropTypes.func
  }),
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  ),
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  match: PropTypes.object
}


export default CategoryList
