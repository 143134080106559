import React from 'react';
import PropTypes from 'prop-types';

import { getCSRFToken } from '../../services/apiService';
import { Card } from '../../shared/components';

import Lifecycle from '../../decorators/Lifecycle';

import { ROOT_URL } from '../../shared/config';

const I18n = window.I18n;

export const Login = ({history, urls, setRouteState}) =>{
  const root = ROOT_URL.length > 1 ? ROOT_URL : '';

  return(
    <Lifecycle
      onMount={ () => {
        setRouteState()
      }}
    >
      <main role='main'>
        <Card title={I18n.t('views.cards.forms.user.login')}>
          <React.Fragment>
            <form action={urls.login_path} method="post" className='login'>
              <input type="text" name='authenticity_token' defaultValue={getCSRFToken()} hidden/>
              <label htmlFor="Email">Email:</label>
              <input type="text" name="login[email]" placeholder='eg. info@example.com'/>
              <label htmlFor="Password">Password</label>
              <input type="password" name="login[password]" />
              <button className='light-green login'>{I18n.t('views.authentication.actions.log_in')}</button>
            </form>
            <a onClick={ () => history.push(`${root}/resend`)}>{I18n.t('views.cards.forms.user.forgot_password')}</a>

            <div className='control login'>
              <div>{I18n.t('views.authentication.prompt.dont_have_account')}</div>
              <button className='light-green' onClick={ () => history.push(`${root}/signup`, {})}>{I18n.t('views.authentication.actions.sign_up')}!</button>
            </div>
          </React.Fragment>
        </Card>
      </main>
    </Lifecycle>
  )
}
Login.propTypes = {
  history: PropTypes.object,
  urls: PropTypes.object,
  setRouteState: PropTypes.func
}

export default Login
