import { createStore, applyMiddleware, compose } from "redux";
import { install } from "redux-loop";

const state = {
  meals: []
};

const configureStore = (
  initialState = state,
  reducer = state => state,
  fixedLoggerOrder = false
) => {
  if (fixedLoggerOrder) {
    return createStore(
      reducer,
      initialState,
      compose(
        install(),
      )
    );
  }

  const store = createStore(
    reducer,
    initialState,
    install()
  );
  return store;
};

export default configureStore;
