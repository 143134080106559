import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router';

import { connectModule } from 'redux-modules';
import { compose } from 'recompose';

import loopProvider from '../../../decorators/loopProvider';
import mealsModule from '../../../redux/modules/meals';

import Lifecycle from '../../../decorators/Lifecycle';
import { ROOT_URL } from '../../../shared/config';

import Chefs from '../Chefs'
import Contacts from '../Contacts'
import Guests from '../Guests'
import Permissions from '../Permissions'
import FriendRequests from '../FriendRequests'
import Notifications from '../Notifications'

import UserInfo from '../UserInfo';

const User = ({ user, actions, setRouteState }) => {
  const root = ROOT_URL.length > 1 ? ROOT_URL : '';

  return(
    <Lifecycle
      onMount={ () => {
          setRouteState()
        }
      }
    >
      <Route path={`${root}/my/friend-requests`} render={ routeProps =>
        <FriendRequests
          user={user}
          actions={actions}
          {...routeProps}
        />
      }/>
      <Route path={`${root}/my/notifications`} render={ routeProps =>
        <Notifications {...routeProps} />
      }/>
      <Route path={`${root}/my/contacts`} render={ routeProps =>
        <Contacts {...routeProps} />
      }/>
      <Route path={`${root}/my/guests`} render={ routeProps =>
        <Guests {...routeProps} />
      }/>
      <Route path={`${root}/my/chefs`} render={ routeProps =>
        <Chefs {...routeProps} />
      }/>
      <Route path={`${root}/my/info`} render={ routeProps =>
        <UserInfo {...routeProps} />
      }/>
      <Route path={`${root}/my/permissions/:user_id?`} render={ routeProps =>
        <Permissions {...routeProps} />
      }/>
    </Lifecycle>
  )
}

User.propTypes = {
  setRouteState: PropTypes.func,
  actions: PropTypes.object,
  ingredients: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      measure: PropTypes.string,
      name: PropTypes.string
    })
  ),
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      ingredients: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string
        })
      )
    })
  ),
  user: PropTypes.shape({
    chefs: PropTypes.array,
    contacts: PropTypes.shape({
      friend_requests: PropTypes.shape({
        sent: PropTypes.arrayOf(PropTypes.shape({
          accepted: PropTypes.bool,
          created_at: PropTypes.string,
          status: PropTypes.string,
          user: PropTypes.object,
          friend: PropTypes.object,
        })),
        received: PropTypes.arrayOf(PropTypes.shape({
          accepted: PropTypes.bool,
          created_at: PropTypes.string,
          status: PropTypes.string,
          user: PropTypes.object,
          friend: PropTypes.object,
        }))
      })
    })
  })
}


export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(User);

