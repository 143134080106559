import React from 'react';
import PropTypes from 'prop-types';
import { connectModule } from 'redux-modules';
import { compose } from 'recompose';

import Lifecycle from '../../decorators/Lifecycle';
import loopProvider from '../../decorators/loopProvider';
import mealsModule from '../../redux/modules/meals';

import CardWrapper from '../../components/Navigation/CardWrapper';

const I18n = window.I18n;

const FriendRequests = ({ user: normal, actions, match, history}) => {
  const friend_requests = { sent: [], received: [] }
  const contacts = { friend_requests }
  const user = (!!normal && Object.keys(normal).length > 0) ? normal : { contacts }
  const { received, sent } = user.contacts.friend_requests

  return(
    <Lifecycle
      onMount={ () => {
        actions.fetchFoodContacts()
      }}
    >
      <CardWrapper title={I18n.t('views.cards.settings')} className={'friend_requests'} match={match} history={history}>
        <h2>{I18n.t('views.cards.friend_requests')}</h2>
        { received.length > 0 ? <h3>{I18n.t('views.cards.actions.received')}</h3> : null }
        {
          received.map( e =>
            <div key={e.id} className='item'>
              <a onClick={ () => console.log('user info') }>{e.user.email}</a>
              <span>
                <button
                  onClick={ () => actions.openModal({
                      modalType: 'updateFriendship',
                      modalActions: {
                        reject: actions.updateFriendship.bind(this, {
                          friendship: {
                            id: e.id, accepted: false, status: 'rejected'
                          }
                        }),
                        accept: actions.updateFriendship.bind(this, {
                          friendship: {
                            id: e.id, accepted: true, status: 'accepted'
                          }
                        }),
                        maybe:  actions.updateFriendship.bind(this, {
                          friendship: {
                            id: e.id, accepted: false, status: 'responded'
                          }
                        })
                      },
                      current: 'reject'
                    })
                  }
                  className='material-icons'>
                    cancel
                </button>
                <button
                  onClick={ () => actions.openModal({
                      modalType: 'updateFriendship',
                      modalActions: {
                        reject: actions.updateFriendship.bind(this, {
                          friendship: {
                            id: e.id, accepted: false, status: 'rejected'
                          }
                        }),
                        accept: actions.updateFriendship.bind(this, {
                          friendship: {
                            id: e.id, accepted: true, status: 'accepted'
                          }
                        }),
                        maybe:  actions.updateFriendship.bind(this, {
                          friendship: {
                            id: e.id, accepted: false, status: 'responded'
                          }
                        })
                      },
                      current: 'accept'
                    })
                  }
                  className='material-icons'>
                    check
                </button>
              </span>
            </div>
          )
        }
        { sent.length > 0 ? <h3>{I18n.t('views.cards.actions.sent')}</h3> : null }
        {
          sent.map( e =>
            <div key={e.id} className='item'>
              <a onClick={ () => console.log('user info') }>{e.friend.email}</a>
              <span>
                <button onClick={ () => actions.openModal({
                    modalType: 'updateFriendship',
                    modalActions: {
                      cancel: actions.updateFriendship.bind(this, {
                        friendship: {
                          id: e.id, accepted: false, status: 'cancelled'
                        }
                      })
                    },
                    current: 'cancel'
                  })
                }
                className='material-icons'>
                  cancel
                </button>
              </span>
            </div>
          )
        }
      </CardWrapper>
    </Lifecycle>


  )
}

FriendRequests.propTypes = {
  user: PropTypes.shape({
    contacts: PropTypes.shape({
      friend_requests: PropTypes.shape({
        sent: PropTypes.arrayOf(PropTypes.shape({
          accepted: PropTypes.bool,
          created_at: PropTypes.string,
          status: PropTypes.string,
          user: PropTypes.object,
          friend: PropTypes.object,
        })),
        received: PropTypes.arrayOf(PropTypes.shape({
          accepted: PropTypes.bool,
          status: PropTypes.string,
          created_at: PropTypes.string,
          user: PropTypes.object,
          friend: PropTypes.object,
        }))
      })
    })
  }),
  actions: PropTypes.shape({
    openModal: PropTypes.func,
    updateFriendship: PropTypes.func,
    fetchFoodContacts: PropTypes.func
  }),
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  match: PropTypes.object
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(FriendRequests);
