import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'recompose';
import { connectModule } from 'redux-modules';

import Lifecycle from '../../decorators/Lifecycle';
import loopProvider from '../../decorators/loopProvider';
import mealsModule from '../../redux/modules/meals';

import CardWrapper from '../../components/Navigation/CardWrapper';
import { ROOT_URL } from '../../shared/config';

import { translatableName, queryParams, fetchIfEmpty, orderByTranslatableName } from '../../shared/utils';

const I18n = window.I18n;

const RecipesList = ({recipes, categories, recipes_categories, user, actions, match, history}) => {
  const root   = ROOT_URL.length > 1 ? ROOT_URL : '';
  const params = queryParams();
  const { recipes: { open_items } } = (user.reports || { recipes: { open_items: [] } })

  const isGrouped = params.group === 'category'
  const isOrdered = params.order === 'name'

  const categoriesCollection = () =>
    recipes_categories.concat(categories.filter( e => e.major ))

  const fetchGroups = () => {
    if (isGrouped) {
      fetchIfEmpty(categories, actions.fetchCategories)
      fetchIfEmpty(recipes_categories, actions.fetchRecipesCategories)
    }
  }

  const RecipesGroupItem = ({ item }) => {
    const isOpen = (currentValue) => {
      const equal = (one, other) =>
        one.type === other.type &&
        one.id === other.id

      return !!open_items.find(element => equal(element, currentValue))
    }

    const onGroupedItemClick = () => {
      switch(item.type) {
        case 'recipe':
          if (!item.effective_recipes) {
            actions.fetchRecipesCategory({id: item.id})
          }
          break;
        case 'ingredient':
          if (!item.effective_recipes) {
            actions.fetchCategory(item.id)
          }
          break;
      }

      actions.reportsRecipesOpenItem({
        type: item.type,
        id: item.id,
      })
    }

    return isOpen(item) ?
      <>
        <a onClick={ (e) => onGroupedItemClick(e) } className='item' key={`group_${item.id}`}>
          <span>{translatableName(item)}</span>
        </a>
        {
          (item.effective_recipes||[]).map( (e,i) =>
            <a onClick={ () => history.push(`${root}/recipes/${e.id}`, { whatever: true }) } className='item' key={`recipe_${i}`}>
              <span className='level1'>{translatableName(e)}</span>
              <span>{e.people}</span>
            </a>
          )
        }
      </>:
      <a onClick={ (e) => onGroupedItemClick(e) } className='item' key={`group_${item.id}`}>
        <span>{translatableName(item)}</span>
      </a>
  }

  RecipesGroupItem.propTypes = {
    item: PropTypes.object
  }

  const RecipesGroupList = () =>
    categoriesCollection().map( (e,i) => <RecipesGroupItem  key={i} item={e}/>  )

  const RecipesList = ({ result }) =>
    result.map( (e,i) =>
      <a onClick={ () => history.push(`${root}/recipes/${e.id}`, { whatever: true }) } className='item' key={`recipe_${i}`}>
        <span>{translatableName(e)}</span>
        <span>{e.people}</span>
      </a>
    )

  RecipesList.propTypes = {
    result: PropTypes.array
  }

  const result = isOrdered ? orderByTranslatableName(recipes) : recipes

  useEffect( () => {
    fetchGroups()
  }, [isGrouped])

  return(
    <Lifecycle
      onMount={ () => fetchGroups() }
    >
      <CardWrapper title={I18n.t('views.cards.recipes')} className={'recipes'} match={match} history={history}>
        {
          isGrouped ?
            <RecipesGroupList /> :
            <RecipesList result={result} />
        }
      </CardWrapper>
    </Lifecycle>
  )
};

RecipesList.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  recipes: PropTypes.array,
  categories: PropTypes.array,
  recipes_categories: PropTypes.array,
  user: PropTypes.shape({
    reports: PropTypes.shape({
      recipes: PropTypes.shape({
        open_items: PropTypes.arrayOf(PropTypes.shape({
            type: PropTypes.string,
            id: PropTypes.number
          })
        )
      })
    }),
  }),
  actions: PropTypes.shape({
    fetchCategory: PropTypes.func,
    fetchCategories: PropTypes.func,
    fetchRecipesCategory: PropTypes.func,
    fetchRecipesCategories: PropTypes.func,
    reportsRecipesOpenItem: PropTypes.func,
  }),
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(RecipesList)
