import React from 'react';
import PropTypes from 'prop-types';
import { connectModule } from 'redux-modules';
import { compose } from 'recompose';

import Lifecycle from '../../decorators/Lifecycle';
import loopProvider from '../../decorators/loopProvider';
import mealsModule from '../../redux/modules/meals';

import { maskId } from '../../shared/utils';
import { ROOT_URL } from '../../shared/config';

import CardWrapper from '../../components/Navigation/CardWrapper';

const I18n = window.I18n;

const Contact = ({ user, history }) => {
  const root = ROOT_URL.length > 1 ? ROOT_URL : '';

  return(
    <a onClick={ () => history.push(`${root}/my/permissions/${user.id}`) } className='item' key={`chef_${user.id}`}>
      <span>{maskId(user.name)}</span>
    </a>
  )
}

Contact.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  }),
  history: PropTypes.shape({
    push: PropTypes.func
  })
}

const Contacts = ({ actions, user, history, match }) => {
  const users = (user.contacts || {}).food_contacts || [];

  return(
    <Lifecycle
      onMount={ () => {
        actions.fetchFoodContacts()
      }}
    >
      <CardWrapper title={I18n.t('views.cards.settings')} className={'contacts'} match={match} history={history}>
        <h2>{I18n.t('views.cards.contacts')}</h2>
        { users.map( user => <Contact key={user.id} user={user} history={history}/> ) }
      </CardWrapper>
    </Lifecycle>
  )
}

Contacts.propTypes = {
  actions: PropTypes.shape({
    fetchFoodContacts: PropTypes.func
  }),
  user: PropTypes.shape({
    id: PropTypes.number,
    contacts: PropTypes.shape({
      food: PropTypes.array,
      food_contacts: PropTypes.any
    })
  }),
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  match: PropTypes.object
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(Contacts);
