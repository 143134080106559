import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { ROOT_URL } from '../../shared/config';


const I18n = window.I18n

const ScheduleOrderItem = ({ orders, chef_ids, routeState } ) => {
  const root = ROOT_URL.length > 1 ? ROOT_URL : '';

  const byDateShop = orders =>
    orders.reduce( (prevValue, currentValue) => {
      const acc  = prevValue
      const type = chef_ids.indexOf(currentValue.chef_id) > -1 ? 'outgoing' : 'incoming'

      if( !acc[currentValue.date] ) {
        acc[currentValue.date] = { [currentValue.shop.name]: {} }
      }
      if( !acc[currentValue.date][currentValue.shop.name] ) {
        acc[currentValue.date][currentValue.shop.name] = {}
      }
      if( !acc[currentValue.date][currentValue.shop.name][type] ) {
        acc[currentValue.date][currentValue.shop.name][type] = 0
      }

      acc[currentValue.date][currentValue.shop.name][type] += 1

      return acc
    }, {})

  const [orderItems, setOrderItems] = useState(byDateShop(orders));

  useEffect( () => {
    setOrderItems(byDateShop(orders))
  }, [orders.length])

  return(
    Object.keys(orderItems).flatMap( (date, index) =>
      Object.keys(orderItems[date]).map( (shop, idx) =>
        Object.keys((orderItems[date][shop])).map( type =>
          <a key={`${date}_${index}_${idx}_${type}`} className='item' onClick={ () => routeState.history.push(`${root}/orders`)}>
            <span>
              {shop}
            </span>
            <span>{I18n.t(type, { scope: 'views.cards.dashboards.schedule.order'})} ({orderItems[date][shop][type]})</span>
          </a>
        )
      )
    )
  )
}


ScheduleOrderItem.propTypes = {
  routeState: PropTypes.object,
  chef_ids: PropTypes.array,
  orders: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      shop: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      })
    })
  ),
}

export default ScheduleOrderItem;


