import React from 'react';
import PropTypes from 'prop-types';

import CardWrapper from '../../components/Navigation/CardWrapper';
import { ROOT_URL } from '../../shared/config';

import { filterByPresentDay } from '../../shared/utils';

const I18n = window.I18n;

const MealsList = ({meals, match, history}) => {
  const root = ROOT_URL.length > 1 ? ROOT_URL : '';

  const RenderMeals = ({ result }) =>
    result.map( (e,i) =>
      <a onClick={ () => history.push(`${root}/meals/${e.id}`, { whatever: true }) } className='item' key={`order_${i}`}>
        <span>{e.date}</span>
        <span>{e.name}</span>
      </a>
    )

  return(
    <CardWrapper title={I18n.t('views.cards.meals')} className={'meals'} match={match} history={history}>
      <details open>
        <summary>{I18n.t('views.cards.reports.meals.today')}</summary>
        <RenderMeals result={filterByPresentDay(meals, 'today')} />
      </details>
      <details open>
        <summary>{I18n.t('views.cards.reports.meals.future')}</summary>
        <RenderMeals result={filterByPresentDay(meals, 'future')} />
      </details>
      <details>
        <summary>{I18n.t('views.cards.reports.meals.past')} </summary>
        <RenderMeals result={filterByPresentDay(meals, 'past')} />
      </details>
    </CardWrapper>
  )
};

MealsList.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  meals: PropTypes.array
}

export default MealsList;