import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connectModule } from 'redux-modules';

import Lifecycle from '../../decorators/Lifecycle';
import CardWrapper from '../../components/Navigation/CardWrapper';

import loopProvider from '../../decorators/loopProvider';
import mealsModule from '../../redux/modules/meals';

import { translatableWithIngredientIdName } from '../../shared/utils';

const I18n = window.I18n;

const OrderView = ({ orders=[], ingredients=[], user, actions, match , history}) => {
  const order       = orders.find( e => e.id === parseInt(match.params.id) ) || { stock_items: [], shop: {} };
  const stock_items = (order.stock_items || [])
  const isShopView  = (user.shop_ids || []).indexOf(order.shop_id) > -1
  const orderChef   = (user.chefs || []).find( e => order.chef_id === e.id) || {}

  const OrderShop = () =>
    <>
      <div className='fieldset'>
        <label htmlFor='order-shop-name'>{I18n.t('views.cards.shop')}</label>
        <span>{order.shop.name}</span>
      </div>
      <div className='fieldset'>
        <label htmlFor="order-date">{I18n.t('views.generic.date')}</label>
        <span>{order.date}</span>
      </div>
      <div className='fieldset'>
        <label htmlFor="order-shop-phone">{I18n.t('views.generic.phone')}</label>
        <span>{order.shop.phone}</span>
      </div>
      <div className='fieldset'>
        <label htmlFor="order-shop-address">{I18n.t('views.generic.address')}</label>
        <span>{order.shop.address}</span>
      </div>
      <div className='fieldset'>
        <label htmlFor="order-done">{I18n.t('views.cards.forms.order.done')}</label>
        <span>{I18n.t(Boolean(order.done).toString(), {scope: 'views.generic.states'})}</span>
      </div>
    </>

  const OrderChef = () =>
    isShopView ?
      <>
        <div className='fieldset'>
          <label htmlFor='order-shop-name'>{I18n.t('views.cards.chef')}</label>
          <span>{[orderChef.name, orderChef.full_name].join(', ')}</span>
        </div>
        <div className='fieldset'>
          <label htmlFor="order-date">{I18n.t('views.generic.phone')}</label>
          <span>{orderChef.phone}</span>
        </div>
        <div className='fieldset'>
          <label htmlFor="order-shop-phone">{I18n.t('views.generic.address')}</label>
          <span>{orderChef.address}</span>
        </div>
      </> : null

  const OrderItems = ({stock_items}) =>
    stock_items.map( e =>
        <a onClick={ () => console.log('console') } className='item' key={`order_item_${e.id}`}>
          <span>{translatableWithIngredientIdName(e, ingredients)}</span>
          <span>{`${e.quantity} ${I18n.t(e.measure, {scope: 'quantity'})}`}</span>
        </a>
      )

  useEffect( () => {
    if (order) actions.fetchChef({ id: order.chef_id })
  }, [isShopView])

  return (
    <Lifecycle
      onMount={()=> {
          actions.fetchOrder({ id: match.params.id }, { meta: 'whatever order' })
        }}
      >
        <CardWrapper title={I18n.t('views.cards.orders')} className='orders' match={match} history={history}>
          <OrderShop />
          <OrderChef />
          <h3>{I18n.t('views.cards.sections.order.ingredients_quantities')}</h3>
          <OrderItems stock_items={stock_items}/>
        </CardWrapper>
    </Lifecycle>
  );
}

OrderView.propTypes = {
  ingredients: PropTypes.array,
  user: PropTypes.shape({
    id: PropTypes.number,
    shop_ids: PropTypes.array,
    chefs: PropTypes.array,
  }),
  orders: PropTypes.array,
  actions: PropTypes.shape({
    fetchOrder: PropTypes.func.isRequired,
    fetchChef: PropTypes.func,
  }),
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(OrderView);
