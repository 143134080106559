import React from 'react';
import PropTypes from 'prop-types';
import { MealsForm } from '../../components/Meals';
import CardWrapper from '../../components/Navigation/CardWrapper';

import { parseDate, orderByName } from '../../shared/utils';

const I18n = window.I18n;

const MealsNew = ({recipes, meals, match, history}) => {
  const selectRecipes = orderByName(recipes).map( i => i.name)
  let meal = Object.assign({}, meals.find( e => e.id === parseInt(match.params.id) ) || { meal_dishes: [] } );

  const { recipe_id } = (history.location.state || { recipe_id: null });

  if (recipe_id) {
    const { meal_dishes } = (meals[meals.length -1] || { meal_dishes: [] })
    const recipe = (recipes.find( e => e.id === parseInt(recipe_id) ) || {})

    meal.name        = recipe.name
    meal.meal_dishes = meal_dishes.map( e => Object.assign(e, { recipe }) )
  }

  meal.date = parseDate()

  return(
    <CardWrapper title={I18n.t('views.cards.meals')} className={'meals'} match={match} history={history}>
      <MealsForm
        selectRecipes={selectRecipes}
        meal={meal}
      />
    </CardWrapper>
  )
}

MealsNew.propTypes = {
  recipes: PropTypes.array,
  meals: PropTypes.array,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default MealsNew;
