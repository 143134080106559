import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connectModule } from 'redux-modules';
import { compose } from 'recompose';

import Lifecycle from '../../decorators/Lifecycle';
import loopProvider from '../../decorators/loopProvider';
import mealsModule from '../../redux/modules/meals';

import { postMessage } from '../../services/apiService';
import { CableApp } from '../../shared/utils';

import { Card } from '../../shared/components';

import ChatWebsocket from './ChatWebsocket';

import ChatMessage from '../../components/Chat/ChatMessage';

const I18n = window.I18n;

const initUser = {id: ''};
const initialMessageState = {content: '', user_id: '', room_id: ''};

const ChatPrivateRoomArea = ({initRoom, rooms_user, leaveRoom}) => {
  const [ user, setUser ] = useState(initUser)
  const [ room, setRoom ] = useState(initRoom)
  const [ message, setMessage ] = useState(initialMessageState)

  const inputRef = useRef();
  const messagesRef = useRef();

  const updateApp = ({room}) => { setRoom(room) }

  const submitMessage = () => {
    // console.log(user, rooms_user, room)
    message.content && postMessage({message: message})
      .then( () => {
        setMessage(Object.assign(initialMessageState, {
          user_id: user.id,
          room_id: room.id
        }))
      })
      .then( () => {
        (inputRef.current||{})['value'] = ''
      });
  }

  const onChange = e =>
    setMessage({
      content: e.target.value,
      user_id: user.id,
      room_id: room.id
    })

  let userScrolled = false

  const setUserScroll = () => {
      const divHeight = messagesRef.current.scrollTop + messagesRef.current.offsetHeight

      if (divHeight == messagesRef.current.scrollHeight) {
        userScrolled = false
      } else {
        userScrolled = true
      }
    };

  const updateScroll = () => {
    if (userScrolled) return

    new Promise(resolve => {
      resolve(messagesRef.current.removeEventListener('scroll', setUserScroll))
    }).then(setTimeout(() => {
      if (messagesRef.current) {
        messagesRef.current.scrollTop = messagesRef.current.scrollHeight
      }
    }, 100)).then(setTimeout(() => {
      if (messagesRef.current) {
        messagesRef.current.addEventListener('scroll', setUserScroll)
      }
    }, 300))
  };

  const cleanUp = () => {
    if (messagesRef.current) {
      messagesRef.current.removeEventListener('scroll', setUserScroll)
    }
    // leaveRoom({username: rooms_user.name, roomname: room.name})
    // console.log('onUnmount')
  }

  return(
    <Lifecycle
      onMount={
        () => {
          setUser(room.users.find( e => e.id === (rooms_user||{}).id) || initUser)
        }
      }
      onUpdate={ updateScroll }
      onUnmount={ cleanUp }
    >
      <Card title={I18n.t('views.cards.chat')} className='chat-private'>
        <React.Fragment>
          <div id="chat-main">
            <ChatWebsocket
              cableApp={CableApp}
              updateApp={ props => updateApp(props) }
              chef_name={rooms_user.name}
              room={room}
            />
            <div className='chat-sidebar'>
              <ul>
                {
                  room.users.map(user =>
                    <li key={user.name}><a onClick={ () => console.log(user)}>{user.name}</a></li>)
                }
              </ul>
            </div>
            <div id='chat-feed'>
              <div className='messages' ref={messagesRef}>
                {
                  room.messages.map( message => <ChatMessage key={message.id} message={message} private_msg={true}/>)
                }
              </div>
            </div>
            <form id='chat-form' onSubmit={ e => e.preventDefault() }>
              <div>
                <input ref={inputRef} id='message' type='text' name='content'
                  onChange={ e => onChange(e) }
                  autoComplete="off"
                />
                <button onClick={ e => submitMessage() }>{I18n.t('views.cards.actions.send')}</button>
              </div>
            </form>
          </div>
          <div className='control'>
            <button className={'salmon'}
              onClick={ () => leaveRoom({username: rooms_user.name, roomname: room.name}) }
              key='leave'>
                {I18n.t('views.cards.actions.leave')}
            </button>
          </div>
        </React.Fragment>
      </Card>
    </Lifecycle>
  )
};

ChatPrivateRoomArea.propTypes = {
  actions: PropTypes.shape({
    fetchAI: PropTypes.func,
    leaveRoom: PropTypes.func
  }),
  user: PropTypes.shape({
    chefs: PropTypes.array,
    rooms_user: PropTypes.shape({
      id: PropTypes.number
    })
  }),
  chef_name: PropTypes.string,
  initRoom: PropTypes.object
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(ChatPrivateRoomArea);
