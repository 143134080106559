import './food_sw'
import React from 'react'
import { render } from 'react-dom';
import { createStore } from 'redux';
import { Provider } from "react-redux"
import { mealsStore } from './food/decorators/providers';

import App from './food/App';

// const getNodeById = (id) => global.window.document.getElementById(id);

// const mountTools = (mounts) =>
//   Object.keys(mounts).forEach((id) => {
//     const node = getNodeById(id);
//     const props = JSON.parse(node.dataset.reactProps);
//     if (node) {
//       render(<App {...props}/>, node);
//     }
//   });

// const root = document.getElementById('bulk_add');
// const props = JSON.parse(root.getAttribute('data-props'));
// const load = () => render((
//   <AppContainer>
//     <App {...props} />
//   </AppContainer>
// ), root);
// if (module.hot) {
//   module.hot.accept('./App', load);
// }
// load();

// document.onreadystatechange = () => {
//   if (document.readyState === 'complete') {
//     mountTools({
//       'mount-food': <App />,
//     });
//   }
// };


window.mountComponent = (node) => {
  const props = JSON.parse(node.dataset.reactProps);
  render(
    <Provider store={mealsStore}>
      <App {...props}/>
    </Provider>,
    node);
};

