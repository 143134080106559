import React from 'react'
import PropTypes from 'prop-types';
import CardMenuControl from './CardMenuControl';
import CardControl from '../CardControl';

import { compose } from 'recompose';
import { connectModule } from 'redux-modules';

import loopProvider from '../../decorators/loopProvider';
import mealsModule from '../../redux/modules/meals';

const CardWrapper = ({match, ...props}) => {
  const newMatch = {
    ...match,
    params: {
      ...match.params,
      action: match.params.action || match.path.split("/")[1]
    }
  }

  // console.log(newMatch)
  return (
    <article className={props.className}>
      <div className='header'>
        <h1>{props.title}</h1>
        <div className='actions'>
          <CardMenuControl match={newMatch} {...props}/>
        </div>
      </div>
      {props.children}
      <CardControl match={newMatch} {...props}/>
    </article>
  )
}

CardWrapper.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.any,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(CardWrapper);

