import { lifecycle } from 'recompose'

export default lifecycle({
  componentDidMount() {
    this.props.onMount && this.props.onMount()
  },
  componentWillUnmount() {
    this.props.onUnmount && this.props.onUnmount()
  },
  componentDidUpdate(prevProps, prevState, snapshot) {
    this.props.onUpdate && this.props.onUpdate(prevProps, prevState, snapshot)
  }
})(({ children }) => children)
