import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { ROOT_URL } from '../../shared/config';

import uniq from 'lodash/uniq'

const I18n = window.I18n

const ScheduleMealItem = ({ meal_items, meal_periods, routeState } ) => {
  const root = ROOT_URL.length > 1 ? ROOT_URL : '';

  const [mealsItems, setMealsItems] = useState(meal_items);

  const byMealType = (a, b) =>
    meal_periods.indexOf(a.meal_period) - meal_periods.indexOf(b.meal_period)

  useEffect( () => {
    setMealsItems([...meal_items].sort(byMealType))
  }, [meal_items.length])

  const onMealClick = id => {
    routeState.history.push(`${root}/meals/${id}`)
  }

  return(
    mealsItems.map( (m, index) =>
      <a key={`${m.id}_${index}`} className='item' onClick={ () => onMealClick(m.id)}>
        <span>
          {uniq(m.meal_dishes.map( i => i.recipe.name )).join(',') }
        </span>
        <span>{I18n.t(m.meal_period, { scope: 'views.cards.forms.diet.meals.meal_periods'})} ({m.meal_dishes.length})</span>
      </a>
    )
  )
}


ScheduleMealItem.displayName = 'ScheduleMealItem'

ScheduleMealItem.propTypes = {
  routeState: PropTypes.object,
  date: PropTypes.string,
  meal_items: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      meal_period: PropTypes.string,
      meal_dishes: PropTypes.arrayOf(PropTypes.shape({
          id: PropTypes.number,
          chef_guest_id: PropTypes.number,
          recipe_id: PropTypes.number,
          recipe: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            people: PropTypes.number,
            description: PropTypes.string,
          }),
          chef_guest: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
          })
        })
      )
    })
  ),
  meal_periods: PropTypes.array,
  chef_guests: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  recipes: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  )
}

export default ScheduleMealItem;


