import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router';

import { connectModule } from 'redux-modules';
import { compose } from 'recompose';

import loopProvider from '../../../decorators/loopProvider';
import mealsModule from '../../../redux/modules/meals';

import Lifecycle from '../../../decorators/Lifecycle';
import { ROOT_URL } from '../../../shared/config';

import IngredientList from './IngredientList';
import IngredientEdit from './IngredientEdit';


const Ingredients = ({ ingredients, categories, actions, setRouteState }) => {
  const root = ROOT_URL.length > 1 ? ROOT_URL : '';

  return (
    <Lifecycle
      onMount={ () => {
        setRouteState()
      }}
    >
      <Route path={`${root}/ingredients`} exact render={ routeProps =>
        <IngredientList
          ingredients={ingredients}
          actions={actions}
          {...routeProps}
        />
      }/>
      <Route path={`${root}/ingredients/:id`} exact render={ routeProps =>
        <IngredientEdit
          categories={categories}
          ingredients={ingredients}
          actions={actions}
          {...routeProps}
        />
      }/>
    </Lifecycle>
  )
}

Ingredients.propTypes = {
  setRouteState: PropTypes.func,
  actions: PropTypes.object,
  ingredients: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      measure: PropTypes.string,
      name: PropTypes.string
    })
  ),
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      ingredients: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string
        })
      )
    })
  ),
  user: PropTypes.shape({
    chefs: PropTypes.array,
    contacts: PropTypes.shape({
      friend_requests: PropTypes.shape({
        sent: PropTypes.arrayOf(PropTypes.shape({
          accepted: PropTypes.bool,
          created_at: PropTypes.string,
          status: PropTypes.string,
          user: PropTypes.object,
          friend: PropTypes.object,
        })),
        received: PropTypes.arrayOf(PropTypes.shape({
          accepted: PropTypes.bool,
          created_at: PropTypes.string,
          status: PropTypes.string,
          user: PropTypes.object,
          friend: PropTypes.object,
        }))
      })
    })
  })
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(Ingredients);
