import React from 'react';
import PropTypes from 'prop-types';
import { connectModule } from 'redux-modules';
import { compose } from 'recompose';

import Lifecycle from '../../decorators/Lifecycle';
import loopProvider from '../../decorators/loopProvider';
import mealsModule from '../../redux/modules/meals';

import CardWrapper from '../../components/Navigation/CardWrapper';

const I18n = window.I18n;

const MembersList = ({ members, match, history}) => {
  const { params } = match;
  const user = (members.find( member => member.chefs.find( i => parseInt(i.id) === parseInt(params.id) ) )||{chefs: []});
  const chef = (user.chefs.find( i => parseInt(i.id) === parseInt(params.id))||{ stats: {} });
  const { recipes, meals, orders, stocks, meal_dishes, chef_guests } = chef.stats;

  return (
    <Lifecycle
      onMount={ () => {} }
    >
      <CardWrapper title={I18n.t('views.cards.members')} className={'members'} match={match} history={history}>
        <h2>{chef.name}</h2>
        <h3>{I18n.t('views.reports.statistics')}</h3>
        <div className='user-statistics'>
          <ul>
            <li>{I18n.t('views.cards.recipes')}</li>
            <li>{recipes}</li>
          </ul>
          <ul>
            <li>{I18n.t('views.cards.meals')}</li>
            <li>{meals}</li>
          </ul>
          <ul>
            <li>{I18n.t('views.reports.meal_dishes', {count: meal_dishes||1})}</li>
            <li>{meal_dishes}</li>
          </ul>
          <ul>
            <li>{I18n.t('views.people', {count: chef_guests||1})}</li>
            <li>{chef_guests}</li>
          </ul>
          <ul>
            <li>{I18n.t('views.cards.orders')}</li>
            <li>{orders}</li>
          </ul>
          <ul>
            <li>{I18n.t('views.cards.stocks')}</li>
            <li>{stocks}</li>
          </ul>
        </div>
      </CardWrapper>
    </Lifecycle>
  )
};

MembersList.propTypes = {
  actions: PropTypes.shape({
    fetchMembers: PropTypes.func
  }),
  members: PropTypes.array,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }),
  history: PropTypes.object
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(MembersList);
