import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { connectModule } from 'redux-modules';
import { compose } from 'recompose';

import loopProvider from '../../../decorators/loopProvider';
import mealsModule from '../../../redux/modules/meals';

import Lifecycle from '../../../decorators/Lifecycle';
import CardWrapper from '../../../components/Navigation/CardWrapper';

import get from 'lodash/get'

const I18n = window.I18n;

const ShopEdit = ({ shops, members, match, actions, history }) => {
  const shop = shops.find( e => e.id === parseInt(match.params.id) ) || { private: false, public: false, active: false}
  const shop_roles = shop.shop_roles || []
  const [shopItem, setShopItem] = useState(shop)
  const [newShopRoleItem, setNewShopRoleItem] = useState({})
  const [shopRoleItems, setShopRoleItems] = useState(shop_roles);
  const [shopUserItems, setShopUserItems] = useState(members);

  const formRef = useRef();
  const userRef = useRef();
  const roleRef = useRef();

  const roleTypes = Object.keys(get(I18n.translations, 'en.views.cards.forms.shop.roles') ||{})

  const onUserChange = e => {
    const value = e.currentTarget.value

    setNewShopRoleItem( prev => ({...prev, user_id: value}))
  }

  const onRoleChange = e => {
    const value = e.currentTarget.value

    setNewShopRoleItem( prev => ({...prev, type: value}))
  }

  useEffect( () => {
    actions.setShopFormElement(formRef.current)
  }, [])

  useEffect( () => {
    setShopItem(shop)
    setShopRoleItems(shop_roles)
    setShopUserItems( prev => shop_roles.map( e => e.user ).reduce((previousValue, currentValue) => {
        const accum = previousValue;

        if (accum.find( d => d.id === currentValue.id)) {
          return accum.map( element => element)
        } else {
          accum.push(currentValue)
        }

        return accum
      }, prev)
     )
  }, [shops])

  useEffect( () => {
    setShopUserItems( prev => members.reduce((previousValue, currentValue) => {
        const accum = previousValue;

        if (accum.find( d => d.id === currentValue.id)) {
          return accum.map( element => element)
        } else {
          accum.push(currentValue)
        }

        return accum
      }, prev)
    )
  }, [members.length])

  useEffect( () => {
    if ( Object.keys(newShopRoleItem).length > 1 ) {
      setShopRoleItems( prev => [...prev, newShopRoleItem])
      setNewShopRoleItem({})

      userRef.current.value = ''
      roleRef.current.value = ''
    }
  }, [newShopRoleItem])

  return(
    <Lifecycle
      onMount={()=> {
        if (shops.length === 0) {
          actions.fetchShops()
            .then( () => actions.fetchShop({ id: match.params.id }) )
            .then( () => actions.fetchMembers() )
        } else {
          actions.fetchShop({ id: match.params.id })
            .then( () => actions.fetchMembers() )
        }
      }}
    >
      <CardWrapper title={I18n.t('views.cards.settings')} className={'shops'} match={match} history={history}>
        <h2>{I18n.t('views.cards.shop')}</h2>
        <form ref={formRef} action="">
          <input type="text" name='shop[id]' defaultValue={shop.id} autoComplete="off" hidden={true}/>
          <ul>
            <li>{I18n.t('views.generic.name')}</li>
            <li>
              <input type="text"
                name="shop[name]"
                defaultValue={shop.name}
                autoComplete="off"
              />
            </li>
          </ul>
          <ul>
            <li>{I18n.t('views.generic.phone')}</li>
            <li>
              <input type="text"
                name="shop[phone]"
                defaultValue={shop.phone}
                autoComplete="off"
              />
            </li>
          </ul>
          <ul>
            <li>{I18n.t('views.generic.address')}</li>
            <li>
              <input type="text"
                name="shop[address]"
                defaultValue={shop.address}
                autoComplete="off"
              />
            </li>
          </ul>
          <ul>
            <li>{I18n.t('views.generic.private')}</li>
            <li>
              <input type="checkbox"
                name="shop[private]"
                defaultValue={Boolean(shopItem.private).toString()}
                checked={Boolean(shopItem.private).toString() === 'true'}
                onClick={ () => {
                  setShopItem( prev => ({...prev, private: !prev.private }))
                }}
                autoComplete="off"
              />
            </li>
          </ul>
          <ul>
            <li>{I18n.t('views.generic.active')}</li>
            <li>
              <input type="checkbox"
                name="shop[active]"
                defaultValue={Boolean(shopItem.active).toString()}
                checked={Boolean(shopItem.active).toString() === 'true'}
                onClick={ () => {
                  setShopItem( prev => ({...prev, active: !prev.active }))
                }}
                autoComplete="off"
              />
            </li>
          </ul>
          <ul>
            <li>{I18n.t('views.generic.public')}</li>
            <li>
              <input type="checkbox"
                name="shop[public]"
                defaultValue={Boolean(shopItem.public).toString()}
                checked={Boolean(shopItem.public).toString() === 'true'}
                onClick={ () => {
                  setShopItem( prev => ({...prev, public: !prev.public }))
                }}
                autoComplete="off"
              />
            </li>
          </ul>
          <ul className='header'>
            <li>{I18n.t('views.cards.forms.shop.member')}</li>
            <li>{I18n.t('views.cards.forms.shop.role')}</li>
          </ul>
          {
            shopRoleItems.map( (i, index) =>
              <ul key={`${i.id}_${index}`}>
                <li>
                  <input type="text" name='shop[shop_roles][id][]' defaultValue={i.id||'null'} autoComplete="off" hidden={true}/>
                  <select defaultValue={i.user_id} name='shop[shop_roles][user_id][]'>
                    <option key='null' value='' defaultValue=''>{I18n.t('views.cards.actions.quick_select')}</option>
                    {
                      shopUserItems.map( (u, index) =>
                        <option key={index} value={u.id} selected={i.user_id === u.id}>{`${get(u,'info.name')||u.name}`}</option>
                      )
                    }
                  </select>
                </li>
                <li>
                  <select defaultValue={i.type} name='shop[shop_roles][type][]'>
                    <option key='null' value='' defaultValue=''>{I18n.t('views.cards.actions.quick_select')}</option>
                    {
                      roleTypes.map( (type, index) =>
                        <option key={index} value={type}>{I18n.t(type, {scope: 'views.cards.forms.shop.roles'})}</option>
                      )
                    }
                  </select>
                </li>
              </ul>
            )
          }
          <ul>
            <li>
              <select onChange={onUserChange} ref={userRef}>
                <option key='null' value='' defaultValue=''>{I18n.t('views.cards.actions.quick_select')}</option>
                {
                  shopUserItems.map( (u, index) =>
                    <option key={index} value={u.id}>{`${get(u,'info.name')||u.name}`}</option>
                  )
                }
              </select>
            </li>
            <li>
              <select onChange={onRoleChange} ref={roleRef}>
                <option key='null' value='' defaultValue=''>{I18n.t('views.cards.actions.quick_select')}</option>
                {
                  roleTypes.map( (type, index) =>
                    <option key={index} value={type}>{I18n.t(type, {scope: 'views.cards.forms.shop.roles'})}</option>
                  )
                }
              </select>
            </li>
          </ul>
        </form>
      </CardWrapper>
    </Lifecycle>
  )
}

ShopEdit.propTypes = {
  actions: PropTypes.shape({
    fetchShops: PropTypes.func,
    fetchShop: PropTypes.func,
    fetchMembers: PropTypes.func,
    setShopFormElement: PropTypes.func
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.object
  }),
  shops: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      phone: PropTypes.string,
      address: PropTypes.string,
      shop_roles: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          user_id: PropTypes.number,
          user: PropTypes.shape({
            id: PropTypes.number,
            info: PropTypes.shape({
              id: PropTypes.number,
              name: PropTypes.string,
              chef_ids: PropTypes.array,
            })
          })
        })
      )
    })
  ),
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      info: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
      chef_ids: PropTypes.array,
    })
  )
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(ShopEdit);
