import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connectModule } from 'redux-modules';

import loopProvider from '../../decorators/loopProvider';
import mealsModule from '../../redux/modules/meals';

import Lifecycle from '../../decorators/Lifecycle';

import { parseDate } from '../../shared/utils';

const ChatWebsocket = ({
    cableApp,
    updateApp,
    room,
    chef_name,
    actions: {
      updateHostRoomSuccess
    },
    user,
    ...props
  }) =>  {

  const onMount = () => {
    // TODO: find a way to subscribe only once or
    // not having to subscribe multiple times
    cableApp.room = cableApp.cable.subscriptions.create({
        channel: 'RoomChannel',
        room: room.name
      },
      {
        received: json => {
          // console.log('RoomChannel', json.room.messages.length)
          updateApp(json)
        }
      }
    )

    if (!cableApp.user) {
      cableApp.user = cableApp.cable.subscriptions.create({
          channel: 'UserChannel',
          user: chef_name
        },
        {
          initialized() {
            this.update = this.update.bind(this)
          },
          connected() {
            this.install()
            this.update()
          },
          disconnected() {
            this.uninstall()
          },
          update() {
            this.documentIsActive() ? this.appear() : this.away()
          },
          received: json => {
            const condition = (user.rooms||[]).find( e => e.id === json.room.id)

            if (condition) {
              console.log('updateHostRoomSuccess', json.room.messages.length)
              updateHostRoomSuccess(json)
            } else {
              console.log('joinGuestRoomSuccess', json.room.messages.length)
              updateHostRoomSuccess(json)
            }
          },
          appear() {
            this.perform('appear', { time: this.getNow() })
          },
          away() {
            this.perform('away', { time: this.getNow() })
          },
          install() {
            // window.addEventListener('focus', this.update)
            // window.addEventListener('blur', this.update)
            // document.addEventListener('turbolinks:load', this.update)
            document.addEventListener('visibilitychange', this.update)
          },
          uninstall() {
            // window.removeEventListener('focus', this.update)
            // window.removeEventListener('blur', this.update)
            // document.removeEventListener('turbolinks:load', this.update)
            document.removeEventListener('visibilitychange', this.update)
          },

          documentIsActive: () => {
            return document.visibilityState == 'visible'
          },
          getNow: () => {
            return new Date().toISOString()
          }
        }
      )
    }
  }
  return(
    <Lifecycle
      onMount={ () => onMount() }
    >
      <div></div>
    </Lifecycle>
  )
}

ChatWebsocket.propTypes = {
  actions: PropTypes.shape({
    updateHostRoomSuccess: PropTypes.func,
    joinGuestRoomSuccess: PropTypes.func
  }),
  cableApp: PropTypes.shape({
    room: PropTypes.any,
    user: PropTypes.any,
    cable: PropTypes.shape({
      subscriptions: PropTypes.shape({
        create: PropTypes.func
      })
    })
  }),
  user: PropTypes.shape({
    rooms: PropTypes.array,
    rooms_user: PropTypes.object
  }),
  updateApp: PropTypes.func,
  room: PropTypes.object,
  chef_name: PropTypes.string
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(ChatWebsocket);
