import "trix/dist/trix-core.js";
import "trix/dist/trix.css";
import 'styles/food';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { compose } from 'recompose';
import { connectModule } from 'redux-modules';
import mealsModule from './redux/modules/meals';

import Lifecycle from './decorators/Lifecycle';

import NavBar from './components/NavBar';
import Guard from './components/Guard';

import { ROOT_URL } from './shared/config';

const I18n = window.I18n;

const App = ({ actions, ...props }) => {
  const [ routeState, setRouteState ] = useState({});

  return(
    <Lifecycle
      onMount={ () => {
        I18n.translations = props.translations;
        I18n.locale = props.locale;
        actions.initApp(props);
        actions.fetchIngredients();
      }}
    >
      <Router
        basename={`${ROOT_URL}`}
      >
        <Route path='/' render={ routerProps =>
          <NavBar
            routeState={routeState}
            {...routerProps}
          />
        }/>
        <Route path="/" render={ routerProps =>
          <Guard
            user={props.user}
            routerProps={routerProps}
            routeState={routeState}
            setRouteState={setRouteState}
            {...props}
          />
        }/>
        <footer>
          <p>&copy;</p>
        </footer>
      </Router>
    </Lifecycle>
  );
}

App.propTypes = {
  actions: PropTypes.object,
  urls: PropTypes.object,
  user: PropTypes.any,
  locale: PropTypes.string,
  translations: PropTypes.object
}

export default compose(
  connectModule(mealsModule)
)(App);
