import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Lifecycle from '../../decorators/Lifecycle';

import { compose } from 'recompose';
import { connectModule } from 'redux-modules';

import loopProvider from '../../decorators/loopProvider';
import mealsModule from '../../redux/modules/meals';


import { ScheduleMealItemForm } from '../../components/Schedule';
import { DashboardSubHeader } from '../../shared/components';
import { formatDateLong } from '../../shared/utils';

const I18n = window.I18n;

const ScheduleMeal = ({
    chef,
    meals = [],
    chef_guests = [],
    recipes = [],
    actions: {
      fetchMeals,
      fetchRecipes,
      fetchChefGuests,
      setScheduleFormElement
    },
    reports,
    match: { path }, history
  }) => {
    const { active_diets: { days, meal_periods } } = reports || { active_diets: { days: [], meal_periods: [] } };

    const formRef = useRef();
    const selectRef = useRef();
    const onChange = e => {
      history.push(e.currentTarget.value)
    }

    const scheduleDay = ({ date }) => {
      const meal_items = (meals||[]).filter( i => i.date === date )

      return(
        <React.Fragment key={date}>
          <h3>{formatDateLong(date)}</h3>
          <ScheduleMealItemForm
            ref={formRef}
            date={date}
            meal_items={meal_items}
            chef_guests={chef_guests}
            meal_periods={meal_periods}
            recipes={recipes||[]}
          />
        </React.Fragment>
      )
    }

    useEffect( () => {
      setScheduleFormElement({['meals']: formRef.current})
    }, [])


    return(
      <Lifecycle
        onMount={ () => {
          fetchMeals()
          fetchRecipes()
          fetchChefGuests()
        }}
      >
        <DashboardSubHeader
          title={I18n.t('views.reports.schedule_meals')}
          onChange={onChange}
          selectRef={selectRef}
          path={path}
        />
        <form ref={formRef} action="">
          { days.map( (date) => scheduleDay({date}) ) }
        </form>
      </Lifecycle>
    )
  }

ScheduleMeal.propTypes = {
  chef: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    ai: PropTypes.any,
    ad: PropTypes.array
  }),
  reports: PropTypes.shape({
    active_diets: PropTypes.object
  }),
  actions: PropTypes.shape({
    fetchMeals: PropTypes.func,
    fetchChefGuests: PropTypes.func,
    fetchRecipes: PropTypes.func,
    setScheduleFormElement: PropTypes.func,
  }),
  meals: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      meal_period: PropTypes.string,
    })
  ),
  chef_guests: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  recipes: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  ),
  user: PropTypes.shape({
    chef_id: PropTypes.number,
    chefs: PropTypes.arrayOf(PropTypes.shape({
        ar: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number
          })
        ),
        ai: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number
          })
        ),
      })
    ),
    reports: PropTypes.object
  }),
  match: PropTypes.shape({
    path: PropTypes.string
  }),
  history: PropTypes.shape({
    push: PropTypes.func
  })
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(ScheduleMeal);
