import qs from 'qs';
import { ROOT_URL } from '../shared/config'

const root = ROOT_URL.length > 1 ? ROOT_URL : '';

export const getCSRFToken = () => {
  const token = window.document.querySelector('meta[name="csrf-token"]');
  if (token) {
    return token.getAttribute('content') || '';
  }
  return '';
}

export const fetchConfig = () => {
  return {
    headers: {
      'X-CSRF-TOKEN': getCSRFToken(),
    },
    credentials: 'same-origin',
  };
};

const jsonResponse = res => res.json()

export const postConfig = params => {
  const headers = new Headers();
  headers.append('X-CSRF-TOKEN', getCSRFToken());
  headers.append('Content-Type', 'application/json');

  return {
    body: JSON.stringify(params),
    method: 'POST',
    headers,
    credentials: 'same-origin',
  };
}

export const postFormBodyConfig = params => {
  const headers = new Headers();
  headers.append('X-CSRF-TOKEN', getCSRFToken());

  let formData = new FormData();
  Object.keys(params).forEach( field => {
    formData.append(field, params[field]);
  })

  return {
    body: formData,
    method: 'POST',
    headers,
    credentials: 'same-origin',
  };
}

export const deleteBodyConfig = params => {
  const headers = new Headers();
  headers.append('X-CSRF-TOKEN', getCSRFToken());
  headers.append('Content-Type', 'application/json');

  return {
    body: JSON.stringify(params),
    method: 'DELETE',
    headers,
    credentials: 'same-origin',
  };
}

export const postBodyConfig = params => {
  const headers = new Headers();
  headers.append('X-CSRF-TOKEN', getCSRFToken());
  headers.append('Content-Type', 'application/json');

  return {
    body: JSON.stringify(params),
    method: 'POST',
    headers,
    credentials: 'same-origin',
  };
}

export const putBodyConfig = params => {
  const headers = new Headers();
  headers.append('X-CSRF-TOKEN', getCSRFToken());
  headers.append('Content-Type', 'application/json');

  return {
    body: JSON.stringify(params),
    method: 'PUT',
    headers,
    credentials: 'same-origin',
  };
}

export const putConfig = params => {
  return {
    ...postConfig(params),
    method: 'PUT'
  }
}

export const removeConfig = params => {
  const headers = new Headers();
  headers.append('X-CSRF-TOKEN', getCSRFToken());
  headers.append('Content-Type', 'application/json');

  return {
    body: params ? JSON.stringify(params) : null,
    method: 'DELETE',
    headers,
    credentials: 'same-origin',
  };
};

export const joinChat = params => (
  fetch('/chefy/join', postConfig(params))
    .then(jsonResponse)
)

export const postMessage = params => (
  fetch('/chefy/messages', postConfig(params))
    .then(jsonResponse)
)

export const postNotification = params => (
  fetch(`${root}/notifications`, postConfig(params))
    .then(jsonResponse)
)

export const removeNotification = params => (
  fetch(`${root}/notifications`, removeConfig(params))
    .then(jsonResponse)
)

export const getJson = (url) => {
  return fetch(url)
    .then(jsonResponse)
}

export const getSearch = (url, params={}) => {
  const formattedParams = qs.stringify(params);
  return fetch(`${url}?${formattedParams}`, fetchConfig())
    .then(jsonResponse)
}

export const postJson = (url, params={}) => {
  const formattedParams = qs.stringify(params);
  return fetch(`${url}?${formattedParams}`, postConfig())
    .then(jsonResponse)
}

export const postBodyJson = (url, params={}) => {
  return fetch(`${url}`, postBodyConfig(params))
    .then(jsonResponse)
}

export const putJson = (url, params={}) => {
  const formattedParams = qs.stringify(params);
  return fetch(`${url}?${formattedParams}`, putConfig())
    .then(jsonResponse)
}

export const putBodyJson = (url, params={}) => {
  return fetch(`${url}`, putBodyConfig(params))
    .then(jsonResponse)
}

export const deleteBodyJson = (url, params={}) => {
  return fetch(`${url}`, deleteBodyConfig(params))
    .then(jsonResponse)
}
