import React from 'react';
import PropTypes from 'prop-types';

import { compose } from 'recompose';
import { connectModule } from 'redux-modules';

import Lifecycle from '../../decorators/Lifecycle';

import loopProvider from '../../decorators/loopProvider';
import mealsModule from '../../redux/modules/meals';

import CardWrapper from '../../components/Navigation/CardWrapper';

import DietsForm from './DietsForm';

const I18n = window.I18n;

const DietsNew = ({ match, history }) => {
  return (
    <Lifecycle
      onMount={()=> {}}
    >
      <CardWrapper title={I18n.t('views.cards.diets')} className={'diets'} match={match} history={history}>
        <DietsForm match={match} history={history}/>
      </CardWrapper>
    </Lifecycle>
  );
}

DietsNew.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }).isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      state: PropTypes.object
    })
  }).isRequired,
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(DietsNew);
