import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { compose } from 'recompose'
import { connectModule } from 'redux-modules'

import mealsModule from '../redux/modules/meals'
import { ROOT_URL } from '../shared/config'

const I18n = window.I18n;

const CardNavigation = ({ routeState: { history, match } }) => {
  const root = ROOT_URL.length > 1 ? ROOT_URL : ''
  const isCurrent = tab =>
    new RegExp(tab).test((match||{}).path)

  const visitCard = cardName =>
    history.push(`${root}/${cardName}`)

  return(
    <nav id='cards'>
      <ul>
        <li>
          <a onClick={ () => visitCard('meals') } className={cn({current: isCurrent("meals")})}>
            {I18n.t('views.cards.meals')}
          </a>
        </li>
        <li>
          <a onClick={ () => visitCard('recipes') } className={cn({current: isCurrent("recipes(?!_)")})}>
            {I18n.t('views.cards.recipes')}
          </a>
        </li>
        <li>
          <a onClick={ () => visitCard('orders') } className={cn({current: isCurrent("orders")})}>
            {I18n.t('views.cards.orders')}
          </a>
        </li>
        <li>
          <a onClick={ () => visitCard('stock') } className={cn({current: isCurrent("stock")})}>
            {I18n.t('views.cards.stock')}
          </a>
        </li>
        <li>
          <a onClick={ () => visitCard('diets') } className={cn({current: isCurrent("diets")})}>
            {I18n.t('views.cards.diets')}
          </a>
        </li>
      </ul>
    </nav>
  )
}

CardNavigation.propTypes = {
  routeState: PropTypes.shape({
    history: PropTypes.shape({
      push: PropTypes.func
    }),
    match: PropTypes.object,
  })
}

export default compose(
  connectModule(mealsModule)
)(CardNavigation);