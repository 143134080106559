import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Lifecycle from '../../../decorators/Lifecycle';

import CardWrapper from '../../../components/Navigation/CardWrapper';

import serialize from 'form-serialize';

const I18n = window.I18n;

const CategoryNew = ({ categories, actions, match, history }) => {
  const [parentItems, setParentItems] = useState([]);
  const [siblingsItems, setSiblingsItems] = useState([]);

  const formRef = useRef();
  const parentRef = useRef();
  const siblingsRef = useRef();

  const selectParentItems = categories.filter( e => parentItems.map(i => i.name).indexOf(e.name) === -1 );
  const selectSiblingItems = categories.filter( e => siblingsItems.map(i => i.name).indexOf(e.name) === -1 );

  const onParentChange = e => {
    const newValue = e.currentTarget.value;
    let item = selectParentItems.find( e => e.name == newValue)

    if (parentItems.find( i => item.name === i.name )) return

    setParentItems(prev => [...prev, item])

    parentRef.current.value = '';
  }

  const onSiblingsChange = e => {
    const newValue = e.currentTarget.value;
    let item = selectSiblingItems.find( e => e.name == newValue)

    if (siblingsItems.find( i => item.name === i.name )) return

    setSiblingsItems(prev => [...prev, item])

    siblingsRef.current.value = '';
  }

  const removeParentItem = (e, index) => {
    e.preventDefault();

    setParentItems(prev => prev.filter((_,i) => i !== index))
  }
  const removeSiblingItem = (e, index) => {
    e.preventDefault();

    setSiblingsItems(prev => prev.filter((_,i) => i !== index))
  }

  const onSubmit = e => {
    e.preventDefault()

    actions.createCategory(serialize(formRef))
  }

  useEffect( () => {
    actions.setCategoryFormElement(formRef.current)
  }, [] )

  return(
    <Lifecycle
      onMount={()=> {
        actions.fetchCategories()
      }}
    >
      <CardWrapper title={I18n.t('views.cards.settings')} className={'categories'} match={match} history={history}>
        <h2>{I18n.t('views.cards.ingredients_categories')}</h2>
        <form ref={formRef} action="" onSubmit={onSubmit}>
          <ul>
            <li>{I18n.t('views.generic.name')}</li>
            <li>
              <input type="text"
                name="category[name]"
                autoComplete="off"
              />
            </li>
          </ul>
          <ul>
            <li>{I18n.t('views.cards.forms.categories.parent')}</li>
            <li>
              <select onChange={ e => onParentChange(e) } ref={parentRef} disabled={parentItems.length === 1}>
                <option key='null' value='' defaultValue=''>{I18n.t('views.cards.actions.quick_select')}</option>
                { selectParentItems.map( e => <option key={`opt${e.id}`} value={e.name}>{e.name}</option> ) }
              </select>
            </li>
          </ul>
          {
            parentItems.map( (i,index) =>
              <ul key={`${i.id}_${index}`}>
                <li></li>
                <li>
                  <span>{i.name}</span>
                  <button onClick={ e => removeParentItem(e,index) }>x</button>
                </li>
                <input type="text" name={'category[parent_id]'} defaultValue={i.id} autoComplete="off" hidden={true}/>
              </ul>
            )
          }
          <ul>
            <li>{I18n.t('views.cards.forms.categories.siblings')}</li>
            <li>
              <select onChange={ e => onSiblingsChange(e) } ref={siblingsRef}>
                <option key='null' value='' defaultValue=''>{I18n.t('views.cards.actions.quick_select')}</option>
                { selectSiblingItems.map( e => <option key={`opt${e.id}`} value={e.name}>{e.name}</option> ) }
              </select>
            </li>
          </ul>
          {
            siblingsItems.map( (i,index) =>
              <ul key={`${i.id}_${index}`}>
                <li></li>
                <li>
                  <span>{i.name}</span>
                  <button onClick={ e => removeSiblingItem(e,index) }>x</button>
                </li>
                <input type="text" name={'category[siblings][id][]'} defaultValue={i.id} autoComplete="off" hidden={true}/>
              </ul>
            )
          }
        </form>
      </CardWrapper>
    </Lifecycle>
  )
}

CategoryNew.propTypes = {
  actions: PropTypes.shape({
    setCategoryFormElement: PropTypes.func,
    createCategory: PropTypes.func,
    fetchCategories: PropTypes.func
  }),
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      ingredients: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string
        })
      )
    })
  ),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }),
  history: PropTypes.object
}

export default CategoryNew
