import React from 'react';
import PropTypes from 'prop-types';
import { connectModule } from 'redux-modules';
import { compose } from 'recompose';

import loopProvider from '../../decorators/loopProvider';
import mealsModule from '../../redux/modules/meals';

import CardWrapper from '../../components/Navigation/CardWrapper';

const I18n = window.I18n;

const Notifications = ({ user, match, history}) => {
  const notifications = user.notifications || [];

  const Notification = props =>
    <a><span>{`${props.content}`}</span> </a>

  Notification.propTypes = {
    id: PropTypes.number,
    chef_id: PropTypes.number,
    resource_id: PropTypes.number,
    resource_type: PropTypes.string,
    sender_id: PropTypes.number,
    sender_name: PropTypes.string,
    content: PropTypes.string,
    refs: PropTypes.object,
    seen: PropTypes.bool,
    read: PropTypes.bool,
    followed: PropTypes.bool,
    rejected: PropTypes.bool,
    created_at: PropTypes.string,
    updated_at: PropTypes.string
  }

  return(
    <CardWrapper title={I18n.t('views.cards.notifications')} className={'notifications'} match={match} history={history}>
      { notifications.map( e => <Notification key={e.id} {...e} />) }
    </CardWrapper>
  )
}

Notifications.propTypes = {
  actions: PropTypes.shape({
    fetchAI: PropTypes.func,
    openModal: PropTypes.func,
    updateFriendship: PropTypes.func
  }),
  user: PropTypes.shape({
    chef_id: PropTypes.number,
    chefs: PropTypes.array,
    notifications: PropTypes.array
  }),
  match: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(Notifications);
