import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router';
import { BrowserRouter } from 'react-router-dom';

import { connectModule } from 'redux-modules';
import { compose } from 'recompose';

import loopProvider from '../../../decorators/loopProvider';
import mealsModule from '../../../redux/modules/meals';

import Lifecycle from '../../../decorators/Lifecycle';
import { ROOT_URL } from '../../../shared/config';

import GuestList from './GuestList';
import GuestView from './GuestView';
import GuestEdit from './GuestEdit';

const Guests = ({ chef_guests, diets, actions}) => {
  const root = ROOT_URL.length > 1 ? ROOT_URL : '';

  return(
    <Lifecycle
      onMount={ () => {
        actions.fetchChefGuests()
      }}
    >
      <BrowserRouter
        initialEntries={[`${root}/my/guests`]}
        initialIndex={0}
      >
        <Route path={`${root}/my/guests`} exact render={ routeProps =>
          <GuestList
            chef_guests={chef_guests}
            actions={actions}
            {...routeProps}
          />
        }/>
        <Route path={`${root}/my/guests/edit/:id`} exact render={ routeProps =>
          <GuestEdit
            chef_guests={chef_guests}
            diets={diets}
            actions={actions}
            {...routeProps}
          />
        }/>
        <Route path={`${root}/my/guests/:id`} exact render={ routeProps =>
          <GuestView
            chef_guests={chef_guests}
            actions={actions}
            {...routeProps}
          />
        }/>
      </BrowserRouter>
    </Lifecycle>
  )
}

Guests.propTypes = {
  setRouteState: PropTypes.func,
  actions: PropTypes.shape({
    fetchChefGuests: PropTypes.func
  }),
  chef_guests: PropTypes.array,
  diets: PropTypes.array,
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  match: PropTypes.object
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(Guests);
