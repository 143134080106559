import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Lifecycle from '../../decorators/Lifecycle';
import { compose } from 'recompose';
import { connectModule } from 'redux-modules';

import loopProvider from '../../decorators/loopProvider';
import mealsModule from '../../redux/modules/meals';

import { ScheduleMealItem, ScheduleOrderItem } from '../../components/Schedule';

import { DashboardSubHeader } from '../../shared/components';
import { formatDateLong } from '../../shared/utils';

const I18n = window.I18n;

const Schedule = ({
    chef,
    meals = [],
    orders = [],
    user,
    actions: {
      fetchMeals,
      fetchOrders,
    },
    reports,
    match: { path }, history, routeState
  }) => {
    const { active_diets: { days, meal_periods } } = reports || { active_diets: { days: [], offset: 0 } };
    const { chef_ids, shop_ids } = user || { chef_ids: [], shop_ids: [] }

    const selectRef = useRef();
    const onChange = e => {
      history.push(e.currentTarget.value)
    }

    const ScheduleDay = ({ date }) => {
      const meal_items  = (meals || []).filter( i => i.date === date )
      const order_items = (orders || []).filter( i => i.date === date )

      return(
        <>
          <h3>{formatDateLong(date)}</h3>
          <ScheduleMealItem
            meal_items={meal_items}
            meal_periods={meal_periods}
            routeState={routeState}
          />
          <ScheduleOrderItem
            orders={order_items}
            chef_ids={chef_ids}
            routeState={routeState}
          />
        </>
      )
    }

    ScheduleDay.propTypes = {
      date: PropTypes.string
    }

    return(
      <Lifecycle
        onMount={ () => {
          fetchMeals()
          fetchOrders()
        }}
      >
        <DashboardSubHeader
          title={I18n.t('views.reports.schedule')}
          onChange={onChange}
          selectRef={selectRef}
          path={path}
        />
        { days.map( (date) => <ScheduleDay key={date} date={date} /> ) }
      </Lifecycle>
    )
  }

Schedule.propTypes = {
  routeState: PropTypes.object,
  chef: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    ai: PropTypes.any,
    ad: PropTypes.array
  }),
  reports: PropTypes.shape({
    active_diets: PropTypes.object
  }),
  actions: PropTypes.shape({
    fetchMeals: PropTypes.func,
    fetchOrders: PropTypes.func,
    fetchChefGuests: PropTypes.func,
    fetchRecipes: PropTypes.func,
    setScheduleFormElement: PropTypes.func,
  }),
  meals: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      meal_period: PropTypes.string,
    })
  ),
  orders: PropTypes.array,
  chef_guests: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  recipes: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  ),
  user: PropTypes.shape({
    chef_id: PropTypes.number,
    chef_ids: PropTypes.array,
    chefs: PropTypes.arrayOf(PropTypes.shape({
        ar: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number
          })
        ),
        ai: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number
          })
        ),
      })
    ),
    reports: PropTypes.object,
    shop_ids: PropTypes.array,
  }),
  match: PropTypes.shape({
    path: PropTypes.string
  }),
  history: PropTypes.shape({
    push: PropTypes.func
  })
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(Schedule);

