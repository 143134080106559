import React from 'react';
import PropTypes from 'prop-types';

import NewChef from '../../views/Chef';
import Authenticated from '../../views/Authenticated';

import Public from '../../views/Public';

const Guard = ({user, routerProps, routeState, setRouteState, ...props}) => {
  return(user.id ?
    user.chef_id ?
      <Authenticated
        routeState={routeState}
        setRouteState={setRouteState}
      /> :
        <NewChef /> :
          <Public
            routeState={routeState}
            setRouteState={setRouteState}
            {...routerProps}
            {...props}
          />)
}

Guard.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    chef_id: PropTypes.number
  }),
  routerProps: PropTypes.object,
  routeState: PropTypes.object,
  setRouteState: PropTypes.func
}

export default Guard
