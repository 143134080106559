import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connectModule } from 'redux-modules';
import loopProvider from '../../decorators/loopProvider';
import mealsModule from '../../redux/modules/meals';
import CardWrapper from '../../components/Navigation/CardWrapper';

import { translatableWithIngredientIdName } from '../../shared/utils';

const I18n = window.I18n;

const StockView = ({stocks, ingredients, match, history}) => {
  const stock = stocks.find( e => e.id === parseInt(match.params['id']) ) || { stock_items: [] };
  const stock_items = stock.stock_items

  const StockItems = ({ item }) =>
    <a onClick={ () => console.log('console') } className='item' key={`stock_item_${item.id}`}>
      <span>{translatableWithIngredientIdName(item, ingredients)}</span>
      <span>{`${item.quantity} ${I18n.t(item.measure, {scope: 'quantity'})}`}</span>
    </a>

  StockItems.propTypes = {
    item: PropTypes.object
  };

  return(
    <CardWrapper title={I18n.t('views.cards.stock')} className={'stock'} match={match} history={history}>
      {stock_items.map( (item,index) => <StockItems key={index} item={item}/> )}
    </CardWrapper>
  );
}

StockView.propTypes = {
  ingredients: PropTypes.array,
  stocks: PropTypes.array,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(StockView);
