import React from 'react';
import PropTypes from 'prop-types';
import { connectModule } from 'redux-modules';
import { compose } from 'recompose';

import loopProvider from '../../decorators/loopProvider';
import mealsModule from '../../redux/modules/meals';

import ChatPrivateRoomArea from './ChatPrivateRoomArea';

const ChatPrivateRoom = ({...props}) => {
  const user_rooms = (props.user.rooms||[]);
  const rooms = user_rooms.filter( e => e.name !== 'Main');
  const { rooms_user } = (props.user||{});

  if ( user_rooms.find( e => e.name === 'Main')  ) {
    return (
      <React.Fragment>
        { rooms.map( (room,index) =>
            <ChatPrivateRoomArea
              key={`${index}_area`}
              initRoom={room}
              rooms_user={rooms_user}
              leaveRoom={props.actions.leaveRoom}/>
            )
        }
      </React.Fragment>
    )
  } else {
    return null
  }
};

ChatPrivateRoom.propTypes = {
  actions: PropTypes.shape({
    fetchAI: PropTypes.func,
    leaveRoom: PropTypes.func
  }),
  user: PropTypes.shape({
    chefs: PropTypes.array,
    rooms_user: PropTypes.shape({
      id: PropTypes.number
    })
  }),
  chef_name: PropTypes.string,
  initRoom: PropTypes.object
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(ChatPrivateRoom);
