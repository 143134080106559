import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TrixEditor } from "react-trix";

import { compose } from 'recompose';
import { connectModule } from 'redux-modules';

import loopProvider from '../../decorators/loopProvider';
import mealsModule from '../../redux/modules/meals';

import { debounce, throttle } from 'lodash';

import { getSearch } from '../../services/apiService';

import CardWrapper from '../../components/Navigation/CardWrapper';
import { IngredientForm } from '../../components/Ingredients';

import { translatableAttribute } from '../../shared/utils';
import { getCSRFToken } from '../../services/apiService';

const I18n = window.I18n;

const RecipesNew = ({ingredients, recipes, user, match, history }) => {
  const recipe = recipes.find( e => e.id === parseInt(match.params.id) ) || { recipe_ingredients: [], people: 1, name: '' };
  const [recipeImagesBlobIds, setRecipeImagesBlobIds] = useState([])

  const descRef         = useRef()
  const descFormatedRef = useRef()
  const instRef         = useRef()
  const instFormatedRef = useRef()

  const onChange = e => {
    let lastValue = e.target.value;
    const fetchSearch = target => {
      if (target.value && target.value === lastValue.trim()) {
        getSearch(this.props.urls.search_food_ingredients, {q: target.value.trim()})
          .then( res => this.setState({results: res}) );
      } else if (target.value === '') {
        this.setState({results: []})
      }
    }
    debounce(fetchSearch.bind(this,e.target), 500)()
  };

  const handleEditorReady = (editor, text, html) => {
    // this is a reference back to the editor if you want to
    // do editing programatically
    if (html) {
      editor.insertHTML(html||'');
    } else {
      editor.insertString(text||'');
    }
  }

  const handleChange = (html, text, target) => {
    // html is the new html content
    // text is the new text content
    switch(target) {
      case 'description':
        descFormatedRef.current.value = html;
        descRef.current.value = text;
        break;
      case 'instruction':
        instFormatedRef.current.value = html;
        instRef.current.value = text;
        break;
    }
  }

  useEffect( () => {
    document.addEventListener("trix-attachment-add", function(event) {
      var file = event.attachment.file;
      if (file) {
        var upload = new window.ActiveStorage.DirectUpload(file,'/rails/active_storage/direct_uploads', window);
        upload.create((error, attributes) => {
          if (error) {
            return false;
          } else {
            setRecipeImagesBlobIds( prev => [...(prev ||[]), attributes.id])
            return event.attachment.setAttributes({
              url: `/rails/active_storage/blobs/${attributes.signed_id}/${attributes.filename}`,
              href: `/rails/active_storage/blobs/${attributes.signed_id}/${attributes.filename}`,
            });
          }
        });
      }
    });
  }, [])

  return(
    <CardWrapper title={I18n.t('views.cards.new_recipe')} className={'recipes'} match={match} history={history}>
      <IngredientForm
        itemName='recipe'
        selectItems={ingredients}
        items={recipe.recipe_ingredients}
        people={recipe.people}
      >
        <input type="text" name='recipe[chef_id]' defaultValue={user.chef_id} autoComplete="off" hidden={true}/>
        <input type="text" name='recipe[parent_id]' defaultValue={match.params.id} autoComplete="off" hidden={true}/>
        <div className='fieldset'>
          <label htmlFor='recipe-name'>{I18n.t('views.cards.recipe')}</label>
          <div className='recipe-name-type'>
            <input type='text' name='recipe[name]' autoComplete="off" defaultValue={recipe.name}/>
          </div>
        </div>
        <div className='fieldset'>
          <label htmlFor='recipe-description'>{I18n.t('views.cards.forms.recipe.description')}</label>
        </div>
        <div className='fieldset'>
          <TrixEditor
            style={{ width: '100%'}}
            uploadData={{"authenticity_token": getCSRFToken()}}
            onChange={ (html, text) => handleChange(html, text, 'description')}
            onEditorReady={ (e) => handleEditorReady(e, translatableAttribute(recipe, 'description'), translatableAttribute(recipe, 'description_f')) }
          />
        </div>
        <div className='fieldset'>
          <label htmlFor='recipe-intruction'>{I18n.t('views.cards.forms.recipe.instruction')}</label>
        </div>
        <div className='fieldset'>
          <TrixEditor
            style={{ width: '100%'}}
            uploadData={{"authenticity_token": getCSRFToken()}}
            onChange={ (html, text) => handleChange(html, text, 'instruction')}
            onEditorReady={ (e) => handleEditorReady(e, translatableAttribute(recipe, 'instruction'), translatableAttribute(recipe, 'instruction_f')) }
          />
        </div>
        <textarea ref={descRef} name='recipe[description]'/>
        <textarea ref={descFormatedRef} name='recipe[description_f]'/>
        <textarea ref={instRef} name='recipe[instruction]'/>
        <textarea ref={instFormatedRef} name='recipe[instruction_f]'/>
        { recipeImagesBlobIds.map( e => <input key={e} type="text" name='recipe[images_blob_ids][]' defaultValue={e} hidden/>) }
      </IngredientForm>
    </CardWrapper>
  )
};

RecipesNew.defaultProps = {
  recipe: {}
};

RecipesNew.propTypes = {
  recipes: PropTypes.array,
  ingredients: PropTypes.array,
  user: PropTypes.object,
  urls: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.object
  }).isRequired,
  history: PropTypes.object.isRequired
};

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(RecipesNew);
