import React from 'react';
import { PropTypes } from 'prop-types';
import { compose } from 'recompose';
import { connectModule } from 'redux-modules';

import Lifecycle from '../../decorators/Lifecycle';

import loopProvider from '../../decorators/loopProvider';
import mealsModule from '../../redux/modules/meals';

import CardWrapper from '../../components/Navigation/CardWrapper';
import { MealsForm } from '../../components/Meals';

import { orderByName } from '../../shared/utils';

const I18n = window.I18n;

const MealsEdit = ({ recipes, meals, actions, match, history }) => {
  const selectRecipes = orderByName(recipes).map( i => i.name)
  const meal = meals.find( e => e.id === parseInt(match.params.id) ) || { meal_dishes: [] };

  return (
    <Lifecycle
      onMount={()=> {
          actions.fetchMeal({ id: match.params.id }, { meta: 'edit' })
          actions.fetchMeals()
        }}
      >
      <CardWrapper title={I18n.t('views.cards.meals')} className={'meals'} match={match} history={history}>
        <MealsForm
          selectRecipes={selectRecipes}
          meal={meal}
        />
      </CardWrapper>
    </Lifecycle>
  );
}

MealsEdit.propTypes = {
  recipes: PropTypes.array,
  meals: PropTypes.array,
  actions: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(MealsEdit);
