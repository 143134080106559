import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { compose } from 'recompose';
import { connectModule } from 'redux-modules';

import Lifecycle from '../../decorators/Lifecycle';

import loopProvider from '../../decorators/loopProvider';
import mealsModule from '../../redux/modules/meals';

import { formatMondayBaseWeekday, sundayBasedWeekday, orderByName } from '../../shared/utils'

const I18n = window.I18n;

const DietsForm = ({ diet, diet_items=[], recipes, categories, recipes_categories, user, actions }) => {
  const {
    active_diets: { periods, seasons, days, meal_periods, offset, dietable_types }
  }  = (user.reports || {
    active_diets: { periods: [], seasons: [], days: [], meal_periods: [], offset: 0, dietable_types: [] }
  });
  const [items, setItems] = useState(diet_items);

  const formRef = useRef()

  const DietableOptions = ({ selectedDietableType, item }) => {
    const [selectedOption, setSelectedOption ] = useState(null);
    const [selectOptions, setSelectOptions] = useState(dietable_types)

    if (selectedDietableType && selectedOption !== selectedDietableType) {
      setSelectedOption(selectedDietableType)
      setSelectOptions([selectedDietableType])
    } else if (!selectedDietableType && selectOptions.length === 1) {
      setSelectOptions(dietable_types)
    }

    const renderType = (type) => {
      switch(type) {
        case 'Food::RecipeCategory':
          return orderByName(recipes_categories)
            .map( e => <option
                          key={`opt_rec_cat_${e.id}`}
                          data-type='Food::RecipeCategory'
                          selected={parseInt(item.dietable_id) === e.id && item.dietable_type === 'Food::RecipeCategory'}
                          value={e.id}>
                            {e.name}
                       </option>)
        case 'Food::Recipe':
          return orderByName(recipes)
            .map( e => <option
                          key={`opt_rec_${e.id}`}
                          data-type='Food::Recipe'
                          selected={parseInt(item.dietable_id) === e.id && item.dietable_type === 'Food::Recipe'}
                          value={e.id}>
                            {e.name}
                       </option>)
        case 'Food::Category':
          return categories
            .map( e => <option
                          key={`opt_cat_${e.id}`}
                          data-type='Food::Category'
                          selected={parseInt(item.dietable_id) == e.id && item.dietable_type === 'Food::Category'}
                          value={e.id}>
                            {e.name}
                       </option>)
        default:
          return null;
      }
    }

    return selectOptions.map( (type, i) =>
      <optgroup label={I18n.t(type, { scope: 'views.cards.forms.diet.dietable_types' })} key={i}>
        { renderType(type) }
      </optgroup>
    )
  }

  DietableOptions.propTypes = {
    selectedDietableType: PropTypes.string,
    item: PropTypes.object,
  }

  const RenderDay = ({ day, period, item, index, last }) => {
    const [selectedDietableType, setSelectedDietableType] = useState(item.dietable_type)

    const periodRef = useRef();

    const mealRef = useRef();
    const mealSelectRef = useRef();

    const dietableTypeRef = useRef();
    const dietableIdRef = useRef();


    const dietableTypeSelectRef = useRef();
    const dietableIdSelectRef = useRef();

    const processComplete = () => {
      const [meal_period, dietable_type, dietable_id] = [mealRef.current.value, dietableTypeRef.current.value, dietableIdRef.current.value]

      if ( meal_period !== 'null' &&
           dietable_type !== 'null' &&
           dietable_id !== 'null' &&
           last ) {

        setItems( prevState => [{meal_period, dietable_type, dietable_id, period, index}].reduce((previousValues, currentValue) => {
              const wdays  = [1,2,3,4,5,6,0];
              const lower  = previousValues.filter( e => wdays.indexOf(e.period) <= wdays.indexOf(currentValue.period) );
              const higher = previousValues.filter( e => wdays.indexOf(e.period) >  wdays.indexOf(currentValue.period) );

              return lower.concat(currentValue).concat(higher)
            },
            prevState
          )
        )
      }
    }

    const onMealChange = e =>{
      mealRef.current.value = e.currentTarget.value;

      processComplete()
    }
    const onDietableTypeChange = e => {
      const value = e.currentTarget.value

      dietableTypeRef.current.value = value

      setSelectedDietableType(value)

      processComplete()
    }

    const onDieatableIdChange = e => {
      const result = e.currentTarget;
      const dietableId = result.value;
      const dataset = result.options[result.selectedIndex].dataset;

      dietableIdRef.current.value = dietableId

      dietableTypeRef.current.value = dataset.type
      dietableTypeSelectRef.current.value = dataset.type

      setItems( prevState => [index].reduce((previousValues, currentValue) => {
          const finder       = e => e.period === period
          const entry        = previousValues.find(finder)
          const currentIndex = previousValues.findIndex(finder)

          if (currentValue === -1) { return previousValues; }

          if ( entry && currentIndex === index) {
            const lower  = previousValues.filter( (_e,i) => i < currentIndex );
            const higher = previousValues.filter( (_e,i) => i > currentIndex );

            return lower.concat({ ...entry, dietable_id: dietableId }).concat(higher)
          }
          return previousValues
        },
        prevState)
      )

      processComplete()
    }

    const selectMealType = meal_periods;
    const selectDietableType = dietable_types;

    const onRemoveItem = (e) => {
      e.preventDefault();

      setItems( prevState => prevState.filter((itemObject, itemIndex) => {
          if (itemObject.id !== 'null') {
            return itemObject.id !== item.id
          } else {
            return itemIndex !== index && itemObject.period !== period
          }
        })
      )
    }

    return(
      <ul>
        <li className={cn('dietable-preriod', { bold: (index === 0)})}>
          <input type='text' name='diet[diet_items][id][]' defaultValue={item.id||'null'} autoComplete="off" hidden/>
          <input type='text' ref={periodRef} name='diet[diet_items][period][]' defaultValue={period} autoComplete="off" hidden/>
          {day}
        </li>
        <li className='dietable-meal'>
          <input type='text' ref={mealRef} name='diet[diet_items][meal_period][]' defaultValue={item.meal_period||'null'} autoComplete="off" hidden/>
          <select  onChange={onMealChange} ref={mealSelectRef} defaultValue={item.meal_period}>
            <option key='null' value='' defaultValue=''></option>
            { selectMealType.map( type => <option key={`opt${type}`} value={type}>{ I18n.t(type, { scope: 'views.cards.forms.diet.meals.meal_periods'})}</option> ) }
          </select>
        </li>
        <li className='dietable-type'>
          <input type='text' ref={dietableTypeRef} name='diet[diet_items][dietable_type][]' defaultValue={item.dietable_type||'null'} autoComplete="off" hidden/>
          <select  onChange={onDietableTypeChange} ref={dietableTypeSelectRef} defaultValue={item.dietable_type}>
            <option key='null' value='' defaultValue=''></option>
            { selectDietableType.map( type => <option key={`opt_${type}`} value={type}>{I18n.t(type, { scope: 'views.cards.forms.diet.dietable_types'}) }</option> ) }
          </select>
        </li>
        <li className='dietable-name'>
          <input type='text' ref={dietableIdRef} name='diet[diet_items][dietable_id][]' defaultValue={item.dietable_id||'null'} autoComplete="off" hidden/>
          <select  onChange={onDieatableIdChange} ref={dietableIdSelectRef}>
            <option key='null' value='' defaultValue=''></option>
            <DietableOptions selectedDietableType={selectedDietableType} item={item}/>
          </select>
        </li>
        <li className='action'>
          {
            last ?
              null :
                <button
                  onClick={onRemoveItem}
                  className='material-icons'
                >
                 cancel
                </button>
          }
        </li>
      </ul>
    )
  }

  RenderDay.propTypes = {
    day: PropTypes.string,
    period: PropTypes.number,
    item: PropTypes.object,
    index: PropTypes.number,
    last: PropTypes.bool
  }

  RenderDay.defaultProps = {
    item: {}
  }

  const RenderWeek = ({days, items}) => {
    return days.map( (day, index) => {
        const wday = sundayBasedWeekday(index)

        if (items.find( i => i.period === wday) ) {
          return(
            <React.Fragment key={`day_${day}_period_${wday}`}>
              {
                items.filter( (i) => i.period === wday )
                     .map( (item, index) =>
                       <RenderDay
                         key={`day_${day}_period_${wday}_${index}`}
                         day={formatMondayBaseWeekday(day, offset)}
                         period={wday}
                         item={item}
                         index={index}
                         last={(index === (items.length -1))}
                       />
                     )
              }
              <RenderDay key={`day_${day}_period_${wday}_new`} day={formatMondayBaseWeekday(day, offset)} period={wday} index={-1} last={true}/>
            </React.Fragment>
          )
        } else {
          return <RenderDay key={`day_${day}_period_${wday}`} day={formatMondayBaseWeekday(day, offset)} period={wday} index={0} last={true}/>
        }
      }
    )
  }

  RenderWeek.propTypes = {
    days: PropTypes.array,
    items: PropTypes.array
  }

  useEffect( () => {
    actions.setDietFormElement(formRef.current)
  }, [])

  useEffect( () => {
    setItems(diet_items)
  }, [diet])

  const periodRef = useRef();
  const seasonRef = useRef();

  const onPeriodChange = e =>
    periodRef.current.value = e.currentTarget.value

  const onSeasonChange = e =>
    seasonRef.current.value = e.currentTarget.value

  const selectPeriods = periods;
  const selectSeasons = seasons;

  return (
    <Lifecycle
      onMount={()=> {
          actions.fetchRecipes()
          actions.fetchCategories()
          actions.fetchRecipesCategories()
        }}
      >
      <form ref={formRef} action="" className='diet'>
        <input type="text" name='diet[id]' defaultValue={diet.id} autoComplete="off" hidden/>
        <input type="text" name='diet[chef_id]' defaultValue={user.chef_id} autoComplete="off" hidden/>
        <div className='fieldset'>
          <label htmlFor='diet-name'>{I18n.t('views.cards.forms.diet.name')}</label>
          <input type='text' name='diet[name]' defaultValue={diet.name} autoComplete="off"/>
        </div>
        <div className='fieldset'>
          <label htmlFor='diet-period'>{I18n.t('views.cards.forms.diet.period')}</label>
          <input type='text' ref={periodRef} name='diet[period]' defaultValue={diet.period} autoComplete="off" hidden/>
          <select  onChange={onPeriodChange} defaultValue={diet.period}>
            <option key='null' value='' defaultValue=''></option>
            { selectPeriods.map( period => <option key={`opt_${period}`} value={period} selected={diet.period === period}>{I18n.t(period, { scope: 'views.cards.forms.diet.periods'}) }</option> ) }
          </select>
        </div>
        <div className='fieldset'>
          <label htmlFor='diet-season'>{I18n.t('views.cards.forms.diet.season')}</label>
          <input type='text' ref={seasonRef} name='diet[season]' defaultValue={diet.season} autoComplete="off" hidden/>
          <select  onChange={onSeasonChange} defaultValue={diet.season}>
            <option key='null' value='' defaultValue=''></option>
            { selectSeasons.map( season => <option key={`opt_${season}`} value={season} selected={diet.season === season}>{I18n.t(season, { scope: 'views.cards.forms.diet.seasons'}) }</option> ) }
          </select>
        </div>
        <ul className='header'>
          <li className='dietable-preriod'>{I18n.t('views.cards.forms.diet.day')}</li>
          <li className='dietable-meal'>{I18n.t('views.cards.forms.diet.meals.meal_period')}</li>
          <li className='dietable-type'>{I18n.t('views.cards.forms.diet.diet_item')}</li>
          <li className='dietable-name'>{I18n.t('views.cards.forms.diet.diet_item_name')}</li>
          <li className='action'></li>
        </ul>
        <RenderWeek days={days} items={items} />
      </form>
    </Lifecycle>
  );
}

DietsForm.propTypes = {
  diet: PropTypes.object,
  diet_items: PropTypes.array,
  recipes: PropTypes.array,
  categories: PropTypes.array,
  actions: PropTypes.shape({
    fetchDiet: PropTypes.func,
    fetchRecipes: PropTypes.func,
    fetchCategories: PropTypes.func,
    fetchRecipesCategories: PropTypes.func,
    setDietFormElement: PropTypes.func,
  }),
  user: PropTypes.shape({
    chef_id: PropTypes.number,
    reports: PropTypes.shape({
      active_diets: PropTypes.shape({
        periods: PropTypes.array,
        seasons: PropTypes.array,
        days: PropTypes.array,
        offset: PropTypes.number,
        meal_periods: PropTypes.array,
        dietable_types: PropTypes.array,
      }),
    })
  })
}

DietsForm.defaultProps = {
  diet: {},
  diet_items: []
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(DietsForm);
