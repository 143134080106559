import React from 'react';
import PropTypes from 'prop-types';
import { connectModule } from 'redux-modules';
import { MemoryRouter, Route } from 'react-router';
import { compose } from 'recompose';

import loopProvider from '../../decorators/loopProvider';
import mealsModule from '../../redux/modules/meals';

import { Card } from '../../shared/components';
import CardControl from '../../components/CardControl';
import Notification from '../../components/Notification/Notification';

import ChatMainRoom from './ChatMainRoom';
import ChatPrivateRoom from './ChatPrivateRoom';

import Lifecycle from '../../decorators/Lifecycle';

const I18n = window.I18n;

const ChatMain = ({user: { chef_id, chefs }, setRouteState }) => {
  const chat_user = (chef_id && chefs.find( e => e.id === chef_id ) || {});

  return (
    <Lifecycle onMount={setRouteState}>
      <Card title={I18n.t('views.cards.chat')} className='chat'>
        <MemoryRouter
          initialEntries={["/chatMain"]}
          initialIndex={0}
        >
          <Route path='/'>
          </Route>
          <Route path={'/chatMain'} render={ routeProps =>
            <React.Fragment>
              <h1>Join</h1>
              <Notification {...routeProps} />
            </React.Fragment>
          }/>
          <Route path={'/chatMainRoom'} render={ routeProps =>
            <ChatMainRoom chef_name={chat_user.name} {...routeProps} />
          }/>
          <Route path={`/chatPrivate`} render={  () =>
            <ChatPrivateRoom />
          }/>
          <Route path={'/:action/:id?'} render={ props =>
            <CardControl {...props} />
          }/>
        </MemoryRouter>
      </Card>
    </Lifecycle>
  )
};

ChatMain.propTypes = {
  actions: PropTypes.shape({
    fetchAI: PropTypes.func
  }),
  user: PropTypes.shape({
    chef_id: PropTypes.number,
    chefs: PropTypes.array,
    rooms: PropTypes.array,
  }),
  setRouteState: PropTypes.func
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(ChatMain);
