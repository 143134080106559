import React from 'react';
import PropTypes from 'prop-types';
import { connectModule } from 'redux-modules';
import { compose } from 'recompose';
import cn from 'classnames';

import loopProvider from '../../decorators/loopProvider';
import mealsModule from '../../redux/modules/meals';

import { orderByName } from '../../shared/utils'

export const ChatUserList = ({users, chef_name, rooms_user, selected='', setSelected, actions}) => {

  const joinPrivateRoom = (actions, chef_name, rooms_user, user) => {
    const roomname = [rooms_user.id, user.id].sort().join('_');
    actions.joinRoom({username: chef_name, roomname: roomname})
      .then( () => {
        setSelected(user.name)
      })
  }

  return(
    <ul className='chat-sidebar-users'>
      {
        orderByName(users).map( user => <li key={user.name}
            className={ cn({selected: selected.trim() === user.name.trim()}) }
          >
            <a className={ cn({away: user.away}) }
              onClick={ () => joinPrivateRoom(actions, chef_name, rooms_user, user) }>
              {user.name}
            </a>
          </li>
        )
      }
    </ul>
  )
}

ChatUserList.propTypes = {
  actions: PropTypes.shape({
    joinRoom: PropTypes.func
  }),
  users: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  chef_name: PropTypes.string,
  rooms_user: PropTypes.object,
  selected: PropTypes.string,
  setSelected: PropTypes.func
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(ChatUserList);
