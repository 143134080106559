import React from 'react';
import PropTypes from 'prop-types';
import { connectModule } from 'redux-modules';
import { MemoryRouter, Route } from 'react-router';
import { compose } from 'recompose';

import loopProvider from '../../decorators/loopProvider';
import mealsModule from '../../redux/modules/meals';

import AppearanceWebSocket from './AppearanceWebSocket';

import { CableApp } from '../../shared/utils';

const I18n = window.I18n;

const Appearance = ({user: { chef_id, chefs, stats } }) => {
  const chef_user = (chef_id && chefs.find( e => e.id === chef_id ) || {});
  const user_stat = (stats || { online: 0, chatting: 0 })

  const updateApp = props => console.log(props)

  return (
    <div id='appearances'>
      <span>{I18n.t('views.stats.online')}: {user_stat.online} {I18n.t('views.stats.chatting')}: {user_stat.chatting}</span>
      <AppearanceWebSocket
        cableApp={CableApp}
        updateApp={ props => updateApp(props) }
        chef_id={chef_id}
      />
    </div>
  )
};

Appearance.propTypes = {
  actions: PropTypes.shape({
    fetchAI: PropTypes.func
  }),
  user: PropTypes.shape({
    chef_id: PropTypes.number,
    chefs: PropTypes.array,
    stats: PropTypes.shape({
      online: PropTypes.number,
      chatting: PropTypes.number
    })
  })
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(Appearance);
