import React, { useState } from 'react';
import cn from 'classnames';
import { compose } from 'recompose';
import { connectModule } from 'redux-modules';
import PropTypes from 'prop-types';

import loopProvider from '../decorators/loopProvider';
import mealsModule from '../redux/modules/meals';
import { ROOT_URL } from '../shared/config';

const I18n = window.I18n;

const availableLocales = () => ['en', 'el']

const availableModes = () =>
  ['day', 'night'].filter( e => document.querySelector('body').className !== { day: '', night: 'dark' }[e] )

const getLanguageName = locale => {
  switch(locale) {
    case 'en':
      return 'english';
    case 'el':
      return 'greek'
  }
}
const getLanguageSymbol = locale => {
  switch(locale) {
    case 'en':
      return 'gb';
    case 'el':
      return 'gr'
  }
}

const initialMenuState = {
  menu: false,
  submenuLang: false,
  submenuAppear: false
}

const LoggedIn = ({routeState: { history } }) => {
  const root = ROOT_URL.length > 1 ? ROOT_URL : '';
  const [ open, setOpen ] = useState(initialMenuState);

  const onAppearanceClick = () => {
    document.querySelector('body').classList.toggle('dark')
    setOpen(initialMenuState)
  }

  const moveAndClose = func => {
    func()
    setOpen( prev => ({ ...prev, menu: !open.menu }))
  }

  return(
    <ul>
      <li>
        <a onClick={ () => history.push(`${root}`) }>
          Chefy
        </a>
      </li>
      <li>
        <a onClick={ () => setOpen( prev => ({ ...prev, menu: !open.menu })) }>
          <span className='material-icons'>menu</span>
        </a>
        <ul className={cn({menu: true, open: open.menu})}>
          <li>
            <a onClick={ () => setOpen( prev => ({ ...prev, submenuLang: !open.submenuLang })) } className='language' >
              <span>{I18n.t('views.generic.language')}</span>
              <span className={`flag-icon flag-icon-${getLanguageSymbol(I18n.locale)}`}></span>
            </a>
            <ul className={cn({submenuLang: true, open: open.submenuLang})}>
              {
                availableLocales().filter( e => e !== I18n.locale ).map( locale =>
                  <li key={locale}>
                    <a href={`?locale=${locale}`}>
                      <span>{I18n.t(getLanguageName(locale), {scope: 'views.generic.languages'})}</span>
                      <span className={`flag-icon flag-icon-${getLanguageSymbol(locale)}`}></span>
                    </a>
                  </li>
                )
              }
            </ul>
          </li>
          <li>
            <a onClick={ () => setOpen( prev => ({ ...prev, submenuAppear: !open.submenuAppear })) } className='appearence-mode' >
              {I18n.t('views.generic.appearence')}
            </a>
            <ul className={cn({submenuAppear: true, open: open.submenuAppear})}>
              {
                availableModes().map( mode =>
                  <li key={mode}>
                    <a onClick={onAppearanceClick}>
                      {I18n.t(mode, {scope: 'views.generic.appearences'})}
                    </a>
                  </li>
                )
              }
            </ul>
          </li>
          <li>
            <a onClick={ () => moveAndClose(history.push.bind(this,`${root}/settings`)) }>
              {I18n.t('views.cards.settings')}
            </a>
          </li>
          <li>
            <a onClick={ () => moveAndClose(history.push.bind(this,`${root}/members`)) }>
              {I18n.t('views.cards.members')}
            </a>
          </li>
          <li>
          <a onClick={ () => moveAndClose(history.push.bind(this,`${root}/chat`)) }>
              {I18n.t('views.cards.chat')}
            </a>
          </li>
          <li>
            <a className='logout' href={`${root}/logout`}>
              {window.I18n.t('views.authentication.actions.log_out')}
            </a>
          </li>
        </ul>
      </li>
    </ul>
  )
}

LoggedIn.propTypes = {
  history: PropTypes.object,
  routeState: PropTypes.shape({
    history: PropTypes.shape({
      push: PropTypes.func
    })
  })
}

const LoggedOut = ({ routeState: { history } }) => {
  const root = ROOT_URL.length > 1 ? ROOT_URL : '';

  return(
    <ul>
      <li>
        <a href={`${ROOT_URL}`}>Chefy</a>
      </li>
      <li>
        <a onClick={ () => history.push(`${root}/signup`, {}) }>
          {I18n.t('views.authentication.actions.sign_up')}
        </a>
        <a onClick={ () => history.push(`${root}/login`, {}) }>
          {I18n.t('views.authentication.actions.log_in')}
        </a>
      </li>
    </ul>
  )
}

LoggedOut.propTypes = {
  history: PropTypes.object,
  routeState: PropTypes.shape({
    history: PropTypes.shape({
      push: PropTypes.func
    })
  })
}

const NavBar = ({ user, routeState, ...props }) => (
    <React.Fragment>
      <nav id='main' className={ user.id ? 'private' : 'public' }>
        { user.id ?
            <LoggedIn
              routeState={routeState}
            /> :
            <LoggedOut
              routeState={routeState}
              {...props}
            /> }
      </nav>
      {props.children}
    </React.Fragment>
  )

NavBar.propTypes = {
  user: PropTypes.object,
  children: PropTypes.object,
  routeState: PropTypes.object
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(NavBar);
