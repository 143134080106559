import React from 'react';
import cn from 'classnames';
import { compose } from 'recompose';
import { connectModule } from 'redux-modules';
import PropTypes from 'prop-types';

import loopProvider from '../decorators/loopProvider';
import mealsModule from '../redux/modules/meals';

const I18n = window.I18n;

const Modal = ({modal, actions}) => {
  const { open, modalType, modalActions, current, history, goBack } = modal;

  const renderMessage = (modalType) => {
    switch(modalType) {
      case 'updateFriendship':
        switch(current) {
          case 'accept':
            return I18n.t('views.modals.update_friendship.accept')
          case 'reject':
            return I18n.t('views.modals.update_friendship.reject')
          case 'cancel':
            return I18n.t('views.modals.update_friendship.cancel')
        }
        break
      case 'deleteCategory':
        switch(current) {
          case 'yes':
            return I18n.t('views.modals.delete_category.yes')
        }
        break
      default:
        return 'Are you sure?';
    }
  }

  const ButtonsWithActions = ({modalActions, current, actions, history, goBack}) => {
    const keys = Object.keys(modalActions);

    switch(true) {
      case keys.indexOf('maybe') !== -1:
        return(
          <React.Fragment>
            <button className='light-green' onClick={ () => {
                modalActions[current]()
                  .then( () => actions.closeModal() );
              }
            }>
              {I18n.t('views.generic.actions.yes')}
             </button>
            <button className='light-blue' onClick={ () => {
                modalActions['maybe']()
                  actions.closeModal()
              }
            }>
              {I18n.t('views.generic.actions.maybe')}
            </button>
          </React.Fragment>
        )
      default:
        return(
          <button className='light-green' onClick={ () => {
              modalActions[current]()
                .then( () => {
                  if (goBack) history.goBack()

                  actions.closeModal()
                });
            }
          }>
            {I18n.t('views.generic.actions.yes')}
          </button>
        )
    }
  }

  return(
    <div id="modal" className={cn({open: open})}>
      <div id="modal-dialog">
        <div id="modal-message">
          {renderMessage(modalType)}
        </div>
        <div id="modal-actions">
          <button className='salmon' onClick={ () => {
              actions.closeModal()
            }
          }>
            {I18n.t('views.generic.actions.no')}
          </button>
          <ButtonsWithActions
            modalActions={modalActions}
            current={current}
            actions={actions}
            history={history}
            goBack={goBack}
          />
        </div>
      </div>
    </div>
  )
}

Modal.propTypes = {
  modal: PropTypes.shape({
    open: PropTypes.bool,
    modalType: PropTypes.string,
    modalActions: PropTypes.object,
    current: PropTypes.string,
    history: PropTypes.shape({
      goBack: PropTypes.func
    }),
    goBack: PropTypes.bool
  }),
  actions: PropTypes.shape({
    closeModal: PropTypes.func
  })
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(Modal);

