import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router';
import { Switch } from 'react-router-dom';

import { connectModule } from 'redux-modules';
import { compose } from 'recompose';

import Lifecycle from '../decorators/Lifecycle';
import loopProvider from '../decorators/loopProvider';
import mealsModule from '../redux/modules/meals';

import OrdersList from './orders/OrdersList';
import OrdersNew from './orders/OrdersNew';
import OrdersView from './orders/OrdersView';
import OrdersEdit from './orders/OrdersEdit';
import { ROOT_URL } from '../shared/config';
import { getBasket } from '../shared/utils';

const Orders = ({ actions, orders, user, setRouteState }) => {
  const root = ROOT_URL.length > 1 ? ROOT_URL : '';

  return(
    <Lifecycle
      onMount={()=>{
        actions.fetchOrders()
        actions.fetchIngredients(); // stock ingredients
        setRouteState()
        actions.addIngredientItemsToBasket(getBasket())
      }}
    >
      <Switch>
        <Route path={`${root}/orders`} exact render={ routeProps =>
          <OrdersList
            orders={orders}
            shop_ids={user.shop_ids}
            chef_id={user.chef_id}
            {...routeProps}
          />
        }/>
        <Route path={`${root}/orders/new/:id?`} exact render={ routerProps =>
          <OrdersNew {...routerProps} />
        }/>
        <Route path={`${root}/orders/edit/:id`} exact render={ routerProps =>
          <OrdersEdit {...routerProps} />
        }/>
        <Route path={`${root}/orders/:id`} exact render={ routerProps =>
          <OrdersView {...routerProps} />
        }/>
      </Switch>
    </Lifecycle>
  )
};

Orders.propTypes = {
  setRouteState: PropTypes.func,
  urls: PropTypes.object,
  orders: PropTypes.array,
  ingredients: PropTypes.array,
  actions: PropTypes.shape({
    fetchOrders: PropTypes.func,
    fetchIngredients: PropTypes.func,
    addIngredientItemsToBasket: PropTypes.func
  }),
  user: PropTypes.shape({
    chef_id: PropTypes.number,
    shop_ids: PropTypes.array,
  }),
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(Orders);
