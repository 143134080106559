import React from 'react';
import PropTypes from 'prop-types';

import Lifecycle from '../../../decorators/Lifecycle';
import { ROOT_URL } from '../../../shared/config';

import CardWrapper from '../../../components/Navigation/CardWrapper';

const I18n = window.I18n;

const ChefList = ({ chefs, actions, match, history}) => {
  const root = ROOT_URL.length > 1 ? ROOT_URL : '';

  const ChefItem = ({ item }) =>
    <a onClick={ () => history.push(`${root}/my/chefs/${item.id}`)} className='item'>
      <span>{item.name}</span>
    </a>

  ChefItem.propTypes = {
    item: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  }

  return(
    <Lifecycle
      onMount={ () => {} }
    >
      <CardWrapper title={I18n.t('views.cards.settings')} className={'settings'} match={match} history={history}>
        <h2>{I18n.t('views.cards.chefs')}</h2>
        { chefs.map( e => <ChefItem key={e.id} item={e} />)}
      </CardWrapper>
    </Lifecycle>
  )
}

ChefList.propTypes = {
  actions: PropTypes.shape({
    fetchChefGuests: PropTypes.func
  }),
  chefs: PropTypes.array,
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  match: PropTypes.object
}


ChefList.defaultProps = {
  chef_guests: []
}

export default ChefList
