import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connectModule } from 'redux-modules';
import { compose } from 'recompose';

import Lifecycle from '../../decorators/Lifecycle';
import loopProvider from '../../decorators/loopProvider';
import mealsModule from '../../redux/modules/meals';

import { maskId } from '../../shared/utils';

import CardWrapper from '../../components/Navigation/CardWrapper';

const I18n = window.I18n;

// interface chef {
//   id: number,
//   name: string
// }

// interface User {
//   id: number,
//   name: string
//   chefs: chef[]
// }

// enum Scopes {
//   recipes
// }


const Permissions = ({ user, match, actions, history }) => {
  const contacts = ((user.contacts || {}).food_contacts || []);
  const contact  = contacts.find( e => e.id === parseInt(match.params.user_id) )||{};
  const food     = user.allow.food || {};
  const scopes   = Object.keys(food);

  const [ chefsWithScopes, setChefsWithScopes ] = useState(food);
  const [ shareScopes, setShareScopes ] = useState(scopes);

  const formRef  = useRef();

  const chefRef  = useRef();
  const scopeRef = useRef();

  const getScope = obj =>
    Object.keys(obj).find( key => obj[key].find( e => e.id === undefined ) )

  const getChef = id =>
    (user.chefs.find( chef => chef.id === parseInt(id) )||{})

  const onScopeSelectChange = e => {
    const scope = e.currentTarget.value;
    const last = { contact_id: contact.id };

    setChefsWithScopes( prev => ({
      ...prev,
      [scope]: [
        ...prev[scope],
        last
      ]
    }))

    scopeRef.current.value = '';
  }

  const onChefSelectChange = e => {
    const scope = getScope(chefsWithScopes);
    const chef = getChef(e.currentTarget.value);

    setChefsWithScopes( prev => {
      const newPermissions = prev[scope].reduce( (previousValue, currentValue) => {
          const accum = previousValue;
          if (!currentValue.id) {
            accum.push(Object.assign(currentValue,chef))
          } else {
            accum.push(currentValue)
          }
          return accum;
        },
        []
      )

      return {
        ...prev,
        [scope]: newPermissions
      }
    });

    chefRef.current.value = '';
  }

  const removeShare = (e, scope, obj) => {

    e.preventDefault();

    setChefsWithScopes( prev => ({
        ...prev,
        [scope]: prev[scope].filter( i => i.id !== obj.id && i.contact_id !== obj.contact_id )
      })
    )
  }

  const RenderPermissionList = () =>
    Object.keys(chefsWithScopes).map( (scope) =>
      chefsWithScopes[scope].filter( e => contact.id === e.contact_id).map( (obj, index) => {
        const chef = getChef(obj.id);
        return(
          <ul className='input-list' key={`${chef.id}_${index}`}>
            <li>{I18n.t(scope, {scope: 'views.cards'})}</li>
            <input type="text" name='share[resource][resource_scope][]' defaultValue={scope} autoComplete="off" hidden={true}/>
            <li>{chef.name}</li>
            <input type="text" name='share[resource][resource_id][]' defaultValue={chef.id} autoComplete="off" hidden={true}/>
            <li>
              <button onClick={ (e) => removeShare(e, scope, obj) }
                className='material-icons'
              >
                cancel
              </button>
            </li>
          </ul>
        )
        })
      )

  useEffect( () => {
    setChefsWithScopes(food)
    setShareScopes(scopes)
  }, [contacts.length])

  useEffect( () => {
    actions.setPermissionFormElement(formRef.current)
  }, [])

  return(
    <Lifecycle
      onMount={ () => {} }
    >
      <CardWrapper title={I18n.t('views.cards.settings')} className={'categories'} match={match} history={history}>
        <h2>{maskId(contact.name)}</h2>
        <form ref={formRef} action="" className='meal'>
          <input type="text" name='share[owner_id]' defaultValue={user.id} autoComplete="off" hidden={true}/>
          <input type="text" name='share[user_id]' defaultValue={contact.id} autoComplete="off" hidden={true}/>
          <ul className='header'>
            <li className='quest-name'>{I18n.t('views.cards.forms.permissions.kind')}</li>
            <li className='guest-dish'>{I18n.t('views.cards.forms.permissions.chef')}</li>
          </ul>
          <RenderPermissionList />
          <ul>
            <li className='quest-name'>
              <select ref={scopeRef} onChange={ e => onScopeSelectChange(e) } >
                <option key='null' value='' defaultValue=''>{I18n.t('views.cards.actions.quick_select')}</option>
                { shareScopes.map( (e,i) => <option key={`opt_${e}_${i}`} value={e}>{I18n.t(e, {scope: 'views.cards'})}</option> ) }
              </select>
            </li>
            <li className='quest-dish'>
              <select ref={chefRef} onChange={ e => onChefSelectChange(e) }  disabled={false}>
                <option key='null' value='' defaultValue=''>{I18n.t('views.cards.actions.quick_select')}</option>
                { user.chefs.map( e => <option key={`opt${e.id}`} value={e.id}>{e.name}</option> ) }
              </select>
            </li>
          </ul>
        </form>
      </CardWrapper>
    </Lifecycle>
  )
}

Permissions.propTypes = {
  actions: PropTypes.shape({
    setPermissionFormElement: PropTypes.func
  }),
  user: PropTypes.shape({
    id: PropTypes.number,
    chefs: PropTypes.array,
    contacts: PropTypes.shape({
      food: PropTypes.array,
      food_contacts: PropTypes.arrayOf(PropTypes.shape({
          chefs: PropTypes.arrayOf(PropTypes.shape({
              id: PropTypes.number,
              user_id: PropTypes.number,
              name: PropTypes.string,
              stats: PropTypes.object
            })
          ),
          id: PropTypes.number,
          email: PropTypes.string,
          first_name: PropTypes.string,
          last_name: PropTypes.string,
          locale: PropTypes.string,
          info: PropTypes.shape({
            name: PropTypes.string,
            chefs: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.number,
                user_id: PropTypes.number,
                name: PropTypes.string,
                stats: PropTypes.object
              })
            )
          })
        })
      )
    }),
    allow: PropTypes.shape({
      food: PropTypes.shape({
        meals: PropTypes.array,
        orders: PropTypes.array,
        recipes: PropTypes.array,
      })
    })

  }),
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  match: PropTypes.object
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(Permissions);
