import React from 'react';
import PropTypes from 'prop-types';

import { getCSRFToken } from '../../services/apiService';
import { Card } from '../../shared/components';

import Lifecycle from '../../decorators/Lifecycle';

const I18n = window.I18n;

export const ForgotPassword = ({history, urls, setRouteState}) =>{
  return(
    <Lifecycle
      onMount={ () => {
        setRouteState()
      }}
    >
      <main role='main'>
        <Card title={I18n.t('views.cards.forms.user.forgot_password')}>
          <React.Fragment>
            <form action={urls.resend_password_path} method="post" className='signup'>
              <input type="text" name='authenticity_token' defaultValue={getCSRFToken()} hidden/>
              <label htmlFor="Email">{I18n.t('views.authentication.labels.email')}:</label>
              <input type="text" name='login[email]' placeholder='eg. info@example.com'/>
              <button className='light-green signup'>{I18n.t('views.authentication.actions.send_link')}</button>
            </form>
            <div className='control signup'>
              <button className='light-green' onClick={ () => history.back() }>{I18n.t('views.cards.actions.cancel')}!</button>
            </div>
          </React.Fragment>
        </Card>
      </main>
    </Lifecycle>
  )
}

ForgotPassword.propTypes = {
  history: PropTypes.object,
  urls: PropTypes.object,
  setRouteState: PropTypes.func
}

export default ForgotPassword
