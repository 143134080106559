import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router';
import { BrowserRouter, Switch } from 'react-router-dom';

import { connectModule } from 'redux-modules';
import { compose } from 'recompose';

import Lifecycle from '../decorators/Lifecycle';
import loopProvider from '../decorators/loopProvider';
import mealsModule from '../redux/modules/meals';

import CardWrapper from '../components/Navigation/CardWrapper';
import { ROOT_URL } from '../shared/config';


import { formatDate } from '../shared/utils';

import StocksNew from './stocks/StocksNew';
import StocksView from './stocks/StocksView';
import StocksEdit from './stocks/StocksEdit';

const I18n = window.I18n;

const StockIndex = ({actions, stocks, ingredients, setRouteState}) => {
  const root = ROOT_URL.length > 1 ? ROOT_URL : '';

  const StockList = ({stocks, match, history}) => {
    return(
      <CardWrapper title={I18n.t('views.cards.stock')} className={'stock'} match={match} history={history}>
        {
          stocks.map( (stock, i) =>
            <a onClick={ () => history.push(`${root}/stock/${stock.id}`) } className='item' key={`stock_${i}`}>
              {formatDate(stock.date)}
            </a>)
        }
      </CardWrapper>
    )
  };

  StockList.propTypes = {
    match: PropTypes.object,
    history: PropTypes.object
  }

  return(
    <Lifecycle
      onMount={()=> {
        actions.fetchStocks()
        actions.fetchIngredients(); // stock ingredients
        actions.fetchAI()
        setRouteState()
      }}
    >
      <Switch>
        <Route path={`${root}/stock`} exact render={ props =>
          <StockList  {...props} stocks={stocks}/>
        }/>
        <Route path={`${root}/stock/new/:chef_id?`} render={ props =>
          <StocksNew ingredients={ingredients} {...props} />
        }/>
        <Route path={`${root}/stock/:id`} exact render={ props =>
          <StocksView {...props} />
        }/>
        <Route path={`${root}/stock/edit/:id`} exact render={ props =>
          <StocksEdit {...props} />
        }/>
      </Switch>
    </Lifecycle>
  )
};


StockIndex.propTypes = {
  setRouteState: PropTypes.func,
  actions: PropTypes.shape({
    fetchStocks: PropTypes.func,
    fetchIngredients: PropTypes.func,
    fetchAI: PropTypes.func,
  }),
  ingredients: PropTypes.array,
  stocks: PropTypes.array,
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(StockIndex);
