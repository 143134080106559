import React, { useState } from "react"
import { createStore } from "redux"
import { install } from "redux-loop"
import { compose } from "recompose"
import mealsModule from '../redux/modules/meals';

export const mealsStore = createStore(
  mealsModule.reducer,
  {},
  compose(
    install()
  )
)


const MealsContext = React.createContext({})

const MealsContextProvider = ({ children }) => {
  const [state, updateQuery] = useState({})

  return (
    <MealsContext.Provider
      value={{ ...state, updateQuery }}
      children={children}
    />
  )
}

export const Meals = {
  Provider: MealsContextProvider,
  Consumer: MealsContext.Consumer
}