import React from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';

import { getCSRFToken } from '../../services/apiService';
import { Card } from '../../shared/components';

import Lifecycle from '../../decorators/Lifecycle';

import { ROOT_URL } from '../../shared/config';

const I18n = window.I18n;

export const ResetPassword = ({history, urls, setRouteState}) =>{
  const root   = ROOT_URL.length > 1 ? ROOT_URL : '';
  const params = new URLSearchParams(window.location.search.replace('?', ''));

  return(
    <Lifecycle
      onMount={ () => {
        setRouteState()
      }}
    >
      <main role='main'>
        <Card title={I18n.t('views.cards.forms.user.forgot_password')}>
          <React.Fragment>
            <form action={urls.reset_password_path} method="post" className='signup'>
              <input type="text" name='authenticity_token' defaultValue={getCSRFToken()} hidden/>
              <input type="text" name='token' defaultValue={params.get('token')} hidden/>
              <label htmlFor="Password">{I18n.t('views.authentication.labels.password')}</label>
              <input type="password" name='password'/>
              <label htmlFor="Password Confirmation">{I18n.t('views.authentication.labels.password_confirmation')}</label>
              <input type="password" name='password_confirmation'/>
              <button className='light-green signup'>{I18n.t('views.authentication.actions.reset_password')}</button>
            </form>
          </React.Fragment>
        </Card>
      </main>
    </Lifecycle>
  )
}

ResetPassword.propTypes = {
  history: PropTypes.object,
  urls: PropTypes.object,
  setRouteState: PropTypes.func
}

export default ResetPassword
