import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connectModule } from 'redux-modules';
import Lifecycle from '../../decorators/Lifecycle';

import loopProvider from '../../decorators/loopProvider';
import mealsModule from '../../redux/modules/meals';
import CardWrapper from '../../components/Navigation/CardWrapper';
import Notification from '../../components/Notification/Notification';

import { IngredientForm } from '../../components/Ingredients';
import { parseDate } from '../../shared/utils';

import { translatableWithIngredientIdName } from '../../shared/utils';

const I18n = window.I18n;

const OrderNew = ({orders, basket, ingredients, user, shops, actions, match, history}) => {
  const order = orders.find( e => e.id === parseInt(match.params.id) ) || { shop: '' };
  const items = (order.stock_items || basket.stocks || []);

  const [selectedShopId, setSelectedShopId] = useState();
  const [selectedShop,   setSelectedShop]   = useState({ ingredients: [] });

  const onShopChange = e => {
    const result = e.currentTarget;
    const newValue = result.value;
    const dataset = result.options[result.selectedIndex].dataset;

    actions.fetchShop({id: dataset.id})
      .then( () => {
        setSelectedShopId(parseInt(dataset.id))
        shopNameRef.current.value = newValue;
        shopSelectRef.current.value = '';
      })
  }

  const OfferedIngredients = ({ offered_ingredients }) => {
    const onClick = e => {
      actions.addOrderNotificationItemToBasket({ stock: e.refs })
    }

    return offered_ingredients.length > 0 ?
      <>
        <h3>{I18n.t('views.cards.sections.order.offered_ingredients')}</h3>
        <div className='columns'>
          {
            offered_ingredients.map( e =>
              <a className='item' onClick={ () => onClick(e) } key={e.id}>{translatableWithIngredientIdName(e.refs, ingredients)}</a>
            )
          }
        </div>
      </>: null
  }

  OfferedIngredients.propTypes = {
    offered_ingredients: PropTypes.array
  }

  const shopSelectRef = useRef();
  const shopNameRef = useRef();


  useEffect( () => {
    if (selectedShopId) {
      setSelectedShop(shops.find( e => e.id === selectedShopId)|| { ingredients: [] })
    }
  }, [selectedShopId])

  return (
    <Lifecycle
      onMount={()=> {
          actions.fetchShops()
        }}
      >
        <CardWrapper title={I18n.t('views.cards.orders')} className={'orders'} match={match} history={history}>
          <IngredientForm
            itemName='order'
            selectItems={ingredients}
            items={items}
            offered_ingredients={selectedShop.ingredients}
          >
            <input type="text" name='order[chef_id]' defaultValue={user.chef_id} autoComplete="off" hidden={true}/>
            <div className='fieldset'>
              <label htmlFor='order-shop-name'>{I18n.t('views.cards.shop')}</label>
              <div className='order-shop-select'>
                <input type='text' ref={shopNameRef} name='order[shop][name]' defaultValue={order.shop.name} autoComplete="off" required={true}/>
                <select  onChange={onShopChange} ref={shopSelectRef} disabled={false}>
                  <option key='null' value='' defaultValue=''>{I18n.t('views.cards.actions.quick_select')}</option>
                  { shops.filter( e => !!e.name ).map( e => <option key={`opt${e.id}`} data-id={e.id} value={e.name}>{e.name}</option> ) }
                </select>
              </div>
            </div>
            <div className='fieldset'>
              <label htmlFor="order-date">{I18n.t('views.generic.date')}</label>
              <input
                type='date'
                name='order[date]'
                autoComplete="off"
                defaultValue={
                  parseDate()
                }
              />
            </div>
          </IngredientForm>
          <OfferedIngredients offered_ingredients={selectedShop.ingredients||[]}/>
          <Notification
            match={match}
            history={history}
            types={['Food::Ingredient']}
           />
        </CardWrapper>
    </Lifecycle>
  )
}

OrderNew.propTypes = {
  ingredients: PropTypes.array,
  shops: PropTypes.array,
  orders: PropTypes.array,
  user: PropTypes.object,
  actions: PropTypes.shape({
    fetchShop: PropTypes.func,
    fetchShops: PropTypes.func,
    addOrderNotificationItemToBasket: PropTypes.func,
  }),
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  basket: PropTypes.shape({
    orders: PropTypes.array,
    stocks: PropTypes.array,
  })
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(OrderNew);
