import React from 'react';
import PropTypes from 'prop-types';

import { compose } from 'recompose';
import { connectModule } from 'redux-modules';

import Lifecycle from '../../decorators/Lifecycle';

import loopProvider from '../../decorators/loopProvider';
import mealsModule from '../../redux/modules/meals';

import CardWrapper from '../../components/Navigation/CardWrapper';

import DietsForm from './DietsForm';

const I18n = window.I18n;

const DietsEdit = ({ diets, actions, match, history }) => {
  const diet = diets.find( e => e.id === parseInt(match.params.id) )||{};
  const diet_items = (diet.diet_items || []);

  return (
    <Lifecycle
      onMount={()=> {
          actions.fetchDiet({ id: match.params.id }, { meta: 'whateva' })
        }}
      >
      <CardWrapper title={I18n.t('views.cards.diets')} className={'diets'} match={match} history={history}>
        <DietsForm match={match} history={history} diet_items={diet_items} diet={diet}/>
      </CardWrapper>
    </Lifecycle>
  );
}

DietsEdit.propTypes = {
  diets: PropTypes.array,
  actions: PropTypes.shape({
    fetchDiet: PropTypes.func,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }).isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      state: PropTypes.object
    })
  }).isRequired,
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(DietsEdit);
