import React from 'react';
import PropTypes from 'prop-types';
import serialize from 'form-serialize';
import cn from 'classnames'

import { compose } from 'recompose';
import { connectModule } from 'redux-modules';

import loopProvider from '../decorators/loopProvider';
import mealsModule from '../redux/modules/meals';

import { ROOT_URL } from '../shared/config';

const I18n = window.I18n

const CardControl = props => {
  const root = ROOT_URL.length > 1 ? ROOT_URL : '';
  const history = props.history || { push: ()=>{}, location: '' }
  const match   = props.match || { path: '' }
  const recipes = props.recipes || []
  const { contacts } = !!props.user && Object.keys(props.user).length > 0 ?
    props.user : {
    contacts: {
      food_contacts: [],
      friend_requests: { sent: [], received: [] }
    }
  }

  const {expand} = (history.location.state||{expand: false});

  const {
    mealsFormRef, ingredientFormRef,
    permissionFormRef, categoryFormRef,
    dietFormRef, chefGuestFormRef,
    recipesCategoryFormRef, shopFormRef,
    scheduleFormRef, chefFormRef,
    userInfoFormRef,
  } = props.elements;

  const getMealID = mealsFormRef =>
    serialize(mealsFormRef, {hash: true}).meal.id;

  const getOrderID = ingredientFormRefOrder =>
    serialize(ingredientFormRefOrder, {hash: true}).order.id;

  const getRecipeID = ingredientFormRefRecipe =>
    serialize(ingredientFormRefRecipe, {hash: true}).recipe.id;

  const getStockId = ingredientFormRefStock =>
    serialize(ingredientFormRefStock, {hash: true}).stock.id;

  const backReset = (history, path) => {
    if (history.entries && history.entries.length > 1 ) {
      history.entries = []
      history.push(path, [])
    } else {
      history.goBack()
    }
  }

  const backWithFetch = (action, callback) => {
    action();
    props.actions.fetchAI();
    callback && callback()
  }

  const findRecipeChefId = id =>
    (recipes.find( e => e.id === parseInt(id) )||{ chef_id: 0 })['chef_id']

  const isFriendAlready = id => {
    return(
      contacts.food_contacts.find( e => (e.chefs||[]).find( c => c.id === parseInt(id) ) ) ||
        (contacts.friend_requests.sent||[]).find( e => e.id === parseInt(id)) ||
          (contacts.friend_requests.received||[]).find( e => e.id === parseInt(id))
    )
  }
  const deleteAction = id => {
    props.actions.openModal({
      modalType: 'deleteCategory',
      modalActions: {
        yes: props.actions.deleteCategory.bind(this, {id: id})
      },
      current: 'yes',
      history: history,
      goBack: true,
    })
  }

  const NavigationControl = {
    stockList: [
      { action: id => history.push(`/stockNew/${props.user.chef_id}`), name: I18n.t('views.cards.actions.new_from_avaliable'), id: true },
      { action: id => history.push('/stockNew'), name: I18n.t('views.cards.actions.add_stock'), id: true }
    ],
    stockNew: [
      {
        action: () => props.actions.createStock(serialize(ingredientFormRef.stock, {hash: true}))
          .then(() => backWithFetch(history.push.bind(this, '/stockList')), () => console.log('error') ),
        name: I18n.t('views.cards.actions.save')
      },
      { action: history.goBack, name: I18n.t('views.cards.actions.cancel'), color: 'salmon'}
    ],
    stockShow: [
      { action: id => { history.push(`/stockEdit/${id}`) }, name: I18n.t('views.cards.actions.edit'), id: true },
      { action: history.goBack, name: I18n.t('views.cards.actions.cancel'), color: 'salmon'}
    ],
    stockEdit: [
      {
        action: () => props.actions.updateStock(serialize(ingredientFormRef.stock, {hash: true}))
          .then(() => backWithFetch(history.push.bind(this, `/stockShow/${getStockId(ingredientFormRef.stock)}`)), () => console.log('error') ),
        name: I18n.t('views.cards.actions.save')
      },
      { action: id => history.push(`/stockEdit/${id}`, {expand: true}), name: I18n.t('views.cards.actions.edit'), id: true, hidden: expand },
      { action: backReset.bind(this, history, '/stockList'), name: I18n.t('views.cards.actions.cancel'), color: 'salmon'}
    ],
    recipeList: [
      { action: () => history.push('/recipeNew'), name: I18n.t('views.cards.actions.add_recipe') }
    ],
    recipeNew: [
      {
        action: () => props.actions.createRecipe(serialize(ingredientFormRef.recipe, {hash: true}))
          .then(() => backWithFetch(history.push.bind(this, '/recipeList')), () => console.log('error') ),
        name: I18n.t('views.cards.actions.save')
      },
      { action: history.goBack, name: I18n.t('views.cards.actions.cancel'), color: 'salmon'}
    ],
    recipeShow: [
      { action: id => history.push(`/recipeNew/${id}`), name: I18n.t('views.cards.actions.repeat'), id: true ,
        hideWhen: id => findRecipeChefId(id) !== props.user.chef_id },
      { action: id => { history.push(`/recipeEdit/${id}`) }, name: I18n.t('views.cards.actions.edit'), id: true,
        hideWhen: id => findRecipeChefId(id) !== props.user.chef_id },
      { action: id => history.push(`/recipeNew/${id}`), name: I18n.t('views.cards.actions.clone'), id: true,
        hideWhen: id => findRecipeChefId(id) === props.user.chef_id, disableWhen: () => true },
      { action: backReset.bind(this, history, '/recipeList'), name: I18n.t('views.cards.actions.cancel'), color: 'salmon'}
    ],
    recipeEdit: [
      {
        action: () => props.actions.updateRecipe(serialize(ingredientFormRef.recipe, {hash: true}))
          .then( () => backWithFetch(history.push.bind(this, `/recipeShow/${getRecipeID(ingredientFormRef.recipe)}`)), () => console.log('error') ),
        name: I18n.t('views.cards.actions.save')
      },
      { action: id => history.push(`/recipeEdit/${id}`, {expand: true}), name: I18n.t('views.cards.actions.edit'), id: true, hidden: expand },
      { action: history.goBack, name: I18n.t('views.cards.actions.cancel'), color: 'salmon'}
    ],
    orderList: [
      { action: history.push.bind(this, '/orderNew'), name: I18n.t('views.cards.actions.add_order')  }
    ],
    orderNew: [
      {
        action: () => props.actions.createOrder(serialize(ingredientFormRef.order, {hash: true}))
          .then(() => backWithFetch(history.push.bind(this, '/orderList')), () => console.log('error') ),
        name: I18n.t('views.cards.actions.save'),
      },
      { action: history.goBack, name: I18n.t('views.cards.actions.cancel'), color: 'salmon'}
    ],
    orderShow: [
      { action: id => history.push(`/orderNew/${id}`), name: I18n.t('views.cards.actions.repeat'), id: true },
      { action: id => history.push(`/orderEdit/${id}`), name: I18n.t('views.cards.actions.edit'), id: true },
      { action: backReset.bind(this, history, '/orderList'), name: I18n.t('views.cards.actions.cancel'), color: 'salmon'}
    ],
    orderEdit: [
      {
        action: () => props.actions.updateOrder(serialize(ingredientFormRef.order, {hash: true}))
          .then(() => backWithFetch(history.push.bind(this, `/orderShow/${getOrderID(ingredientFormRef.order)}`)), () => console.log('error') ),
        name: I18n.t('views.cards.actions.save')
      },
      { action: id => history.push(`/orderEdit/${id}`, {expand: true}), name: I18n.t('views.cards.actions.edit'), id: true, hidden: expand },
      { action: history.goBack, name: I18n.t('views.cards.actions.cancel'), color: 'salmon'}
    ],
    mealList: [
      { action: history.push.bind(this, '/mealNew'), name: I18n.t('views.cards.actions.add_meal') }
    ],
    mealNew: [
      {
        action: () => props.actions.createMeal(serialize(mealsFormRef.meal, {hash: true}))
          .then(() => backWithFetch(history.push.bind(this, '/mealList'), props.actions.fetchRecipes), () => console.log('error') ),
        name: I18n.t('views.cards.actions.save')
      },
      { action: history.goBack, name: I18n.t('views.cards.actions.cancel'), color: 'salmon'}
    ],
    mealShow: [
      { action: id => history.push(`/mealNew/${id}`), name: I18n.t('views.cards.actions.repeat'), id: true },
      { action: id => { history.push(`/mealEdit/${id}`) }, name: I18n.t('views.cards.actions.edit'), id: true },
      { action: backReset.bind(this, history, '/mealList'), name: I18n.t('views.cards.actions.cancel'), color: 'salmon'}
    ],
    mealEdit: [
      {
        action: () => props.actions.updateMeal(serialize(mealsFormRef.meal, {hash: true}))
          .then(() => backWithFetch(history.push.bind(this, `/mealShow/${getMealID(mealsFormRef)}`)), () => console.log('error') ),
        name: I18n.t('views.cards.actions.save')
      },
      { action: history.goBack, name: I18n.t('views.cards.actions.cancel'), color: 'salmon'}
    ],
    contactList: [
      { action: history.goBack, name: I18n.t('views.cards.actions.cancel'), color: 'salmon'}
    ],
    notificationList: [
      { action: history.goBack, name: I18n.t('views.cards.actions.cancel'), color: 'salmon'}
    ],
    friendRequestList: [
      { action: history.goBack, name: I18n.t('views.cards.actions.cancel'), color: 'salmon'}
    ],
    permissions: [
      {
        action: () => props.actions.updatePermissions(serialize(permissionFormRef, {hash: true}))
          .then(() => history.push('/contactList'), () => console.log('error') ),
        name: I18n.t('views.cards.actions.save')
      },
      { action: history.goBack, name: I18n.t('views.cards.actions.cancel'), color: 'salmon'}
    ],
    chatMain: [
      { action: () => history.push('/chatMainRoom'), name: I18n.t('views.cards.actions.connect')}
    ],
    chatMainRoom: [
      { action: history.goBack, name: I18n.t('views.cards.actions.disconnect'), color: 'salmon' },
      { action: () => {}, name: I18n.t('views.cards.actions.private') }
    ],
    memberShow: [
      { action: id => props.actions.createFriendRequest({id}), name: I18n.t('views.cards.actions.add_friend'), id: true,
        hideWhen: id => isFriendAlready(id) },
      { action: history.goBack, name: I18n.t('views.cards.actions.cancel'), color: 'salmon' }
    ],
    ingredientList: [
      { action: history.goBack, name: I18n.t('views.cards.actions.cancel'), color: 'salmon' }
    ],
    ingredientEdit: [
      {
        action: () => props.actions.updateIngredient(serialize(ingredientFormRef.ingredient, {hash: true}))
          .then( () => backWithFetch(history.goBack), () => console.log('never called') ),
        name: I18n.t('views.cards.actions.save')
      },
      { action: history.goBack, name: I18n.t('views.cards.actions.cancel'), color: 'salmon' }
    ],
    categoryList: [
      { action: () => history.push('/categoryNew'), name: I18n.t('views.cards.actions.add_category') },
      { action: history.goBack, name: I18n.t('views.cards.actions.cancel'), color: 'salmon' }
    ],
    categoryNew: [
      { action: () => props.actions.createCategory(serialize(categoryFormRef, {hash: true}))
          .then( () => backWithFetch(history.goBack), () => console.log('never called') ),
        name: I18n.t('views.cards.actions.save') },
      { action: history.goBack, name: I18n.t('views.cards.actions.cancel'), color: 'salmon' }
    ],
    categoryEdit: [
      {
        action: () => props.actions.updateCategory(serialize(categoryFormRef, {hash: true}))
          .then( () => backWithFetch(history.goBack), () => console.log('never called') ),
        name: I18n.t('views.cards.actions.save')
      },
      {
        action: id => deleteAction(id), id: true,
        name: I18n.t('views.cards.actions.delete')
      },
      { action: history.goBack, name: I18n.t('views.cards.actions.cancel'), color: 'salmon' }
    ],
    scheduleMeals: [
      {
        action: () => props.actions.scheduleMeals(serialize(scheduleFormRef.meals, {hash: true}))
          .then( () => backWithFetch(history.goBack), () => console.log('never called') ),
        name: I18n.t('views.cards.actions.save')
      },
    ]
  };

  const NewNavigationControl = [
    {
      path: '/stock',
      buttons: [
        { action: id => history.push(`${root}/stock/new/${props.user.chef_id}`), name: I18n.t('views.cards.actions.new_from_avaliable'), id: true },
        { action: id => history.push(`${root}/stock/new`), name: I18n.t('views.cards.actions.add_stock'), id: true }
      ]
    },
    {
      path: '/stock/new/:chef_id?',
      buttons: [
        {
          action: () => props.actions.createStock(serialize(ingredientFormRef.stock, {hash: true}))
            .then(() => backWithFetch(history.push.bind(this, `${root}/stock`)), () => console.log('error') ),
          name: I18n.t('views.cards.actions.save')
        },
        { action: history.goBack, name: I18n.t('views.cards.actions.cancel'), color: 'salmon'}
      ]
    },
    {
      path: '/stock/:id',
      buttons: [
        { action: id => { history.push(`${root}/stock/edit/${id}`) }, name: I18n.t('views.cards.actions.edit'), id: true },
        { action: history.goBack, name: I18n.t('views.cards.actions.cancel'), color: 'salmon'}
      ]
    },
    {
      path: '/stock/edit/:id',
      buttons: [
        {
          action: () => props.actions.updateStock(serialize(ingredientFormRef.stock, {hash: true}))
            .then(() => backWithFetch(history.push.bind(this, `${root}/stock/${getStockId(ingredientFormRef.stock)}`)), () => console.log('error') )
            .then(() => props.actions.fetchAR()),
          name: I18n.t('views.cards.actions.save')
        },
        { action: id => history.push(`${root}/stock/edit/${id}`, {expand: true}), name: I18n.t('views.cards.actions.edit'), id: true, hidden: expand },
        { action: backReset.bind(this, history, `${root}/stock`), name: I18n.t('views.cards.actions.cancel'), color: 'salmon'}
      ]
    },
    {
      path: '/recipes',
      buttons: [
        { action: () => history.push(`${root}/recipes/new`), name: I18n.t('views.cards.actions.add_recipe') }
      ]
    },
    {
      path: '/recipes/new/:id?',
      buttons: [
        {
          action: () => props.actions.createRecipe(serialize(ingredientFormRef.recipe, {hash: true}))
            .then(() => backWithFetch(history.push.bind(this, `${root}/recipes`)), () => console.log('error') ),
          name: I18n.t('views.cards.actions.save')
        },
        { action: history.goBack, name: I18n.t('views.cards.actions.cancel'), color: 'salmon'}
      ]
    },
    {
      path: '/recipes/:id',
      buttons: [
        { action: id => history.push(`${root}/recipes/new/${id}`), name: I18n.t('views.cards.actions.repeat'), id: true ,
          hideWhen: id => findRecipeChefId(id) !== props.user.chef_id },
        { action: id => { history.push(`${root}/recipes/edit/${id}`) }, name: I18n.t('views.cards.actions.edit'), id: true,
          hideWhen: id => findRecipeChefId(id) !== props.user.chef_id },
        { action: id => history.push(`${root}/recipes/new/${id}`), name: I18n.t('views.cards.actions.clone'), id: true,
          hideWhen: id => findRecipeChefId(id) === props.user.chef_id, disableWhen: () => true },
        { action: backReset.bind(this, history, `${root}/recipes`), name: I18n.t('views.cards.actions.cancel'), color: 'salmon'}
      ]
    },
    {
      path: '/recipes/edit/:id',
      buttons: [
        {
          action: () => props.actions.updateRecipe(serialize(ingredientFormRef.recipe, {hash: true}))
            .then( () => backWithFetch(history.push.bind(this, `${root}/recipes/${getRecipeID(ingredientFormRef.recipe)}`)), () => console.log('error') ),
          name: I18n.t('views.cards.actions.save')
        },
        { action: id => history.push(`${root}/recipes/edit/${id}`, {expand: true}), name: I18n.t('views.cards.actions.edit'), id: true, hidden: expand },
        { action: history.goBack, name: I18n.t('views.cards.actions.cancel'), color: 'salmon'}
      ]
    },
    {
      path: '/orders',
      buttons: [
        { action: () => history.push(`${root}/orders/new`), name: I18n.t('views.cards.actions.add_order')  }
      ]
    },
    {
      path: '/orders/new/:id?',
      buttons: [
        {
          action: () => props.actions.createOrder(serialize(ingredientFormRef.order, {hash: true}))
            .then(() => backWithFetch(history.push.bind(this, `${root}/orders`)), () => console.log('error') ),
          name: I18n.t('views.cards.actions.save'),
        },
        { action: history.goBack, name: I18n.t('views.cards.actions.cancel'), color: 'salmon'}
      ]
    },
    {
      path: '/orders/:id',
      buttons: [
        { action: id => history.push(`${root}/orders/new/${id}`), name: I18n.t('views.cards.actions.repeat'), id: true },
        { action: id => history.push(`${root}/orders/edit/${id}`), name: I18n.t('views.cards.actions.edit'), id: true },
        { action: backReset.bind(this, history, `${root}/orders`), name: I18n.t('views.cards.actions.cancel'), color: 'salmon'}
      ]
    },
    {
      path: '/orders/edit/:id',
      buttons: [
        {
          action: () => props.actions.updateOrder(serialize(ingredientFormRef.order, {hash: true}))
            .then(() => backWithFetch(history.push.bind(this, `${root}/orders/${getOrderID(ingredientFormRef.order)}`)), () => console.log('error') ),
          name: I18n.t('views.cards.actions.save')
        },
        { action: id => history.push(`${root}/orders/edit/${id}`, {expand: true}), name: I18n.t('views.cards.actions.edit'), id: true, hidden: expand },
        { action: history.goBack, name: I18n.t('views.cards.actions.cancel'), color: 'salmon'}
      ]
    },
    {
      path: '/meals',
      buttons: [
        { action: () => history.push(`${root}/meals/new`), name: I18n.t('views.cards.actions.add_meal') }
      ]
    },
    {
      path: '/meals/new/:id?',
      buttons: [
        {
          action: () => props.actions.createMeal(serialize(mealsFormRef.meal, {hash: true}))
            .then(() => backWithFetch(history.push.bind(this, `${root}/meals`), props.actions.fetchRecipes), () => console.log('error') ),
          name: I18n.t('views.cards.actions.save')
        },
        { action: history.goBack, name: I18n.t('views.cards.actions.cancel'), color: 'salmon'}
      ]
    },
    {
      path: '/meals/:id',
      buttons: [
        { action: id => history.push(`${root}/meals/new/${id}`), name: I18n.t('views.cards.actions.repeat'), id: true },
        { action: id => { history.push(`${root}/meals/edit/${id}`) }, name: I18n.t('views.cards.actions.edit'), id: true },
        { action: backReset.bind(this, history, `${root}/meals`), name: I18n.t('views.cards.actions.cancel'), color: 'salmon'}
      ]
    },
    {
      path: '/meals/edit/:id',
      buttons: [
        {
          action: () => props.actions.updateMeal(serialize(mealsFormRef.meal, {hash: true}))
            .then(() => backWithFetch(history.push.bind(this, `${root}/meals/${getMealID(mealsFormRef.meal)}`)), () => console.log('error') ),
          name: I18n.t('views.cards.actions.save')
        },
        { action: history.goBack, name: I18n.t('views.cards.actions.cancel'), color: 'salmon'}
      ]
    },
    {
      path: '/my/contacts',
      buttons: [
        { action: history.goBack, name: I18n.t('views.cards.actions.cancel'), color: 'salmon'}
      ]
    },
    {
      path: '/my/notifications',
      buttons: [
        { action: history.goBack, name: I18n.t('views.cards.actions.cancel'), color: 'salmon'}
      ]
    },
    {
      path: '/my/friend-requests',
      buttons: [
        { action: history.goBack, name: I18n.t('views.cards.actions.cancel'), color: 'salmon'}
      ]
    },
    {
      path: '/my/permissions/:user_id?',
      buttons: [
        {
          action: () => props.actions.updatePermissions(serialize(permissionFormRef, {hash: true}))
            .then(() => history.push(`${root}/my/contacts`), () => console.log('error') ),
          name: I18n.t('views.cards.actions.save')
        },
        { action: history.goBack, name: I18n.t('views.cards.actions.cancel'), color: 'salmon'}
      ]
    },
    {
      path: 'chatMain',
      buttons: [
        { action: () => history.push('/chatMainRoom'), name: I18n.t('views.cards.actions.connect')}
      ]
    },
    {
      path: 'chatMainRoom',
      buttons: [
        { action: history.goBack, name: I18n.t('views.cards.actions.disconnect'), color: 'salmon' },
        { action: () => {}, name: I18n.t('views.cards.actions.private') }
      ]
    },
    {
      path: '/members/:id',
      buttons: [
        { action: id => props.actions.createFriendRequest({id}), name: I18n.t('views.cards.actions.add_friend'), id: true,
          hideWhen: id => isFriendAlready(id) },
        { action: history.goBack, name: I18n.t('views.cards.actions.cancel'), color: 'salmon' }
      ]
    },
    {
      path: '/ingredients',
      buttons: [
        { action: history.goBack, name: I18n.t('views.cards.actions.cancel'), color: 'salmon' }
      ]
    },
    {
      path: '/ingredients/:id',
      buttons: [
        {
          action: () => props.actions.updateIngredient(serialize(ingredientFormRef.ingredient, {hash: true}))
            .then( () => backWithFetch(history.goBack), () => console.log('never called') ),
          name: I18n.t('views.cards.actions.save')
        },
        { action: history.goBack, name: I18n.t('views.cards.actions.cancel'), color: 'salmon' }
      ]
    },
    {
      path: '/categories',
      buttons: [
        { action: () => history.push(`${root}/categories/new`), name: I18n.t('views.cards.actions.add_category') },
        { action: history.goBack, name: I18n.t('views.cards.actions.cancel'), color: 'salmon' }
      ]
    },
    {
      path: '/categories/new',
      buttons: [
        { action: () => props.actions.createCategory(serialize(categoryFormRef, {hash: true}))
            .then( () => backWithFetch(history.goBack), () => console.log('never called') ),
          name: I18n.t('views.cards.actions.save') },
        { action: history.goBack, name: I18n.t('views.cards.actions.cancel'), color: 'salmon' }
      ]
    },
    {
      path: '/categories/:id',
      buttons: [
        {
          action: () => props.actions.updateCategory(serialize(categoryFormRef, {hash: true}))
            .then( () => backWithFetch(history.goBack), () => console.log('never called') ),
          name: I18n.t('views.cards.actions.save')
        },
        {
          action: id => deleteAction(id), id: true,
          name: I18n.t('views.cards.actions.delete')
        },
        { action: history.goBack, name: I18n.t('views.cards.actions.cancel'), color: 'salmon' }
      ]
    },
    {
      path: '/diets',
      buttons: [
        { action: () => history.push(`${root}/diets/new`), name: I18n.t('views.cards.actions.add_diet') }
      ]
    },
    {
      path: '/diets/new/:id?',
      buttons: [
        {
          action: () => props.actions.createDiet(serialize(dietFormRef, { hash: true }))
            .then( () => backWithFetch(history.goBack), () => console.log('never called') ),
          name: I18n.t('views.cards.actions.save')
        },
        { action: history.goBack, name: I18n.t('views.cards.actions.cancel'), color: 'salmon'}
      ]
    },
    {
      path: '/diets/:id',
      buttons: [
        {
          action: id => { history.push(`${root}/diets/edit/${id}`) }, name: I18n.t('views.cards.actions.edit'), id: true
        },
        { action: backReset.bind(this, history, `${root}/diets`), name: I18n.t('views.cards.actions.cancel'), color: 'salmon'}
      ]
    },
    {
      path: '/diets/edit/:id',
      buttons: [
        {
          action: () => props.actions.updateDiet(serialize(dietFormRef, { hash: true }))
            .then( () => backWithFetch(history.goBack), () => console.log('never called') ),
          name: I18n.t('views.cards.actions.save')
        },
        { action: history.goBack, name: I18n.t('views.cards.actions.cancel'), color: 'salmon'}
      ]
    },
    {
      path: '/shops',
      buttons: [
        { action: history.goBack, name: I18n.t('views.cards.actions.cancel'), color: 'salmon' }
      ]
    },
    {
      path: '/shops/:id',
      buttons: [
        {
          action: id => { history.push(`${root}/shops/edit/${id}`) }, name: I18n.t('views.cards.actions.edit'), id: true
        },
        { action: backReset.bind(this, history, `${root}/shops`), name: I18n.t('views.cards.actions.cancel'), color: 'salmon'}
      ]
    },
    {
      path: '/shops/edit/:id',
      buttons: [
        {
          action: () => props.actions.updateShop(serialize(shopFormRef, {hash: true}))
            .then( () => backWithFetch(history.goBack), () => console.log('never called') ),
          name: I18n.t('views.cards.actions.save')
        },
        { action: history.goBack, name: I18n.t('views.cards.actions.cancel'), color: 'salmon' }
      ]
    },
    {
      path: '/recipes_categories',
      buttons: [
        { action: () => history.push(`${root}/recipes_categories/new`), name: I18n.t('views.cards.actions.add_category') },
        { action: history.goBack, name: I18n.t('views.cards.actions.cancel'), color: 'salmon' },
      ]
    },
    {
      path: '/recipes_categories/:id',
      buttons: [
        {
          action: () => props.actions.updateRecipesCategory(serialize(recipesCategoryFormRef, {hash: true}))
            .then( () => backWithFetch(history.goBack), () => console.log('never called') ),
          name: I18n.t('views.cards.actions.save')
        },
        { action: history.goBack, name: I18n.t('views.cards.actions.cancel'), color: 'salmon' }
      ]
    },
    {
      path: '/recipes_categories/new',
      buttons: [
        {
          action: () => props.actions.createRecipesCategory(serialize(recipesCategoryFormRef, { hash: true }))
            .then( () => backWithFetch(history.goBack), () => console.log('never called') ),
          name: I18n.t('views.cards.actions.save')
        },
        { action: history.goBack, name: I18n.t('views.cards.actions.cancel'), color: 'salmon'}
      ]
    },
    {
      path: '/my/guests',
      buttons: [
        { action: backReset.bind(this, history, `${root}/settings`), name: I18n.t('views.cards.actions.cancel'), color: 'salmon' }
      ]
    },
    {
      path: '/my/guests/:id',
      buttons: [
        {
          action: id => { history.push(`${root}/my/guests/edit/${id}`) }, name: I18n.t('views.cards.actions.edit'), id: true
        },
        { action: backReset.bind(this, history, `${root}/my/guests`), name: I18n.t('views.cards.actions.cancel'), color: 'salmon'}
      ]
    },
    {
      path: '/my/guests/edit/:id',
      buttons: [
        {
          action: () => props.actions.updateChefGuest(serialize(chefGuestFormRef, { hash: true }))
            .then( () => backWithFetch(history.goBack), () => console.log('never called') ),
          name: I18n.t('views.cards.actions.save')
        },
        { action: history.goBack, name: I18n.t('views.cards.actions.cancel'), color: 'salmon'}
      ]
    },
    {
      path: '/my/chefs',
      buttons: [
        { action: backReset.bind(this, history, `${root}/settings`), name: I18n.t('views.cards.actions.cancel'), color: 'salmon' }
      ]
    },
    {
      path: '/my/chefs/:id',
      buttons: [
        {
          action: id => { history.push(`${root}/my/chefs/edit/${id}`) }, name: I18n.t('views.cards.actions.edit'), id: true
        },
        { action: backReset.bind(this, history, `${root}/my/chefs`), name: I18n.t('views.cards.actions.cancel'), color: 'salmon'}
      ]
    },
    {
      path: '/my/chefs/edit/:id',
      buttons: [
        {
          action: () => props.actions.updateChef(serialize(chefFormRef, { hash: true }))
            .then( () => backWithFetch(history.goBack), () => console.log('never called') ),
          name: I18n.t('views.cards.actions.save')
        },
        { action: history.goBack, name: I18n.t('views.cards.actions.cancel'), color: 'salmon'}
      ]
    },
    {
      path: '/my/info',
      buttons: [
        {
          action: () => props.actions.updateUserInfo(serialize(userInfoFormRef, {hash: true}))
            .then(() => history.push(`${root}/settings`), () => console.log('error') ),
          name: I18n.t('views.cards.actions.save')
        },
        { action: history.goBack, name: I18n.t('views.cards.actions.cancel'), color: 'salmon'}
      ]
    },
  ];
  const emptyButtons = [{ action: () => {}, name: '', color: 'background', disabled: true }]
  const oldButtons   = (NavigationControl[props.match.params.action] || []).filter(e => !e.hidden);
  const newButtons   = (NewNavigationControl.find( e => `${root}${e.path}` === match.path) || { buttons: emptyButtons }).buttons.filter(e => !e.hidden)
  // console.log(match)
  const buttons = oldButtons.length === 0 ? newButtons : oldButtons;

  const bindAction = button => {
    if (props.match.params.id && button.id) {
      return button.action.bind(this, props.match.params.id)
    } else if (button.bind) {
      return button.action.bind(this, props)
    } else {
      return button.action
    }
  }
  const bindAbility = button => {
    if (props.match.params.id && typeof button.disableWhen === 'function') {
      return button.disableWhen(props.match.params.id)
    }
    if (button.disabled) { return true }
  }

  const bindVisibility = button => {
    if (props.match.params.id && typeof button.hideWhen === 'function') {
      return button.hideWhen(props.match.params.id)
    }
  }

  return(
    <div className={cn('control', { hidden: buttons.every( e => e.disabled ) })}>
      { buttons.map( (button,i) => <button className={button.color||'light-green'}
          onClick={ bindAction(button) }
          key={i}
          disabled={bindAbility(button)}
          hidden={bindVisibility(button)}>
            {button.name}
          </button> )
      }
    </div>
  )
}

CardControl.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  actions: PropTypes.shape({
    fetchAI: PropTypes.func,
    fetchRecipes: PropTypes.func,
    createMeal: PropTypes.func,
    updateMeal: PropTypes.func,
    createRecipe: PropTypes.func,
    updateRecipe: PropTypes.func,
    createOrder: PropTypes.func,
    updateOrder: PropTypes.func,
    createStock: PropTypes.func,
    updateStock: PropTypes.func,
    updatePermissions: PropTypes.func,
    createFriendRequest: PropTypes.func,
    updateIngredient: PropTypes.func,
    updateCategory: PropTypes.func,
    openModal: PropTypes.func,
    deleteCategory: PropTypes.func,
    createCategory: PropTypes.func,
    createDiet: PropTypes.func,
    updateDiet: PropTypes.func,
    deleteDiet: PropTypes.func,
    updateChefGuest: PropTypes.func,
    fetchAR: PropTypes.func,
    updateShop: PropTypes.func,
    updateRecipesCategory: PropTypes.func,
    createRecipesCategory: PropTypes.func,
    scheduleMeals: PropTypes.func,
    updateChef: PropTypes.func,
    updateUserInfo: PropTypes.func,
  }).isRequired,
  elements: PropTypes.shape({
    mealsFormRef: PropTypes.object,
    ingredientFormRef: PropTypes.object,
    permissionFormRef: PropTypes.object,
    categoryFormRef: PropTypes.object,
    dietFormRef: PropTypes.object,
    chefGuestFormRef: PropTypes.object,
    recipesCategoryFormRef: PropTypes.object,
    shopFormRef: PropTypes.object,
    scheduleFormRef: PropTypes.object,
    chefFormRef: PropTypes.object,
    userInfoFormRef: PropTypes.object,
  }).isRequired,
  user: PropTypes.shape({
    chef_id: PropTypes.any
  }).isRequired,
  recipes: PropTypes.array,
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(CardControl);

