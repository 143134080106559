import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router';
import { BrowserRouter } from 'react-router-dom';

import { connectModule } from 'redux-modules';
import { compose } from 'recompose';

import loopProvider from '../../../decorators/loopProvider';
import mealsModule from '../../../redux/modules/meals';

import Lifecycle from '../../../decorators/Lifecycle';
import { ROOT_URL } from '../../../shared/config';

import ChefList from './ChefList';
import ChefView from './ChefView';
import ChefEdit from './ChefEdit';

const Chefs = ({ user, diets, actions}) => {
  const root = ROOT_URL.length > 1 ? ROOT_URL : '';
  const chefs = (user.chefs || []).filter( e => e.user_id === user.id )

  return(
    <Lifecycle
      onMount={ () => {
      }}
    >
      <BrowserRouter
        initialEntries={[`${root}/my/chefs`]}
        initialIndex={0}
      >
        <Route path={`${root}/my/chefs`} exact render={ routeProps =>
          <ChefList
            chefs={chefs}
            actions={actions}
            {...routeProps}
          />
        }/>
        <Route path={`${root}/my/chefs/edit/:id`} exact render={ routeProps =>
          <ChefEdit
            chefs={chefs}
            diets={diets}
            actions={actions}
            {...routeProps}
          />
        }/>
        <Route path={`${root}/my/chefs/:id`} exact render={ routeProps =>
          <ChefView
            chefs={chefs}
            actions={actions}
            {...routeProps}
          />
        }/>
      </BrowserRouter>
    </Lifecycle>
  )
}

Chefs.propTypes = {
  setRouteState: PropTypes.func,
  actions: PropTypes.shape({
    fetchChefGuests: PropTypes.func
  }),
  user: PropTypes.shape({
    id: PropTypes.number,
    chefs: PropTypes.array
  }),
  diets: PropTypes.array,
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  match: PropTypes.object
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(Chefs);
