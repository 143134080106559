import React, { useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import serialize from 'form-serialize';

import { postNotification } from '../../services/apiService'

import { compose } from 'recompose';
import { connectModule } from 'redux-modules';

import loopProvider from '../../decorators/loopProvider';
import mealsModule from '../../redux/modules/meals';

import { translatableWithDietableIdName, translatableWithIngredientIdName, translatableName, parseDate } from '../../shared/utils';


const I18n = window.I18n

const DietItem = ({ item, date, recipes, categories, recipes_categories, ingredients, meals, chef_guests, actions, user, elements }) => {
  const { name, meal_period, dietable_type, dietable_id } = item;
  const { active_diets: { open_items, meal_periods } } = user.reports || { active_diets: { open_items: [], new_items: [] } };
  const { mealsFormRef } = elements;

  const chef = user.chefs.find( i => i.id === user.chef_id ) || {}
  const ar   = chef.ar || []
  const ai   = chef.ai || []

  const cache = (type, id) => {
    let target
    let items

    switch(type) {
      case 'Food::Recipe':
        target = (recipes.find( e => e.id === id) || {})
        items  = (target.recipe_ingredients || [])
        break;
      case 'Food::Category':
        target = (categories.find( e => e.id === id) || {})
        items  = (target.effective_recipes || [])
        break;
      case 'Food::RecipeCategory':
        target = (recipes_categories.find( e => e.id === id) || {})
        items  = (target.effective_recipes || [])
        break;
    }
    return([target, items])
  }

  const [target, items] = cache(dietable_type, dietable_id)
  const isCached = (target, items) => target.id && items.length > 0
  const isOpen = (currentValue) => {
    const equal = (one, other) =>
      one.period === other.period &&
      one.level0 === other.level0 &&
      one.level1 === other.level1

    return !!open_items.find(element => equal(element, currentValue))
  }

  const isAvailableRecipe = (id) =>
    !!ar.find( i => i.missing === 0 && i.id === id )

  const isAvailableIngredient = (id) =>
    !!ai.find( i => i.id === id )

  const newMealItem = (currentValue) => {
    const equal = (one, other) =>
      one.period === other.period &&
      one.level0 === other.level0 &&
      one.level1 === other.level1

    return(open_items.find(element => equal(element, currentValue)).meal || {})
  }

  const DietCollectionItems = ({ items }) => {
    if (!isOpen({ period: item.period, level0: item.id })) { return null }

    const RecipeCollectionAddMeal = ({ meal, mealRef, id }) => {
      const onSaveMealItem = e => {
        e.preventDefault()

        actions.scheduleMeals(serialize(mealsFormRef[`meal_${date}_${id}`], {hash: true}))
          .then( () =>
            actions.reportsActiveDietsRemoveNewMealItem({
              period: item.period,
              level0: item.id,
              level1: id
            })
          )

      }

      const onCancelMealItem = e => {
        e.preventDefault()

        actions.reportsActiveDietsRemoveNewMealItem({
          period: item.period,
          level0: item.id,
          level1: id
        })
      }

      useEffect( () => {
        if (!elements.mealsFormRef[`meal_${date}_${id}`]) {
          actions.setMealFormElement({[`meal_${date}_${id}`]: mealRef.current})
        }
      },[])

      return(
        <form ref={mealRef}>
          <ul className='header'>
            <li className='level2'>{I18n.t('views.cards.dashboards.schedule_meals.meal_period')}</li>
            <li>{I18n.t('views.cards.dashboards.schedule_meals.guest')}</li>
            <li>{I18n.t('views.cards.dashboards.schedule_meals.recipe')}</li>
          </ul>
          {
            [meal].flatMap( (m, index) =>
              m.meal_dishes.map( (i) =>
                <ul key={`${m.id||'null'}_${i.id||'null'}_${i.chef_guest_id}_${i.recipe_id}_${index}`}>
                  <li className='level2'>
                    <input type="text" name='meals[id][]' defaultValue={m.id||'null'} hidden={true}/>
                    <input type="text" name='meals[date][]' defaultValue={m.date||date} hidden={true}/>
                    <select defaultValue={meal_period} name='meals[meal_period][]'>
                      <option key='null' value='' defaultValue=''></option>
                      {
                        meal_periods.map( type =>
                          <option key={`opt${type}`} value={type}>{ I18n.t(type, { scope: 'views.cards.forms.diet.meals.meal_periods'})}</option>
                        )
                      }
                    </select>
                  </li>
                  <li>
                    <input type="text" name='meals[dishes][id][]' defaultValue={i.id||'null'} hidden={true}/>
                    <select name='meals[dishes][chef_guest_id][]' defaultValue={i.chef_guest_id}>
                      <option key='null' value='' defaultValue=''></option>
                      {
                        chef_guests.map( (e,index) =>
                          <option key={index} value={e.id} selected={i.chef_guest_id === e.id}>{e.name}</option>
                        )
                      }
                    </select>
                  </li>
                  <li>
                    <select name='meals[dishes][recipe_id][]' defaultValue={i.recipe.id}>
                      <option key='null' value='' defaultValue=''></option>
                      {
                        recipes.map( (e,index) =>
                          <option key={index} value={e.id} selected={i.recipe_id === e.id}>{e.name}</option>
                        )
                      }
                    </select>
                  </li>
                </ul>
              )
            )
          }
          <ul className='footer'>
            <li className='level2'>
              <input
                type='date'
                name='meal[date]'
                autoComplete="off"
                defaultValue={
                  parseDate(meal.date)
                }
              />
            </li>
            <li>
              <button onClick={onSaveMealItem}>Save</button>
            </li>
            <li>
              <button onClick={onCancelMealItem}>Cancel</button>
            </li>
          </ul>
        </form>
      )
    }

    RecipeCollectionAddMeal.propTypes = {
      id: PropTypes.number,
      meal: PropTypes.object,
      mealRef: PropTypes.object,
      callback: PropTypes.func
    }

    const RecipeCollectionItems = ({ id }) => {
      const [recipe, items] = cache('Food::Recipe', id)
      const currentValue    = { period: item.period, level0: item.id, level1: id }
      const mealRef         = useRef()

      const onAddMealToSchedule = e => {
        const meal            = {}
        const { meal_dishes } = (meals[meals.length -1] || { meal_dishes: [] })

        meal.name        = recipe.name
        meal.date        = date
        meal.meal_dishes = meal_dishes.map( e => Object.assign(e, { recipe }) )

        actions.reportsActiveDietsAddNewMealItem({
          period: item.period,
          level0: item.id,
          level1: id,
          meal: meal
        })
      }

      if (!isOpen(currentValue)) { return null }

      const meal       = newMealItem(currentValue)
      const hasNewItem = Object.keys(meal).length > 0

      return(
        <React.Fragment>
          {
            items.map( (i, index) => // ingredients
              <a className='item' key={`recipe_${index}`}>
                <span className={cn('level2', {green: isAvailableIngredient(i.ingredient_id)})}>
                  {translatableWithIngredientIdName(i, ingredients)}
                </span>
                <span className={cn({green: isAvailableIngredient(i.ingredient_id)})}>
                  {`${i.quantity} ${I18n.t(i.measure, {scope: 'quantity'})}`}
                </span>
              </a>
            )
          }
          <a className='item'>
            <button onClick={onAddMealToSchedule}>
              {I18n.t('views.cards.actions.add_to_schedule')}
            </button>
          </a>
          { hasNewItem ? <RecipeCollectionAddMeal mealRef={mealRef} meal={meal} id={id}/> : null}
        </React.Fragment>
      )
    }

    RecipeCollectionItems.propTypes = {
      id: PropTypes.number
    }

    const onRecipeItemClick = id => {
      actions.reportsActiveDietsOpenItem({
        period: item.period,
        level0: item.id,
        level1: id
      })

      fetchRecipeItem(id)
    }

    const fetchRecipeItem   = id => {
      const [target,items] = cache('Food::Recipe', id)

      if ( isCached(target, items) ) return

      actions.fetchRecipe({ id })
    }

        // console.log(items)
    switch(item.dietable_type) {
      case 'Food::Recipe':
        return( items.map( (i, index) => // ingredients
            <a className='item' key={`rec_${index}`}>
              <span className={cn('level1', {green: isAvailableIngredient(i.ingredient_id)})}>
                {translatableWithIngredientIdName(i, ingredients)}
              </span>
              <span className={cn({green: isAvailableIngredient(i.ingredient_id)})}>
                {`${i.quantity} ${I18n.t(i.measure, {scope: 'quantity'})}`}
              </span>
            </a>
          )
        )
      case 'Food::Category':
        return( items.map( (i, index) => // recipes
            <React.Fragment key={`cat_${index}`}>
              <a className='item' onClick={ () => onRecipeItemClick(i.id) }>
                <span className={cn('level1',{green: isAvailableRecipe(i.id)})}>
                  {translatableName(i)}
                </span>
              </a>
              <RecipeCollectionItems id={i.id}/>
            </React.Fragment>
          )
        )
      case 'Food::RecipeCategory':
        return( items.map( (i, index) => // recipes
            <React.Fragment key={`rec_cat_${index}`}>
              <a className='item' onClick={ () => onRecipeItemClick(i.id) }>
                <span className={cn('level1',{green: isAvailableRecipe(i.id)})}>
                  {translatableName(i)}
                </span>
              </a>
              <RecipeCollectionItems id={i.id}/>
            </React.Fragment>
          )
        )
    }
  }

  const onDietableItemClick = () => {
    actions.reportsActiveDietsOpenItem({
      period: item.period,
      level0: item.id
    })

    fetchResources()

    if (ai.length === 0) actions.fetchAI()
    if (ar.length === 0) actions.fetchAR()
  }

  const fetchResources = () => {
    if (isCached(target, items)) return null

    switch(dietable_type) {
      case 'Food::Recipe':
        actions.fetchRecipe({ id: dietable_id })
        break;
      case 'Food::Category':
        actions.fetchCategory(dietable_id)
        break;
      case 'Food::RecipeCategory':
        actions.fetchRecipesCategory({ id: dietable_id })
        break;
    }
  }

  const isAvailable = () => {
    switch(dietable_type) {
      case 'Food::Recipe':
        return isAvailableRecipe(dietable_id);
      case 'Food::Category':
        return isAvailableIngredient(dietable_id);
      case 'Food::RecipeCategory':
        return null;
    }
  }

  const translatedName = () => {
    switch(dietable_type) {
      case 'Food::Recipe':
        return name;
      case 'Food::Category':
        return translatableWithDietableIdName(item, categories);
      case 'Food::RecipeCategory':
        return translatableWithDietableIdName(item, recipes_categories);
    }
  }

  return(
    <>
      <a className='item' onClick={onDietableItemClick}>
        <span className={cn({green: isAvailable()})}>
          {translatedName()}
        </span>
        <span>{I18n.t(meal_period, { scope: 'views.cards.forms.diet.meals.meal_periods'})}</span>
      </a>
      <DietCollectionItems
        items={items}
      />
    </>
  )
}

DietItem.propTypes = {
  ingredients: PropTypes.array,
  user: PropTypes.shape({
    chef_id: PropTypes.number,
    chefs: PropTypes.arrayOf(PropTypes.shape({
        ar: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number
          })
        ),
        ai: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number
          })
        ),
      })
    ),
    reports: PropTypes.object
  }),
  date: PropTypes.string,
  item: PropTypes.shape({
    chef_id: PropTypes.number,
    id: PropTypes.number,
    name: PropTypes.string,
    period: PropTypes.number,
    meal: PropTypes.string,
    meal_period: PropTypes.string,
    dietable_id: PropTypes.number,
    dietable_type: PropTypes.string,
  }),
  actions: PropTypes.shape({
    fetchRecipe: PropTypes.func,
    fetchCategory: PropTypes.func,
    fetchRecipesCategory: PropTypes.func,
    reportsActiveDietsOpenItem: PropTypes.func,
    fetchAI: PropTypes.func,
    fetchAR: PropTypes.func,
    reportsActiveDietsAddNewMealItem: PropTypes.func,
    reportsActiveDietsRemoveNewMealItem: PropTypes.func,
    setMealFormElement: PropTypes.func,
    scheduleMeals: PropTypes.func,
  }),
  recipes: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  ),
  categories: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  ),
  recipes_categories: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  ),
  meals: PropTypes.array,
  chef_guests: PropTypes.array,
  elements: PropTypes.shape({
    mealsFormRef: PropTypes.object
  })
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(DietItem);
