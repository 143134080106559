import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connectModule } from 'redux-modules';
import { compose } from 'recompose';

import Lifecycle from '../../decorators/Lifecycle';
import loopProvider from '../../decorators/loopProvider';
import mealsModule from '../../redux/modules/meals';

import CardWrapper from '../../components/Navigation/CardWrapper';
import { ROOT_URL } from '../../shared/config';

import flatten from 'lodash/flatten';

const I18n = window.I18n;

const MembersList = ({actions: { fetchMembers}, members, loading, history, match}) => {
  const [ term, setTerm ] = useState('')
  const root = ROOT_URL.length > 1 ? ROOT_URL : '';

  const onChange = e => setTerm(e.target.value)

  useEffect( () => {
    if (term.length > 2 && !loading) return
  })

  const filterCallback = member => {
    if (term.length > 2) {
      return member.name.match(term)
    } else {
      return true
    }
  }

  return (
    <Lifecycle
      onMount={ () => fetchMembers() }
    >
      <CardWrapper title={I18n.t('views.cards.members')} className={'members'} match={match} history={history}>
        <div className='fieldset filter'>
          <label htmlFor="search">{I18n.t('views.generic.actions.search')}</label>
          <input type="text" onChange={onChange}/>
        </div>
        {
          flatten(members.map( e => e.chefs )).filter(filterCallback).map( chef => {
            return(
              <a className='item' key={chef.id} onClick={ () => history.push(`${root}/members/${chef.id}`) }>
                <span>{chef.name}</span>
                <span>{chef.stats.recipes}</span>
              </a>
            )
          })
        }
      </CardWrapper>
    </Lifecycle>
  )
};

MembersList.propTypes = {
  actions: PropTypes.shape({
    fetchMembers: PropTypes.func
  }),
  members: PropTypes.array,
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  match: PropTypes.object,
  loading: PropTypes.bool
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(MembersList);
