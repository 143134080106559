import React from 'react';
import PropTypes from 'prop-types';

import { compose } from 'recompose';
import { connectModule } from 'redux-modules';

import loopProvider from '../../decorators/loopProvider';
import mealsModule from '../../redux/modules/meals';

import Lifecycle from '../../decorators/Lifecycle';
import CardWrapper from '../../components/Navigation/CardWrapper';
import { ROOT_URL } from '../../shared/config';

import { translatableWithIngredientIdName, translatableName, translatableAttributeWithFallback } from '../../shared/utils';

import uniqBy from 'lodash/uniqBy';

const I18n = window.I18n;

const RecipesView = ({ recipes=[], ingredients, user, actions, match, history }) => {
  const root = ROOT_URL.length > 1 ? ROOT_URL : '';
  const recipe = recipes.find( e => e.id === parseInt(match.params.id) ) || { name: '', description: '', people: ''};
  const recipe_ingredients = (recipe.recipe_ingredients || [])

  const RecipeIgnredients = ({recipe_ingredients}) => {
    return recipe_ingredients.map( e => {
      return(
        <a onClick={ () => user.admin && history.push(`${root}/ingredients/${e.ingredient_id}`) } className='item' key={`recipe_${e.id}`}>
          <span>{translatableWithIngredientIdName(e, ingredients)}</span>
          <span>{`${e.quantity} ${I18n.t(e.measure, {scope: 'quantity'})}`}</span>
        </a>
      )
    });
  }

  const RecipeCategories = ({ recipe }) => {
    const IngredientsCategories = () =>
      <div className='category_type'>
        <ul className='header'>
          <li>{I18n.t('views.cards.forms.recipe.ingredients_categories')}</li>
        </ul>
        <ul>
          {
            uniqBy(recipe.ingredients_categories, 'name').map( e =>
              <li key={e.id}>{translatableName(e)}</li>
            )
          }
        </ul>
      </div>

    const RecipeCategories = () =>
      <div className='category_type'>
        <ul className='header'>
          <li>{I18n.t('views.cards.forms.recipe.recipe_categories')}</li>
        </ul>
        <ul>
          {
            uniqBy(recipe.recipe_categories, 'name').map( e =>
              <li key={e.id}>{translatableName(e)}</li>
            )
          }
        </ul>
      </div>

    return(
      <summary className='categories'>
        <IngredientsCategories />
        <RecipeCategories />
      </summary>
    )
  }

  RecipeCategories.propTypes = {
    recipe: PropTypes.shape({
      ingredients_categories: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string
        })
      ),
      recipe_categories: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string
        })
      )
    })
  }


  return (
    <Lifecycle
      onMount={()=> {
          actions.fetchRecipe({ id: match.params.id }, { meta: 'whateva' })
        }}
      >
        <CardWrapper title={I18n.t('views.cards.recipe')} className={'recipes'} match={match} history={history}>
          <h2>{translatableName(recipe)}</h2>
          <h3>{I18n.t('views.cards.ingredients')}</h3>
          <RecipeIgnredients recipe_ingredients={recipe_ingredients}/>
          <h3>{I18n.t('views.cards.forms.recipe.description')}</h3>
          <div className="trix-content"
            dangerouslySetInnerHTML={ {__html: translatableAttributeWithFallback(recipe, 'description_f', 'description')} }
          />
          <h3>{I18n.t('views.cards.forms.recipe.instruction')}</h3>
          <div className="trix-content"
            dangerouslySetInnerHTML={ {__html: translatableAttributeWithFallback(recipe, 'instruction_f', 'instruction')} }
          />
          <h4>{I18n.t('views.people', {count: recipe.people})} {recipe.people}</h4>
          <RecipeCategories recipe={recipe}/>
        </CardWrapper>
    </Lifecycle>
  );
}

RecipesView.propTypes = {
  user: PropTypes.shape({
    admin: PropTypes.boolean
  }),
  ingredients: PropTypes.array,
  recipes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
      people: PropTypes.number,
      recipe_ingredients: PropTypes.array,
    })
  ),
  actions: PropTypes.shape({
    fetchRecipe: PropTypes.func
  }),
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(RecipesView);
