import React from 'react';
import PropTypes from 'prop-types';
import CardMenuControl from '../../components/CardMenuControl';

const I18n = window.I18n;

const setOnlyVible = className => {
  document.querySelectorAll('article').forEach( e => {
    if (!e.className.match(className)) {
      e.classList.toggle('hide')
    }
  });
}

export const Card = props => (
  <article className={props.className}>
    <div className='header'>
      <h1>{props.title}</h1>
    </div>
    {props.children}
  </article>
)


Card.propTypes = {
  title: PropTypes.string,
  children: PropTypes.object,
  className: PropTypes.string
}

export const DashboardSubHeader = props => (
  <div className='header'>
    <h3>{props.title}</h3>
    <div className='actions'>
      <select onChange={props.onChange} ref={props.selectRef} value={props.path}>
        <option key='sc' value='/schedule'>
          {I18n.t('views.reports.schedule')}
        </option>
        <option key='ad' value='/activeDiets'>
          {I18n.t('views.reports.active_diets')}
        </option>
        <option key='ai' value='/availableIngredients'>
          {I18n.t('views.reports.available_ingredients')}
        </option>
        <option key='ui' value='/availableRecipes'>
          {I18n.t('views.reports.available_recipes')}
        </option>
        <option key='ar' value='/unavailableIngredients'>
          {I18n.t('views.reports.unavailable_ingredients')}
        </option>
        <option key='scN' value='/scheduleMeals'>
          {I18n.t('views.reports.schedule_meals')}
        </option>
      </select>
    </div>
  </div>
)

DashboardSubHeader.propTypes = {
  title: PropTypes.string,
  onChange: PropTypes.func,
  selectRef: PropTypes.object,
  path: PropTypes.string
}

export const LimitButton = ({sortedList, limit, setLimit}) =>
  sortedList.length > 10 ?
    <button className='more' onClick={ () => setLimit(prevState => prevState === 10 ? 100 : 10) }>
      { limit === 10 ? I18n.t('views.generic.more') : I18n.t('views.generic.less') }
    </button> : null

LimitButton.propTypes = {
  sortedList: PropTypes.array,
  limit: PropTypes.number,
  setLimit: PropTypes.func
}

