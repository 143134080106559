import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { orderByName } from '../../shared/utils';

const I18n = window.I18n

const ScheduleMealItemForm = React.forwardRef(
  ({ meal_items, date, chef_guests, meal_periods, recipes }, ref ) => {
    const [mealsItems, setMealsItems] = useState(meal_items);
    const [newMealItem, setNewMealItem] = useState({});

    const [mealTypeRef, chefGuestRef, recipeRef] = [useRef(), useRef(), useRef()]

    const onNewMealTypeChange = e => {
      const value = e.currentTarget.value

      setNewMealItem( prev => ({...prev, meal_period: value}))
    }
    const onNewMealGuestChange = e => {
      const value = e.currentTarget.value

      setNewMealItem( prev => ({...prev, chef_guest_id: value}))
    }
    const onNewMealRecipeChange = e => {
      const value = e.currentTarget.value

      setNewMealItem( prev => ({...prev, recipe_id: value}))
    }

    const byMealType = (a, b) =>
      meal_periods.indexOf(a.meal_period) - meal_periods.indexOf(b.meal_period)

    useEffect( () => {
      setMealsItems([...meal_items].sort(byMealType))
    }, [meal_items.length])

    useEffect( () => {
      if (Object.keys(newMealItem).length > 2) {
        setMealsItems( prev => prev.reduce((previousValue, currentValue) => {
            const accum  = previousValue;
            const exist  = accum.filter( e => e.meal_period === currentValue.meal_period)
            const remain = accum.filter( e => e.meal_period !== currentValue.meal_period)

            let result   = []

            if ( exist.length > 0 ) {
              result = [
                ...remain,
                ...exist.map( e => ({
                  ...e,
                  ...currentValue,
                  meal_dishes: [
                    ...currentValue.meal_dishes,
                    ...e.meal_dishes
                  ]
                }) )
              ]
            } else {
              result = [currentValue, ...accum]
            }

            return [...result].sort(byMealType);
          },
          [{date: date, meal_period: newMealItem.meal_period, meal_dishes: [newMealItem] }])
        )
        setNewMealItem({})

        mealTypeRef.current.value = ''
        chefGuestRef.current.value = ''
        recipeRef.current.value = ''
      }
    }, [newMealItem])

    return(
      <React.Fragment>
        <ul className='header'>
          <li>{I18n.t('views.cards.dashboards.schedule_meals.meal_period')}</li>
          <li>{I18n.t('views.cards.dashboards.schedule_meals.guest')}</li>
          <li>{I18n.t('views.cards.dashboards.schedule_meals.recipe')}</li>
        </ul>
        {
          mealsItems.flatMap( (m, index) =>
            m.meal_dishes.map( (i) =>
              <ul ref={ref} key={`${m.id||'null'}_${i.id||'null'}_${i.chef_guest_id}_${i.recipe_id}_${index}`}>
                <li>
                  <input type="text" name='meals[id][]' defaultValue={m.id||'null'} hidden={true}/>
                  <input type="text" name='meals[date][]' defaultValue={m.date||date} hidden={true}/>
                  <select defaultValue={m.meal_period} name='meals[meal_period][]'>
                    <option key='null' value='' defaultValue=''></option>
                    {
                      meal_periods.map( type =>
                        <option key={`opt${type}`} value={type}>{ I18n.t(type, { scope: 'views.cards.forms.diet.meals.meal_periods'})}</option>
                      )
                    }
                  </select>
                </li>
                <li>
                  <input type="text" name='meals[dishes][id][]' defaultValue={i.id||'null'} hidden={true}/>
                  <select name='meals[dishes][chef_guest_id][]' defaultValue={i.chef_guest_id}>
                    <option key='null' value='' defaultValue=''></option>
                    {
                      chef_guests.map( (e,index) =>
                        <option key={index} value={e.id} selected={i.chef_guest_id === e.id}>{e.name}</option>
                      )
                    }
                  </select>
                </li>
                <li>
                  <select name='meals[dishes][recipe_id][]' defaultValue={i.recipe_id}>
                    <option key='null' value='' defaultValue=''></option>
                    {
                      recipes.map( (e,index) =>
                        <option key={index} value={e.id} selected={i.recipe_id === e.id}>{e.name}</option>
                      )
                    }
                  </select>
                </li>
              </ul>
            )
          )
        }

        <ul>
          <li>
            <select onChange={onNewMealTypeChange} ref={mealTypeRef}>
              <option key='null' value='' defaultValue=''></option>
              {
                meal_periods.map( type =>
                  <option key={`opt${type}`} value={type}>{ I18n.t(type, { scope: 'views.cards.forms.diet.meals.meal_periods'}) }</option>
                )
              }
            </select>
          </li>
          <li>
            <select onChange={onNewMealGuestChange} ref={chefGuestRef}>
              <option key='null' value='' defaultValue=''></option>
              {
                orderByName(chef_guests).map( (i,index) =>
                  <option key={index} value={i.id}>{i.name}</option>
                )
              }
            </select>
          </li>
          <li>
            <select onChange={onNewMealRecipeChange} ref={recipeRef}>
              <option key='null' value='' defaultValue=''></option>
              {
                orderByName(recipes).map( (i,index) =>
                  <option key={index} value={i.id}>{i.name}</option>
                )
              }
            </select>
          </li>
        </ul>
      </React.Fragment>
    )
  }
)

ScheduleMealItemForm.displayName = 'ScheduleMealItemForm'

ScheduleMealItemForm.propTypes = {
  date: PropTypes.string,
  meal_items: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      meal_period: PropTypes.string,
      meal_dishes: PropTypes.arrayOf(PropTypes.shape({
          id: PropTypes.number,
          chef_guest_id: PropTypes.number,
          recipe_id: PropTypes.number,
          recipe: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            people: PropTypes.number,
            description: PropTypes.string,
          })
        })
      )
    })
  ),
  meal_periods: PropTypes.array,
  chef_guests: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  recipes: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  )
}

export default ScheduleMealItemForm;


