import React from 'react';
import PropTypes from 'prop-types';

import { compose } from 'recompose';
import { connectModule } from 'redux-modules';

import loopProvider from '../../decorators/loopProvider';
import mealsModule from '../../redux/modules/meals';
import { ROOT_URL } from '../../shared/config'
import { queryParams } from '../../shared/utils'

const I18n = window.I18n

const CardMenuControl = ({user, match, history}) => {
  const root = ROOT_URL.length > 1 ? ROOT_URL : ''
  const { params: { id }, path } = match;

  const defaultValue = () => {
    switch(path) {
      case `${root}/recipes`:
        if (queryParams().group === 'category' ) {
          return "group-by-category"
        } else if (queryParams().order === 'name') {
          return "order-by-name"
        }
    }
  }

  let options = [
    <option key="null" value=""></option>,
  ]

  switch(path) {
    case `${root}/recipes`:
       options.push(<option key='by-category' value="group-by-category">{I18n.t('views.cards.actions.group_by_category')}</option>)
       options.push(<option key='by-name' value="order-by-name">{I18n.t('views.cards.actions.order_by_name')}</option>)

      if (user.admin) {
        options.push(<option key='export-csv' value="export-csv">{I18n.t('views.cards.actions.export_csv')}</option>)
        options.push(<option key='export-description-yml' value="export-description-yml">{I18n.t('views.cards.actions.export_description_yml')}</option>)
        options.push(<option key='export-instruction-yml' value="export-instruction-yml">{I18n.t('views.cards.actions.export_instruction_yml')}</option>)
      }
      break
    case `${root}/recipes/:id`:
      options.push(<option key='cook' value="cook">{I18n.t('views.cards.actions.cook')}</option>)
      options.push(<option key='print' value="print">{I18n.t('views.cards.actions.print')}</option>)
      options.push(<option key='copy-link' value="copy-link">{I18n.t('views.cards.actions.copy_link')}</option>)
      break;
    case `${root}/orders/:id`:
      options.push(<option key='copy-link' value="copy-link">{I18n.t('views.cards.actions.copy_link')}</option>)
      break;
    case `${root}/meals/:id`:
      options.push(<option key='copy-link' value="copy-link">{I18n.t('views.cards.actions.copy_link')}</option>)
      break;
    default:
      break;
  }

  const onChange = e => {
    const value = e.currentTarget.value;

    switch (value) {
      case 'print':
        switch (path) {
          case `${root}/recipes/:id`:
            window.open(`/food/recipes/${id}.pdf?locale=${I18n.locale}`);
            break;

          default:
            break;
        }
        break;
      case 'cook':
        switch (path) {
          case `${root}/recipes/:id`:
            history.push(`${root}/meals/new`, { recipe_id: id });
            break;

          default:
            break;
        }
        break;
      case 'group-by-category':
        switch (path) {
          case `${root}/recipes`:
            history.push(`${root}/recipes?group=category`);
            break
        }
        break
      case 'order-by-name':
        switch (path) {
          case `${root}/recipes`:
            history.push(`${root}/recipes?order=name`);
            break
        }
        break
      case 'export-csv':
        switch (path) {
          case `${root}/recipes`:
            window.open(`/food/recipes/export`);
            break
        }
        break
      case 'export-description-yml':
        switch (path) {
          case `${root}/recipes`:
            window.open(`/food/recipes/export?yml=description`);
            break
        }
        break
      case 'export-instruction-yml':
        switch (path) {
          case `${root}/recipes`:
            window.open(`/food/recipes/export?yml=instruction`);
            break
        }
        break
      case 'copy-link':
        navigator.clipboard.writeText(window.location.toLocaleString().replace(/(recipes|meals|orders)/, 'food/$1'))
        break
      case '':
        switch(path) {
          case `${root}/recipes`:
            history.push(`${root}/recipes`);
            break;
        }
        break;
      default:
        break;
    }
  };


  return(
    <select onChange={e => onChange(e)} defaultValue={defaultValue()}>
      {options}
    </select>
  )
}

CardMenuControl.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.any
    }),
    path: PropTypes.string
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string
    })
  }),
  recipes: PropTypes.array,
  user: PropTypes.shape({
    admin: PropTypes.boolean
  })
}


export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(CardMenuControl);
