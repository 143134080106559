import React from 'react';
import PropTypes from 'prop-types';
import { connectModule } from 'redux-modules';
import { Route } from 'react-router';
import { compose } from 'recompose';

import Lifecycle from '../../decorators/Lifecycle';
import loopProvider from '../../decorators/loopProvider';
import mealsModule from '../../redux/modules/meals';

import CardWrapper from '../../components/Navigation/CardWrapper';

import { BrowserRouter } from 'react-router-dom';
import { ROOT_URL } from '../../shared/config';

const I18n = window.I18n;


const Settings = ({ user, setRouteState, history, match }) => {
  const root = ROOT_URL.length > 1 ? ROOT_URL : '';

  const AdminSettings = ({user: admin, history}) =>
    admin ?
      <React.Fragment>
        <h3>{I18n.t('views.generic.admin')}</h3>
        <a onClick={ () => history.push(`${root}/ingredients`) } className='item'>{I18n.t('views.cards.ingredients')}</a>
        <a onClick={ () => history.push(`${root}/categories`) } className='item'>{I18n.t('views.cards.ingredients_categories')}</a>
        <a onClick={ () => history.push(`${root}/recipes_categories`) } className='item'>{I18n.t('views.cards.recipes_categories')}</a>
        <a onClick={ () => history.push(`${root}/shops`) } className='item'>{I18n.t('views.cards.shops')}</a>
      </React.Fragment>
        : null

  AdminSettings.propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func
    }),
    user: PropTypes.shape({
      admin: PropTypes.bool
    })
  }

  const SettingsList = ({user, history, match}) =>
    <CardWrapper title={I18n.t('views.cards.settings')} className={'ingredients'} match={match} history={history}>
      <a onClick={ () => history.push(`${root}/my/info`) } className='item'>{I18n.t('views.cards.member')}</a>
      <a onClick={ () => history.push(`${root}/my/chefs`) } className='item'>{I18n.t('views.cards.chefs')}</a>
      <a onClick={ () => history.push(`${root}/my/guests`) } className='item'>{I18n.t('views.cards.guests')}</a>
      <a onClick={ () => history.push(`${root}/my/contacts`) } className='item'>{I18n.t('views.cards.contacts')}</a>
      <a onClick={ () => history.push(`${root}/my/friend-requests`) } className='item'>{I18n.t('views.cards.friend_requests')}</a>
      <a onClick={ () => history.push(`${root}/my/notifications`) } className='item'>{I18n.t('views.cards.notifications')}</a>
      <AdminSettings user={user} history={history}/>
    </CardWrapper>

  SettingsList.propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func
    }),
    user: PropTypes.shape({
      admin: PropTypes.bool
    }),
    match: PropTypes.object,
  }

  return (
    <Lifecycle
      onMount={ () => {
          setRouteState()
        }
      }
    >
      <Route path={`${root}/settings`} exact render={ () =>
        <React.Fragment>
          <SettingsList
            user={user}
            history={history}
            match={match}
          />
        </React.Fragment>
      }/>
    </Lifecycle>
  )
};

Settings.propTypes = {
  setRouteState: PropTypes.func,
  actions: PropTypes.shape({
    fetchAI: PropTypes.func,
    openModal: PropTypes.func,
    updateFriendship: PropTypes.func
  }),
  ingredients: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      measure: PropTypes.string,
      name: PropTypes.string
    })
  ),
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  ),
  user: PropTypes.shape({
    chef_id: PropTypes.number,
    chefs: PropTypes.array,
    contacts: PropTypes.shape({
      friend_requests: PropTypes.shape({
        sent: PropTypes.arrayOf(PropTypes.shape({
          accepted: PropTypes.bool,
          created_at: PropTypes.string,
          status: PropTypes.string,
          user: PropTypes.object,
          friend: PropTypes.object,
        })),
        received: PropTypes.arrayOf(PropTypes.shape({
          accepted: PropTypes.bool,
          created_at: PropTypes.string,
          status: PropTypes.string,
          user: PropTypes.object,
          friend: PropTypes.object,
        }))
      })
    }),
    notifications: PropTypes.array
  }),
  match: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(Settings);
