import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connectModule } from 'redux-modules';
import loopProvider from '../../decorators/loopProvider';
import mealsModule from '../../redux/modules/meals';

import CardWrapper from '../../components/Navigation/CardWrapper';
import { IngredientForm } from '../../components/Ingredients';

import { parseDate } from '../../shared/utils';

const I18n = window.I18n;

const StockEdit = ({stocks, user, match, history}) => {
  const stockId = parseInt(match.params['id']);
  const stock = stocks.find( e => e.id === stockId )||{stock_items: []};
  const {expand} = (history.location.state||{expand: false});

  return (
    <CardWrapper title={I18n.t('views.cards.stock')} className={'stock'} match={match} history={history}>
      <IngredientForm
        itemName='stock'
        items={stock.stock_items}
        expand_form={expand}
      >
        <input type="text" name='stock[id]' defaultValue={stock.id} autoComplete="off" hidden={true}/>
        <input type="text" name='stock[chef_id]' defaultValue={stock.chef_id || user.chef_id} autoComplete="off" hidden={true}/>
        <div className='fieldset'>
          <label htmlFor="stock-date">{I18n.t('views.generic.date')}</label>
          <div className='stock-date-type'>
            <input
              type='date'
              name='stock[date]'
              autoComplete="off"
              defaultValue={
                parseDate(stock.date)
              }
            />
          </div>
        </div>
      </IngredientForm>
    </CardWrapper>
  );
};

StockEdit.propTypes = {
  date: PropTypes.string,
  stocks: PropTypes.array,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }),
  history: PropTypes.shape({
    location: PropTypes.shape({
      state: PropTypes.object
    })
  }),
  user: PropTypes.shape({
    chef_id: PropTypes.number
  })
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(StockEdit);
