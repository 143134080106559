import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router';

import { connectModule } from 'redux-modules';
import { compose } from 'recompose';

import loopProvider from '../../../decorators/loopProvider';
import mealsModule from '../../../redux/modules/meals';

import Lifecycle from '../../../decorators/Lifecycle';
import { ROOT_URL } from '../../../shared/config';

import RecipeCategoryList from './RecipeCategoryList';
import RecipeCategoryNew  from './RecipeCategoryNew';
import RecipeCategoryEdit from './RecipeCategoryEdit';

const RecipesCategories = ({ recipes_categories, recipes, user, actions, setRouteState }) => {
  const root = ROOT_URL.length > 1 ? ROOT_URL : '';

  return(
    <Lifecycle
      onMount={ () => {
          setRouteState()
        }
      }
    >
      <Switch>
        <Route path={`${root}/recipes_categories`} exact render={ routeProps =>
          <RecipeCategoryList
            recipes_categories={recipes_categories}
            actions={actions}
            {...routeProps}
          />
        }/>
        <Route path={`${root}/recipes_categories/new`} exact render={ routeProps =>
          <RecipeCategoryNew
            recipes={recipes}
            recipes_categories={recipes_categories}
            actions={actions}
            {...routeProps}
          />
        }/>
        <Route path={`${root}/recipes_categories/:id`} exact render={ routeProps =>
          <RecipeCategoryEdit {...routeProps} />
        }/>
      </Switch>
    </Lifecycle>
  )
}

RecipesCategories.propTypes = {
  setRouteState: PropTypes.func,
  actions: PropTypes.object,
  recipes_categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      recipes: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string
        })
      )
    })
  ),
  user: PropTypes.shape({
    chefs: PropTypes.array,
    contacts: PropTypes.shape({
      friend_requests: PropTypes.shape({
        sent: PropTypes.arrayOf(PropTypes.shape({
          accepted: PropTypes.bool,
          created_at: PropTypes.string,
          status: PropTypes.string,
          user: PropTypes.object,
          friend: PropTypes.object,
        })),
        received: PropTypes.arrayOf(PropTypes.shape({
          accepted: PropTypes.bool,
          created_at: PropTypes.string,
          status: PropTypes.string,
          user: PropTypes.object,
          friend: PropTypes.object,
        }))
      })
    })
  })
}


export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(RecipesCategories);
