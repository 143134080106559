import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'recompose';
import { connectModule } from 'redux-modules';
import loopProvider from '../../decorators/loopProvider';

import mealsModule from '../../redux/modules/meals';

import serialize from 'form-serialize';

import { Card } from '../../shared/components';
import { ROOT_URL } from '../../shared/config';

const I18n = window.I18n;

const NewChef = compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)( ({ user, actions }) => {
  const root = ROOT_URL.length > 1 ? ROOT_URL : '';

  const formRef = useRef();
  const nameRef = useRef();

  const createChef = () => {
    actions.createChef(serialize(formRef.current, {hash: true}))
      .then( () => window.location = root );
  }
  return(
    <main>
      <Card title={I18n.t('views.cards.new_chef')}>
        <React.Fragment>
          <form ref={formRef} action="" className='meal'>
            <input type="text" name='chef[user_id]' defaultValue={user.id} autoComplete="off" hidden={true}/>
            <div className='fieldset'>
              <label htmlFor="chef-name">{I18n.t('views.cards.forms.chef.name')}</label>
              <div className='chef-name-type'>
                <input
                  ref={nameRef}
                  type="text"
                  name='chef[name]'
                  autoComplete="off"
                />
              </div>
            </div>
          </form>
          <div className='control'>
            <button className='light-green' onClick={ () => createChef() }>Save</button>
          </div>
        </React.Fragment>
      </Card>
    </main>
  )
})

NewChef.propTypes = {
  actions: PropTypes.shape({
    createChef: PropTypes.func
  })
}

export default NewChef
