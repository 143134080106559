import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connectModule } from 'redux-modules';
import Lifecycle from '../../../decorators/Lifecycle';

import loopProvider from '../../../decorators/loopProvider';
import mealsModule from '../../../redux/modules/meals';

import CardWrapper from '../../../components/Navigation/CardWrapper';

const I18n = window.I18n;

const UserInfo = ({ user, actions, match, history}) => {
  const info    = (user.info || {})
  const locales = ['en', 'el']

  const formRef = useRef()

  useEffect( () => {
    actions.setUserInfoFormElement(formRef.current)
  }, [])

  return(
    <Lifecycle
      onMount={ () => {} }
    >
      <CardWrapper title={I18n.t('views.cards.settings')} className={'settings'} match={match} history={history}>
        <h2>{I18n.t('views.cards.member')}</h2>
        <form ref={formRef}>
          <div className='fieldset'>
            <label htmlFor='user-first-name'>{I18n.t('views.cards.forms.member.first_name')}</label>
            <input type='text' name='info[first_name]' defaultValue={info.first_name} autoComplete='false'/>
          </div>
          <div className='fieldset'>
            <label htmlFor='user-full-name'>{I18n.t('views.cards.forms.member.last_name')}</label>
            <input type='text' name='info[last_name]' defaultValue={info.last_name} autoComplete='false'/>
          </div>
          <div className='fieldset'>
            <label htmlFor='user-email'>{I18n.t('views.cards.forms.member.email')}</label>
            <input type='text' name='info[email]' defaultValue={info.email} autoComplete='false' disabled/>
          </div>
          <div className='fieldset'>
            <label htmlFor='user-locale'>{I18n.t('views.cards.forms.member.locale')}</label>
            <select name='info[locale]' defaultValue={info.locale}>
              { locales.map( e => <option key={e} value={e}>{I18n.t(e,{ scope: 'views.generic.locales' })}</option> ) }
            </select>
          </div>
          </form>
      </CardWrapper>
    </Lifecycle>
  )
}

UserInfo.propTypes = {
  actions: PropTypes.shape({
    setUserInfoFormElement: PropTypes.func
  }),
  user: PropTypes.shape({
    info: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      email: PropTypes.string,
      locale: PropTypes.string,
    })
  }),
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  match: PropTypes.object
}

UserInfo.defaultProps = {
  user: {}
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(UserInfo);
