import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router';

import { connectModule } from 'redux-modules';
import { compose } from 'recompose';

import loopProvider from '../../../decorators/loopProvider';
import mealsModule from '../../../redux/modules/meals';

import Lifecycle from '../../../decorators/Lifecycle';
import { ROOT_URL } from '../../../shared/config';

import ShopList from './ShopList';
import ShopEdit from './ShopEdit';
import ShopView from './ShopView';

const Shops = ({ shops, user, actions, setRouteState }) => {
  const root = ROOT_URL.length > 1 ? ROOT_URL : '';

  return(
    <Lifecycle
      onMount={ () => {
          setRouteState()
        }
      }
    >
      <Switch>
        <Route path={`${root}/shops`} exact render={ routeProps =>
          <ShopList
            shops={shops}
            actions={actions}
            {...routeProps}
          />
        }/>
        <Route path={`${root}/shops/edit/:id`} exact render={ routeProps =>
          <ShopEdit
            shops={shops}
            actions={actions}
            {...routeProps}
          />
        }/>
        <Route path={`${root}/shops/:id`} exact render={ routeProps =>
          <ShopView
            shops={shops}
            actions={actions}
            {...routeProps}
          />
        }/>
      </Switch>
    </Lifecycle>
  )
}

Shops.propTypes = {
  setRouteState: PropTypes.func,
  actions: PropTypes.object,
  shops: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      shop_roles: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string
        })
      )
    })
  ),
  user: PropTypes.shape({
    chefs: PropTypes.array,
    contacts: PropTypes.shape({
      friend_requests: PropTypes.shape({
        sent: PropTypes.arrayOf(PropTypes.shape({
          accepted: PropTypes.bool,
          created_at: PropTypes.string,
          status: PropTypes.string,
          user: PropTypes.object,
          friend: PropTypes.object,
        })),
        received: PropTypes.arrayOf(PropTypes.shape({
          accepted: PropTypes.bool,
          created_at: PropTypes.string,
          status: PropTypes.string,
          user: PropTypes.object,
          friend: PropTypes.object,
        }))
      })
    })
  })
}


export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(Shops);
