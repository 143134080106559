import React from 'react';
import PropTypes from 'prop-types';
import { postNotification } from '../../services/apiService'
import { formatNumber } from '../../shared/utils';

const IngredientItem = ({chef_id, id, name, quantity, measure_intl }) => {
  const submitNotification = _ => {
    postNotification({
      notification: { chef_id: chef_id, ingredient_id: id }
    })
  }

  return(
    <a className='item' onClick={submitNotification}>
      <span>{name}</span>
      <span>{`${formatNumber(quantity)} ${measure_intl}`}</span>
    </a>
  )
}

IngredientItem.propTypes = {
  chef_id: PropTypes.number,
  id: PropTypes.number,
  name: PropTypes.string,
  quantity: PropTypes.string,
  measure_intl: PropTypes.string,
}

export default IngredientItem
