import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Lifecycle from '../../../decorators/Lifecycle';

import CardWrapper from '../../../components/Navigation/CardWrapper';

const I18n = window.I18n;

const GuestEdit = ({ chef_guests, diets, actions, match, history}) => {
  const guest = chef_guests.find( e => e.id === parseInt(match.params.id) )||{};
  const chef_diet_guests = (guest.chef_diet_guests||[])
  const [items, setItems] = useState([]);

  const formRef = useRef()

  const DietItem = ({ item, index }) => {
    const dietRef = useRef();
    const dietSelectRef = useRef();

    const onDietChange = (e) => {
      const value = e.currentTarget.value;

      dietRef.current.value = value;

      setItems( prev => {
        let last = prev.pop()

        if (last) {
          if (value) {
            return([...prev, {...last, diet_id: parseInt(value)}])
          } else {
            return([...prev, {...last, diet_id: null}])
          }
        } else {
          return([{ diet_id: value }])
        }
      })
    }

    const onRemoveDiet = e => {
      e.preventDefault()

      setItems(prev => prev.filter( (guestDiet, i) => {
          if (item.id) {
            return item.id !== guestDiet.id
          } else {
            return index !== i
          }
        })
      )
    }

    return(
      <ul>
        <li>
          <input type='text' name='guest[diet_items][id][]' defaultValue={item.id} autoComplete="off" hidden/>
          <input type='text' name='guest[diet_items][diet_id][]' ref={dietRef} defaultValue={item.diet_id} autoComplete="off" hidden/>
          <select  onChange={onDietChange} ref={dietSelectRef} defaultValue={item.diet_id}>
            <option key='null' value='' defaultValue=''>{I18n.t('views.cards.actions.quick_select')}</option>
            { diets.map( diet => <option key={`diet_id_${diet.diet_id}`} value={diet.id}>{`${diet.name} - ${diet.period} - ${diet.season}`}</option> ) }
          </select>
        </li>
        <li>
          <input type='date' name='guest[diet_items][start][]' defaultValue={item.start} autoComplete="off"/>
        </li>
        <li>
          <input type='date' name='guest[diet_items][finish][]' defaultValue={item.finish} autoComplete="off"/>
        </li>
        <li>
          <input type='text' name='guest[diet_items][notes][]' defaultValue={item.notes} autoComplete="off"/>
        </li>
        <li className='action'>
          <button
            onClick={onRemoveDiet}
            className='material-icons'
          >
            cancel
          </button>
        </li>
      </ul>
    )
  }

  DietItem.propTypes = {
    item: PropTypes.shape({
      id: PropTypes.any,
      diet_id: PropTypes.number,
      start: PropTypes.string,
      finish: PropTypes.string,
      notes: PropTypes.string
    }),
    index: PropTypes.number
  }


  const onAddButtonClick = (e) => {
    e.preventDefault()

    setItems( prev => {
      if (prev.length === 0 || prev.slice(-1)[0].diet_id) {
        return [ ...prev, { id: null, diet_id: null, start: null, finish: null, notes: null}]
      } else {
        return prev
      }
    })
  }

  useEffect( () => {
    actions.setChefGuestFormElement(formRef.current)
  }, [])

  useEffect( () => {
    setItems(chef_diet_guests)
  }, [guest])

  return(
    <Lifecycle
      onMount={ () => {
        !guest.id && actions.fetchChefGuest({ id: parseInt(match.params.id) })
        actions.fetchDiets()
      }}
    >
      <CardWrapper title={I18n.t('views.cards.settings')} className={'settings'} match={match} history={history}>
        <h2>{I18n.t('views.cards.guests')}</h2>
        <form ref={formRef} action="" className='diets'>
          <input type='text' name='guest[id]' defaultValue={guest.id} autoComplete="off" hidden/>
          <div className='fieldset'>
            <label htmlFor='diet-name'>{I18n.t('views.cards.forms.guest.name')}</label>
            <input type='text' name='guest[name]' defaultValue={guest.name} autoComplete="off"/>
          </div>
          <ul className='header'>
            <li className='diet-guest-diet_name'>{I18n.t('views.cards.forms.guest.diet_name')}</li>
            <li className='diet-guest-start'>{I18n.t('views.cards.forms.guest.start')}</li>
            <li className='diet-guest-finish'>{I18n.t('views.cards.forms.guest.finish')}</li>
            <li className='diet-guest-notes'>{I18n.t('views.cards.forms.guest.notes')}</li>
            <li className='action'></li>
          </ul>
            { items.map( (item, index) => <DietItem key={`diet_${index}`} item={item} index={index}/> ) }
          <ul>
            <li className='diet-guest-diet_name'></li>
            <li className='diet-guest-start'></li>
            <li className='diet-guest-finish'></li>
            <li className='diet-guest-notes'></li>
            <li className='action'>
              <button
                  onClick={onAddButtonClick}
                  className='material-icons'
                >
                 add
               </button>
            </li>
          </ul>
        </form>
      </CardWrapper>
    </Lifecycle>
  )
}

GuestEdit.propTypes = {
  actions: PropTypes.shape({
    fetchChefGuest: PropTypes.func,
    fetchDiets: PropTypes.func,
    setChefGuestFormElement: PropTypes.func
  }),
  chef_guests: PropTypes.array,
  diets: PropTypes.array,
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  match: PropTypes.object
}


GuestEdit.defaultProps = {
  chef_guests: [],
  diets: []
}

export default GuestEdit
