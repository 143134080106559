import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Lifecycle from '../../../decorators/Lifecycle';

import CardWrapper from '../../../components/Navigation/CardWrapper';

const I18n = window.I18n;

const GuestView = ({ chef_guests, actions, match, history}) => {
  const guest = chef_guests.find( e => e.id === parseInt(match.params.id) )||{};
  const chef_diet_guests = (guest.chef_diet_guests||[])
  const [items, setItems] = useState([]);

  const DietItem = ({ item }) =>
      <ul>
        <li>
          {item.diet.name}
        </li>
        <li>
          {item.start}
        </li>
        <li>
          {item.finish}
        </li>
        <li>
          {item.notes}
        </li>
      </ul>

  DietItem.propTypes = {
    item: PropTypes.shape({
      start: PropTypes.string,
      finish: PropTypes.string,
      notes: PropTypes.string,
      diet: PropTypes.shape({
        name: PropTypes.string
      })
    })
  }

  useEffect( () => {
    setItems(chef_diet_guests)
  }, [guest])

  return(
    <Lifecycle
      onMount={ () => {
        !guest.id && actions.fetchChefGuest({ id: parseInt(match.params.id) })
      }}
    >
      <CardWrapper title={I18n.t('views.cards.settings')} className={'settings'} match={match} history={history}>
        <h2>{I18n.t('views.cards.guests')}</h2>
        <div className='fieldset'>
          <label htmlFor='diet-name'>{I18n.t('views.cards.forms.guest.name')}</label>
          <span>{guest.name}</span>
        </div>
        <ul className='header'>
          <li className='diet-guest-diet_name'>{I18n.t('views.cards.forms.guest.diet_name')}</li>
          <li className='diet-guest-start'>{I18n.t('views.cards.forms.guest.start')}</li>
          <li className='diet-guest-finish'>{I18n.t('views.cards.forms.guest.finish')}</li>
          <li className='diet-guest-notes'>{I18n.t('views.cards.forms.guest.notes')}</li>
        </ul>
        {
          items.map( (item, key) => <DietItem key={key} item={item}/>)
        }
      </CardWrapper>
    </Lifecycle>
  )
}

GuestView.propTypes = {
  actions: PropTypes.shape({
    fetchChefGuest: PropTypes.func,
    fetchChefGuests: PropTypes.func
  }),
  chef_guests: PropTypes.array,
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  match: PropTypes.object
}


GuestView.defaultProps = {
  chef_guests: []
}

export default GuestView
