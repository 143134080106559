import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import Lifecycle from '../../../decorators/Lifecycle';
import { ROOT_URL } from '../../../shared/config';

import CardWrapper from '../../../components/Navigation/CardWrapper';

const I18n = window.I18n;

const ChefView = ({ chefs, actions, match, history}) => {
  const root = ROOT_URL.length > 1 ? ROOT_URL : '';
  const chef = chefs.find( e => e.id === parseInt(match.params.id) )||{};

  return(
    <Lifecycle
      onMount={ () => actions.fetchChef({ id: match.params.id}) }
    >
      <CardWrapper title={I18n.t('views.cards.settings')} className={'settings'} match={match} history={history}>
        <h2>{I18n.t('views.cards.chef')}</h2>
        <div className='fieldset'>
          <label htmlFor='chef-name'>{I18n.t('views.cards.forms.chef.name')}</label>
          <span>{chef.name}</span>
        </div>
        <div className='fieldset'>
          <label htmlFor='chef-full-name'>{I18n.t('views.cards.forms.chef.full_name')}</label>
          <span>{chef.full_name}</span>
        </div>
        <div className='fieldset'>
          <label htmlFor='chef-phone'>{I18n.t('views.cards.forms.chef.phone')}</label>
          <span>{chef.phone}</span>
        </div>
        <div className='fieldset'>
          <label htmlFor='chef-address'>{I18n.t('views.cards.forms.chef.address')}</label>
          <span>{chef.address}</span>
        </div>
        <div className='fieldset'>
          <label htmlFor='chef-zip'>{I18n.t('views.cards.forms.chef.zip')}</label>
          <span>{chef.zip}</span>
        </div>
        <div className='fieldset'>
          <label htmlFor='chef-city'>{I18n.t('views.cards.forms.chef.city')}</label>
          <span>{chef.city}</span>
        </div>
        <div className='fieldset'>
          <label htmlFor='chef-country'>{I18n.t('views.cards.forms.chef.country')}</label>
          <span>GR</span>
        </div>
      </CardWrapper>
    </Lifecycle>
  )
}

ChefView.propTypes = {
  actions: PropTypes.shape({
    fetchChef: PropTypes.func
  }),
  chefs: PropTypes.array,
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  match: PropTypes.object
}

ChefView.defaultProps = {
  chefs: []
}

export default ChefView
