import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { connectModule } from 'redux-modules';
import { compose } from 'recompose';

import loopProvider from '../../../decorators/loopProvider';
import mealsModule from '../../../redux/modules/meals';

import Lifecycle from '../../../decorators/Lifecycle';
import CardWrapper from '../../../components/Navigation/CardWrapper';
import { ROOT_URL } from '../../../shared/config';

import { orderByTranslatableName, translatableName, translatableWithIdName } from '../../../shared/utils';

const I18n = window.I18n;

const CategoryEdit = ({ categories, ingredients, recipes, actions, match, history }) => {
  const root = ROOT_URL.length > 1 ? ROOT_URL : '';

  const category = categories.find( e => e.id === parseInt(match.params.id) ) || {
    parent: null,
    major: false,
    siblings: [],
    ingredients: [],
    effective_ingredients: [],
    effective_recipes: []
  };
  const getParent = item => item.parent ? [item.parent] : []

  const [categoryItem, setCategoryItem ] = useState(category);

  const [parentItems, setParentItems] = useState(getParent(categoryItem));
  const [siblingItems, setSiblingItems] = useState(categoryItem.siblings);

  const [ingredientItems, setIngredientItems] = useState(categoryItem.ingredients);

  const selectParentItems = categories.filter( e => parentItems.map(i => i.name).indexOf(e.name) === -1 && category.name !== e.name);
  const selectSiblingItems = categories.filter( e => siblingItems.map(i => i.name).indexOf(e.name) === -1 && category.name !== e.name);

  const selectIngredientItems = ingredients.filter( e => ingredientItems.map(i => i.name).indexOf(e.name) === -1 );

  const formRef = useRef();

  const parentRef = useRef();
  const siblingsRef = useRef();

  const ingredientRef = useRef();

  const onParentChange = e => {
    const newValue = e.currentTarget.value;
    let item = selectParentItems.find( e => e.name == newValue)

    if (parentItems.find( i => item.name === i.name )) return

    setParentItems(prev => [...prev, item])

    parentRef.current.value = '';
  }

  const onSiblingsChange = e => {
    const newValue = e.currentTarget.value;
    let item = selectSiblingItems.find( e => e.name == newValue)

    if (siblingItems.find( i => item.name === i.name )) return

    setSiblingItems(prev => [...prev, item])

    siblingsRef.current.value = '';
  }

  const onIngredientChange = e => {
    const newValue = e.currentTarget.value;
    let item = selectIngredientItems.find( e => e.name == newValue)

    if (ingredientItems.find( i => item.name === i.name )) return

    setIngredientItems(prev => [...prev, item])

    ingredientRef.current.value = '';
  }

  const removeParentItem = (e, index) => {
    e.preventDefault();

    setParentItems(prev => prev.filter((_,i) => i !== index))
  }
  const removeSiblingItem = (e, index) => {
    e.preventDefault();

    setSiblingItems(prev => prev.filter((_,i) => i !== index))
  }

  const removeIngredientItem = (e, index) => {
    e.preventDefault();

    setIngredientItems(prev => prev.filter((_,i) => i !== index))
  }

  useEffect( () => {
    actions.setCategoryFormElement(formRef.current)
  }, [])

  const inTranslation = category.locale !== I18n.locale

  useEffect( () => {
    setCategoryItem(category)
    setParentItems(getParent(category))
    setSiblingItems(category.siblings)
    setIngredientItems(category.ingredients)
  }, [categories.length])

  return(
    <Lifecycle
      onMount={()=> {
        if (categories.length === 0) {
          actions.fetchCategories()
            .then( () => actions.fetchCategory(match.params.id) )
        } else {
          actions.fetchCategory(match.params.id)
        }
      }}
    >
      <CardWrapper title={I18n.t('views.cards.settings')} className={'categories'} match={match} history={history}>
        <h2>{I18n.t('views.cards.ingredients_categories')}</h2>
        <form ref={formRef} action="">
          <input type="text" name='category[id]' defaultValue={category.id} autoComplete="off" hidden={true}/>
          <ul>
            <li>{I18n.t('views.generic.name')}</li>
            <li>
              {
                inTranslation ?
                  category.name :
                    <input type="text"
                      name="category[name]"
                      defaultValue={category.name}
                      autoComplete="off"
                    />
              }
            </li>
          </ul>
          <ul>
            <li>{I18n.t('views.cards.forms.categories.major')}</li>
            <li>
              {
                <input type="checkbox"
                  name="category[major]"
                  defaultValue={Boolean(categoryItem.major).toString()}
                  checked={Boolean(categoryItem.major).toString() === 'true'}
                  onClick={ () => {
                    setCategoryItem( prev => ({...prev, major: !prev.major }) )
                  }}
                  autoComplete="off"
                />
              }
            </li>
          </ul>
          {
            inTranslation ?
              <ul>
                <li></li>
                <li>
                  <input type="text"
                    name="category[name]"
                    defaultValue={translatableName(category)}
                    autoComplete="off"
                  />
                </li>
              </ul> :
              null
          }
          <ul>
            <li>{I18n.t('views.cards.forms.categories.parent')}</li>
            <li>
              <select onChange={ e => onParentChange(e) } ref={parentRef} disabled={parentItems.length === 1}>
                <option key='null' value='' defaultValue=''>{I18n.t('views.cards.actions.quick_select')}</option>
                {
                  orderByTranslatableName(selectParentItems).map( e =>
                    <option key={`opt${e.id}`} value={e.name}>{translatableName(e)}</option>
                  )
                }
              </select>
            </li>
          </ul>
          {
            parentItems.map( (i,index) =>
              <ul key={`${i.id}_${index}`}>
                <li></li>
                <li>
                  <span onClick={() => history.push(`${root}/categories/${i.id}`)}>{translatableName(i)}</span>
                  <button onClick={ e => removeParentItem(e,index) }
                    className='material-icons'
                  >
                    cancel
                  </button>
                </li>
                <input type="text" name={'category[parent_id]'} defaultValue={i.id} autoComplete="off" hidden={true}/>
              </ul>
            )
          }
          <ul>
            <li>{I18n.t('views.cards.forms.categories.siblings')}</li>
            <li>
              <select onChange={ e => onSiblingsChange(e) } ref={siblingsRef}>
                <option key='null' value='' defaultValue=''>{I18n.t('views.cards.actions.quick_select')}</option>
                {
                  orderByTranslatableName(selectSiblingItems).map( e =>
                    <option key={`opt${e.id}`} value={e.name}>{translatableName(e)}</option>
                  )
                }
              </select>
            </li>
          </ul>
          {
            siblingItems.map( (i,index) =>
              <ul key={`${i.id}_${index}`}>
                <li></li>
                <li>
                  <span onClick={() => history.push(`${root}/categories/${i.id}`)}>{translatableName(i)}</span>
                  <button onClick={ e => removeSiblingItem(e,index) }
                    className='material-icons'
                  >
                    cancel
                  </button>
                </li>
                <input type="text" name={'category[siblings][id][]'} defaultValue={i.id} autoComplete="off" hidden={true}/>
              </ul>
            )
          }
          <ul>
            <li>{I18n.t('views.cards.ingredients')}</li>
            <li>
              <select onChange={onIngredientChange} ref={ingredientRef}>
                <option key='null' value='' defaultValue=''>{I18n.t('views.cards.actions.quick_select')}</option>
                {
                  orderByTranslatableName(selectIngredientItems).map( e =>
                    <option key={`opt${e.id}`} value={e.name}>{translatableName(e)}</option>
                  )
                }
              </select>
            </li>
          </ul>
          {
            ingredientItems.map( (i,index) =>
              <ul key={`${i.id}_${index}`}>
                <li></li>
                <li>
                  <span onClick={ () => console.log(i) }>{translatableName(i)}</span>
                  <button onClick={ e => removeIngredientItem(e,index) }
                    className='material-icons'
                  >
                    cancel
                  </button>
                </li>
                <input type="text" name={'category[ingredients][id][]'} defaultValue={i.id} autoComplete="off" hidden={true}/>
              </ul>
            )
          }
          <h3>{ category.effective_ingredients ? I18n.t('views.cards.forms.categories.effective_ingredients') : ''}</h3>
          {
            (category.effective_ingredients||[]).map( (i,index) =>
              <ul key={`${i.id}_${index}`}>
                <li>
                  <a onClick={ () => history.push(`${root}/ingredients/${i.id}`)} className='item'>{translatableWithIdName(i, ingredients)}</a>
                </li>
              </ul>
            )
          }
          <h3>{ category.effective_recipes ? I18n.t('views.cards.forms.categories.effective_recipes') : ''}</h3>
          {
            (category.effective_recipes||[]).map( (i,index) =>
              <ul key={`${i.id}_${index}`}>
                <li>
                  <a  onClick={() => history.push(`${root}/recipes/${i.id}`)} className='item'>{translatableWithIdName(i, recipes)}</a>
                </li>
              </ul>
            )
          }
        </form>
      </CardWrapper>
    </Lifecycle>
  )
}

CategoryEdit.propTypes = {
  actions: PropTypes.shape({
    fetchCategories: PropTypes.func,
    fetchCategory: PropTypes.func,
    fetchIngredients: PropTypes.func,
    setCategoryFormElement: PropTypes.func
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.object
  }),
  ingredients: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      measure: PropTypes.string,
      name: PropTypes.string
    })
  ),
  recipes: PropTypes.array,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      ingredients: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string
        })
      ),
      effective_ingredients: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string
        })
      )
    })
  )
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(CategoryEdit);
