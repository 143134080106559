import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'recompose';
import { connectModule } from 'redux-modules';

import Lifecycle from '../../decorators/Lifecycle';

import loopProvider from '../../decorators/loopProvider';
import mealsModule from '../../redux/modules/meals';


const I18n = window.I18n;

const PublicIndex = ({ setRouteState, images }) => {
  const image2 = images.find( e => e.name == 'brand_two' )
  const image3 = images.find( e => e.name == 'brand_three' )
  const image4 = images.find( e => e.name == 'brand_four' )

  const brand1 = useRef()
  const brand2 = useRef()
  const brand3 = useRef()
  const brand4 = useRef()

  const setWigth = (brand) => {
    if (window.innerWidth < 700) {
      brand.current.style.backgroundSize = '100vw'
      brand.current.style.backgroundPosition = 'bottom 0px right 0vw'
    } else if(window.innerWidth < 1000) {
      brand.current.style.backgroundSize = '80vw'
      brand.current.style.backgroundPosition = 'bottom 0px right 5vw'
    } else {
      brand.current.style.backgroundSize = '50vw'
      brand.current.style.backgroundPosition = 'bottom 0px right 20vw'
    }
  }
  const setImage = (brand, image) => {
    brand.current.style.backgroundImage = `url('${image.url}')`
    brand.current.style.backgroundSize = '100vw'
    brand.current.style.backgroundRepeat = 'no-repeat'
  }

  useEffect(() => {
    setImage(brand2, image2)
    setWigth(brand2)
    setImage(brand3, image3)
    setWigth(brand3)
    setImage(brand4, image4)
    setWigth(brand4)

    window.addEventListener('resize', (e) => {
      setWigth(brand2)
      setWigth(brand3)
      setWigth(brand4)
    })

  }, [])


  return (
    <Lifecycle
      onMount={ () => {
        setRouteState()
      }
    }>
      <section>
        <div className='masthead'>
          <h1 className='greet'>{I18n.t('views.landing.greet')}</h1>
        </div>
        <div className='masthead2' ref={brand1}>
          <h1 className='greet'>{I18n.t('views.landing.brand_one')}</h1>
          <h2 className='greet'>{I18n.t('views.landing.brand_one_details')}</h2>
        </div>
        <div className='masthead2' ref={brand2}>
          <h1 className='greet'>{I18n.t('views.landing.brand_two')}</h1>
          <h2 className='greet'>{I18n.t('views.landing.brand_two_details')}</h2>
        </div>
        <div className='masthead2' ref={brand3}>
          <h1 className='greet'>{I18n.t('views.landing.brand_three')}</h1>
          <h2 className='greet'>{I18n.t('views.landing.brand_three_details')}</h2>
        </div>
        <div className='masthead2' ref={brand4}>
          <h1 className='greet'>{I18n.t('views.landing.brand_four')}</h1>
          <h2 className='greet'>{I18n.t('views.landing.brand_four_details')}</h2>
        </div>
      </section>
    </Lifecycle>
  )
}

PublicIndex.propTypes = {
  setRouteState: PropTypes.func
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(PublicIndex);
