import React from 'react';
import PropTypes from 'prop-types';

import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ROOT_URL } from '../../shared/config';

import PublicIndex     from './PublicIndex';
import SignUp          from './SignUp';
import Login           from './Login';
import ForgotPassword  from './ForgotPassword';
import ResetPassword   from './ResetPassword';


const Public = ({ urls, images, setRouteState }) => {
  const root = ROOT_URL.length > 1 ? ROOT_URL : '';

  return(
    <main>
      <Router
        initialEntries={[`${root}/`]}
        initialIndex={0}
      >
        <Route path={`${root}/`} exact render={  routerProps =>
          <PublicIndex {...routerProps} images={images} setRouteState={ () => setRouteState(routerProps)}/>
        }/>
        <Route path={`${root}/signup`} render={  routerProps =>
          <SignUp {...routerProps} urls={urls} setRouteState={ () => setRouteState(routerProps)} />
        }/>
        <Route path={`${root}/login`} render={  routerProps =>
          <Login {...routerProps} urls={urls} setRouteState={ () => setRouteState(routerProps)} />
        }/>
        <Route path={`${root}/resend`} render={  routerProps =>
          <ForgotPassword {...routerProps} urls={urls} setRouteState={ () => setRouteState(routerProps)} />
        }/>
        <Route path={`${root}/reset`} render={  routerProps =>
          <ResetPassword {...routerProps} urls={urls} setRouteState={ () => setRouteState(routerProps)} />
        }/>
      </Router>
    </main>
  )
}

Public.propTypes = {
  urls: PropTypes.object,
  setRouteState: PropTypes.func,
}

export default Public;
