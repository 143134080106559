import React from "react"
import PropTypes from 'prop-types';


import {
  translatableName,
  orderByTranslatableName,
} from '../../shared/utils';

const I18n = window.I18n;

const IngredientSelect = ({ selectItems, ingredientRef, onIngredientChange, isDisabled = () => false }) => {
  return(
    <select onChange={onIngredientChange} ref={ingredientRef} disabled={isDisabled()}>
      <option key='null' value='' defaultValue=''>{I18n.t('views.cards.actions.quick_select')}</option>
      { orderByTranslatableName(selectItems).map( e => <option key={`opt${e.id || e.ingredient_id}`} value={e.name}>{translatableName(e)}</option> ) }
    </select>
  )
}

IngredientSelect.propTypes = {
  selectItems: PropTypes.array,
  ingredientRef: PropTypes.object,
  onIngredientChange: PropTypes.func,
  isDisabled: PropTypes.func,
}

export default IngredientSelect;

