import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { connectModule } from 'redux-modules';
import { compose } from 'recompose';

import loopProvider from '../../../decorators/loopProvider';
import mealsModule from '../../../redux/modules/meals';

import Lifecycle from '../../../decorators/Lifecycle';
import CardWrapper from '../../../components/Navigation/CardWrapper';
import { ROOT_URL } from '../../../shared/config';

import { orderByTranslatableName, translatableName, translatableWithIdName } from '../../../shared/utils';

const I18n = window.I18n;

const RecipeCategoryEdit = ({ recipes, recipes_categories, match, actions, history }) => {
  const root = ROOT_URL.length > 1 ? ROOT_URL : '';
  const recipes_category = recipes_categories.find( e => e.id === parseInt(match.params.id) ) || {
    effective_recipes: []
  };

  const [recipesCategoryItem, setRecipesCategoryItem ] = useState(recipes_category);
  const [recipesCategoryItems, setRecipesCategoryItems] = useState(recipes_category.effective_recipes||[]);
  const [selectRecipeItems, setSelectRecipeItems] = useState(recipes||[])

  const formRef   = useRef();
  const recipeRef = useRef();

  const onRecipeChange = e => {
    const newValue = e.currentTarget.value;
    let item = selectRecipeItems.find( e => e.id == newValue)

    if (recipesCategoryItems.find( i => item.id === i.id )) return

    setRecipesCategoryItems(prev => [...prev, item])
    setSelectRecipeItems(prev => prev.filter(e => e.id !== item.id))

    recipeRef.current.value = '';
  }

  const removeCategoryItem = (e, index) => {
    e.preventDefault();

    setRecipesCategoryItems(prev => prev.filter((_,i) => i !== index))
  }

  useEffect( () => {
    actions.setRecipesCategoryFormElement(formRef.current)
  }, [])

  useEffect( () => {
    setRecipesCategoryItem(recipes_category)
    setRecipesCategoryItems(recipes_category.effective_recipes||[])
  }, [recipes_categories.length])

  useEffect( () => {
    setSelectRecipeItems(recipes.filter( e => (recipes_category.effective_recipes||[]).map( i => i.id).indexOf(e.id) === -1 ))
  }, [recipes.length])

  const inTranslation = recipesCategoryItem.locale !== I18n.locale

  return(
    <Lifecycle
      onMount={()=> {
        if (recipes_categories.length === 0) {
          actions.fetchRecipesCategories()
            .then( () => actions.fetchRecipesCategory({id :match.params.id}) )
        } else {
          actions.fetchRecipesCategory({id: match.params.id})
        }

        if (recipes.length === 0) {
          actions.fetchRecipes()
        }
      }}
    >
      <CardWrapper title={I18n.t('views.cards.settings')} className={'categories'} match={match} history={history}>
        <h2>{I18n.t('views.cards.recipes_categories')}</h2>
        <form ref={formRef} action="">
          <input type="text" name='recipes_category[id]' defaultValue={recipes_category.id} autoComplete="off" hidden={true}/>
          <ul>
            <li>{I18n.t('views.generic.name')}</li>
            <li>
              {
                inTranslation ?
                  recipes_category.name :
                    <input type="text"
                      name="recipes_category[name]"
                      defaultValue={recipes_category.name}
                      autoComplete="off"
                    />
              }
            </li>
          </ul>
          <ul>
            <li>{I18n.t('views.cards.forms.recipes_categories.major')}</li>
            <li>
              {
                <input type="checkbox"
                  name="category[major]"
                  defaultValue={Boolean(recipesCategoryItem.major).toString()}
                  checked={Boolean(recipesCategoryItem.major).toString() === 'true'}
                  onClick={ () => {
                    setRecipesCategoryItem( prev => ({...prev, major: !prev.major }) )
                  }}
                  autoComplete="off"
                />
              }
            </li>
          </ul>
          {
            inTranslation ?
              <ul>
                <li></li>
                <li>
                  <input type="text"
                    name="recipes_category[name]"
                    defaultValue={translatableName(recipes_category)}
                    autoComplete="off"
                  />
                </li>
              </ul> :
              null
          }
          <ul>
            <li>{I18n.t('views.cards.recipes')}</li>
            <li>
              <select onChange={onRecipeChange} ref={recipeRef}>
                <option key='null' value='' defaultValue=''>{I18n.t('views.cards.actions.quick_select')}</option>
                {
                  orderByTranslatableName(selectRecipeItems).map( e =>
                    <option key={`opt${e.id}`} value={e.id}>{translatableName(e)}</option>
                  )
                }
              </select>
            </li>
          </ul>
          {
            (recipesCategoryItems||[]).map( (i,index) =>
              <ul key={`${i.id}_${index}`}>
                <li></li>
                <li>
                  <a onClick={ () => history.push(`${root}/recipes/${i.id}`) } className='item eighty'>
                    {translatableName(i)}
                  </a>
                  <button onClick={ e => removeCategoryItem(e,index) }
                    className='material-icons twenty'
                  >
                    cancel
                  </button>
                </li>
                <input type="text" name={'recipes_category[recipes][id][]'} defaultValue={i.id} autoComplete="off" hidden={true}/>
              </ul>
            )
          }
        </form>
      </CardWrapper>
    </Lifecycle>
  )
}

RecipeCategoryEdit.propTypes = {
  actions: PropTypes.shape({
    fetchRecipesCategories: PropTypes.func,
    fetchRecipesCategory: PropTypes.func,
    setRecipesCategoryFormElement: PropTypes.func,
    fetchRecipes: PropTypes.func
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.object
  }),
  ingredients: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      measure: PropTypes.string,
      name: PropTypes.string
    })
  ),
  recipes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      effective_recipes: PropTypes.object
    })
  ),
  recipes_categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      recipes: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string
        })
      )
    })
  )
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(RecipeCategoryEdit);
