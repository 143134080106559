import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Lifecycle from '../../decorators/Lifecycle';

import { DietItem } from '../../components/Diets';
import { DashboardSubHeader } from '../../shared/components';
import { formatDateLong } from '../../shared/utils';

const I18n = window.I18n;

const ActiveDiets = ({
    fetchAD, fetchCategories, fetchRecipesCategories, fetchChefGuests,
    chef, reports, match: { path }, history
  }) => {
    const diets = chef.ad ? chef.ad : [];
    const { active_diets: { days, offset } } = reports || { active_diets: { days: [], offset: 0 } };
    const selectRef = useRef();
    const onChange = e => {
      history.push(e.currentTarget.value)
    }

    const DietDay = ({ index, day, offset }) => {
      const target    = index + offset
      const period    = target > 6 ? target - 7 : target
      const dietItems = diets.filter( i => i.period === parseInt(period) )

      return(
        <React.Fragment>
          <h3>{formatDateLong(day)}</h3>
          {
            dietItems.map( item =>
              <DietItem
                key={`item_${item.id}`}
                item={item}
                date={day}
              />
            )
          }
        </React.Fragment>
      )
    }

    DietDay.propTypes = {
      index: PropTypes.number,
      day: PropTypes.string,
      offset: PropTypes.number
    }

    return(
      <Lifecycle
        onMount={ () => {
          fetchAD()
          fetchCategories()
          fetchRecipesCategories()
          fetchChefGuests()
        }}
      >
        <DashboardSubHeader
          title={I18n.t('views.reports.active_diets')}
          onChange={onChange}
          selectRef={selectRef}
          path={path}
        />
        {
          days.map((day, index) =>
            <DietDay key={day} day={day} index={index} offset={offset}/>
          )
        }
      </Lifecycle>
    )
  }

ActiveDiets.propTypes = {
  fetchAD: PropTypes.func,
  fetchCategories: PropTypes.func,
  fetchRecipesCategories: PropTypes.func,
  fetchChefGuests: PropTypes.func,
  chef: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    ai: PropTypes.any,
    ad: PropTypes.array
  }),
  reports: PropTypes.shape({
    active_diets: PropTypes.object
  }),
  match: PropTypes.shape({
    path: PropTypes.string
  }),
  history: PropTypes.shape({
    push: PropTypes.func
  })
}

export default ActiveDiets;
