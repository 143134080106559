import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { connectModule } from 'redux-modules';
import { compose } from 'recompose';

import loopProvider from '../../../decorators/loopProvider';
import mealsModule from '../../../redux/modules/meals';

import Lifecycle from '../../../decorators/Lifecycle';

import CardWrapper from '../../../components/Navigation/CardWrapper';
import { ROOT_URL } from '../../../shared/config';

import { translatableName, translatableWithIdName } from '../../../shared/utils';

const I18n = window.I18n;


const IngredientEdit= ({ ingredients, categories, recipes, match, actions, history }) => {
  const root = ROOT_URL.length > 1 ? ROOT_URL : '';

  const ingredient = ingredients.find( e => e.id === parseInt(match.params.id) ) || { categories: [], category_ids: [] };

  const getParent     = item => item.categories.filter( e => !e.parent_id )
  const getSibling    = item => item.categories.filter( e => e.parent_id && !item.category_ids.includes(e.id) )
  const getCategories = item => item.category_ids.map( id => item.categories.find( c => c.id === id) )

  const [ingredientItem, setIngredientItem] = useState(ingredient);

  const [categoryItems, setCategoryItems] = useState(getCategories(ingredientItem))
  const [siblingItems, setSiblingItems] = useState(getSibling(ingredientItem));
  const [parentItems, setParentItems] = useState(getParent(ingredientItem));

  const selectCategoryItems = categories.filter( e => siblingItems.map(i => i.name).indexOf(e.name) === -1 );

  const formRef = useRef();
  const categoriesRef = useRef();
  const ingrMeasureRef = useRef();
  const measureRef = useRef();

  const onCategoriesChange = e => {
    const newValue = e.currentTarget.value;
    let item = selectCategoryItems.find( e => e.name == newValue)

    if (categoryItems.find( i => item.name === i.name )) return

    setCategoryItems(prev => [...prev, item])

    categoriesRef.current.value = '';
  }

  const removeCategoryItem = (e, index) => {
    e.preventDefault();

    setCategoryItems(prev => prev.filter((_,i) => i !== index))
  }

  const onMeasureChange = (e) => {
    const newValue = e.currentTarget.value;

    ingrMeasureRef.current.value = newValue
  }

  const renderMeasureQuantityOptions = () => {
    const quantity = (I18n.translations && I18n.translations.en && I18n.translations.en.quantity) || [];
    return Object.keys(quantity).filter( e => e !== 'cook' ).map( (type, i) => {
      return(
        <optgroup label={I18n.t(type, {scope: 'views.quantity'})} key={i}>
          { Object.keys(quantity[type]).map( e => {
            const value = `${type}.${e}`
            return (
              <option
                key={`opt${e}`}
                value={value}
                selected={ingredientItem.measure === value}
              >
                {I18n.t(value, {scope: 'quantity'})}
              </option>
            )
          })}
        </optgroup>
      )
    })
  }

  useEffect( () => {
    actions.setIngredientFormElement({ingredient: formRef.current})
  }, [])

  useEffect( () => {
    setIngredientItem(ingredient)
    setCategoryItems(getCategories(ingredient))
    setSiblingItems(getSibling(ingredient))
    setParentItems(getParent(ingredient))
  }, [ingredients.length])

  const inTranslation = ingredient.locale !== I18n.locale

  return(
    <Lifecycle
      onMount={()=> {
        if (ingredients.length === 0) {
          actions.fetchIngredients()
            .then( () => actions.fetchIngredient(match.params.id)  )
            .then( () => actions.fetchCategories()  )
        } else {
          actions.fetchIngredient(match.params.id)
        }
      }}
    >
      <CardWrapper title={I18n.t('views.cards.settings')} className={'ingredients'} match={match} history={history}>
        <h2>{I18n.t('views.cards.ingredients')}</h2>
        <form ref={formRef} action="">
          <input type="text" name='ingredient[id]' defaultValue={ingredient.id} autoComplete="off" hidden={true}/>
          <ul>
            <li>{I18n.t('views.generic.name')}</li>
            <li>
              {
                inTranslation ?
                  ingredient.name :
                    <input type="text"
                      name="ingredient[name]"
                      defaultValue={ingredient.name}
                      autoComplete="off"
                    />
              }
            </li>
          </ul>
          {
            inTranslation ?
              <ul>
                <li></li>
                <li>
                  <input type="text"
                    name="ingredient[name]"
                    defaultValue={translatableName(ingredient)}
                    autoComplete="off"
                  />
                </li>
              </ul> :
              null
          }
          {
            parentItems.map( (i,index) =>
              <ul key={`${i.id}_${index}`}>
                <li>{ index === 0 ? I18n.t('views.cards.forms.categories.parent') : ''}</li>
                <li>
                  <a onClick={() => history.push(`${root}/categories/${i.id}`) } className='item eighty'>{translatableWithIdName(i, categories)}</a>
                </li>
              </ul>
            )
          }
          {
            siblingItems.map( (i,index) =>
              <ul key={`${i.id}_${index}`}>
                <li>{index === 0 ? I18n.t('views.cards.forms.categories.siblings') : ''}</li>
                <li>
                  <a onClick={() => history.push(`${root}/categories/${i.id}`)} className='item eighty'>{translatableWithIdName(i, categories)}</a>
                </li>
              </ul>
            )
          }
          <ul>
            <li>{I18n.t('views.cards.ingredients_categories')}</li>
            <li>
              <select onChange={ onCategoriesChange } ref={categoriesRef}>
                <option key='null' value='' defaultValue=''>{I18n.t('views.cards.actions.quick_select')}</option>
                { selectCategoryItems.map( e => <option key={`opt${e.id}`} value={e.name}>{e.name}</option> ) }
              </select>
            </li>
          </ul>
          {
            categoryItems.map( (i,index) =>
              <ul key={`${i.id}_${index}`}>
                <li></li>
                <li>
                  <a onClick={() => history.push(`${root}/categories/${i.id}`)} className='item eighty'>{translatableWithIdName(i, categories)}</a>
                  <button onClick={ e => removeCategoryItem(e,index) }
                    className='material-icons'
                  >
                    cancel
                  </button>
                  <input type="text" name={'ingredient[category_ids][]'} defaultValue={i.id} autoComplete="off" hidden={true}/>
                </li>
              </ul>
            )
          }
          <ul>
            <li>{I18n.t('views.cards.forms.ingredient.unit')}</li>
            <li>
              <input type='text' ref={ingrMeasureRef} name='ingredient[measure]' defaultValue={ingredientItem.measure} autoComplete="off" hidden={true}/>
              <select onChange={onMeasureChange} ref={measureRef}>
                { renderMeasureQuantityOptions() }
              </select>
            </li>
          </ul>
          <h3>{ ingredient.effective_ingredients ? I18n.t('views.cards.forms.categories.effective_ingredients') : ''}</h3>
          {
            (ingredient.effective_ingredients||[]).map( (i,index) =>
              <ul key={`${i.id}_${index}`}>
                <li>
                  <a onClick={ () => history.push(`${root}/ingredients/${i.id}`) } className='item'>{translatableWithIdName(i, ingredients)}</a>
                </li>
              </ul>
            )
          }
          <h3>{ ingredient.effective_recipes ? I18n.t('views.cards.forms.categories.effective_recipes') : ''}</h3>
          {
            (ingredient.effective_recipes||[]).map( (i,index) =>
              <ul key={`${i.id}_${index}`}>
                <li>
                  <a onClick={ () => history.push(`${root}/recipes/${i.id}`) } className='item'>{translatableWithIdName(i, recipes)}</a>
                </li>
              </ul>
            )
          }
        </form>
      </CardWrapper>
    </Lifecycle>
  )
}

IngredientEdit.propTypes = {
  actions: PropTypes.shape({
    setIngredientFormElement: PropTypes.func,
    fetchIngredient: PropTypes.func,
    fetchIngredients: PropTypes.func,
    fetchCategories: PropTypes.func,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }),
  history: PropTypes.object,
  ingredients: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      measure: PropTypes.string,
      name: PropTypes.string
    })
  ),
  recipes: PropTypes.array,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      ingredients: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string
        })
      )
    })
  )
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(IngredientEdit);
