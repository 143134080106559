import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import Lifecycle from '../../../decorators/Lifecycle';

import CardWrapper from '../../../components/Navigation/CardWrapper';

const I18n = window.I18n;

const ChefEdit = ({ chefs, actions, match, history}) => {
  const chef = chefs.find( e => e.id === parseInt(match.params.id) )||{};

  const formRef = useRef();

  useEffect( () => {
    actions.setChefFormElement(formRef.current)
  }, [])

  return(
    <Lifecycle
      onMount={ () => {} }
    >
      <CardWrapper title={I18n.t('views.cards.settings')} className={'settings'} match={match} history={history}>
        <h2>{I18n.t('views.cards.chefs')}</h2>
        <form ref={formRef}>
          <div className='fieldset'>
            <label htmlFor='diet-name'>{I18n.t('views.cards.forms.chef.name')}</label>
            <input type='text' name='chef[id]' defaultValue={chef.id} autoComplete="off" hidden/>
            <input type='text' name='chef[name]' defaultValue={chef.name} autoComplete="off"/>
          </div>
          <div className='fieldset'>
            <label htmlFor='chef-full-name'>{I18n.t('views.cards.forms.chef.full_name')}</label>
            <input type='text' name='chef[full_name]' defaultValue={chef.full_name}/>
          </div>
          <div className='fieldset'>
            <label htmlFor='chef-phone'>{I18n.t('views.cards.forms.chef.phone')}</label>
            <input type='text' name='chef[phone]' defaultValue={chef.phone}/>
          </div>
          <div className='fieldset'>
            <label htmlFor='chef-address'>{I18n.t('views.cards.forms.chef.address')}</label>
            <input type='text' name='chef[address]' defaultValue={chef.address}/>
          </div>
          <div className='fieldset'>
            <label htmlFor='chef-zip'>{I18n.t('views.cards.forms.chef.zip')}</label>
            <input type='text' name='chef[zip]' defaultValue={chef.zip}/>
          </div>
          <div className='fieldset'>
            <label htmlFor='chef-city'>{I18n.t('views.cards.forms.chef.city')}</label>
            <input type='text' name='chef[city]' defaultValue={chef.city}/>
          </div>
          <div className='fieldset'>
            <label htmlFor='chef-country'>{I18n.t('views.cards.forms.chef.country')}</label>
            <input type='text' name='chef[country]' defaultValue={'GR'}/>
          </div>
        </form>
      </CardWrapper>
    </Lifecycle>
  )
}

ChefEdit.propTypes = {
  actions: PropTypes.shape({
    setChefFormElement: PropTypes.func
  }),
  chefs: PropTypes.array,
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  match: PropTypes.object
}


ChefEdit.defaultProps = {
  chef_guests: []
}

export default ChefEdit
