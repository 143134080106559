
export const ROOT_URL='/'

export const APP_URLS = {
  createChef: '/food/chefs',
  fetchAI: '/food/chefs/available_ingredients',
  fetchUI: '/food/chefs/unavailable_ingredients',
  fetchAR: '/food/chefs/available_recipes',
  fetchAD: '/food/chefs/active_diets',
  fetchMembers: '/food/members',
  fetchFoodContacts: '/food/contacts',
  updatePermissions: '/food/users',
  fetchMeals: '/food/meals',
  fetchMeal: '/food/meals',
  createMeal: '/food/meals',
  updateMeal: '/food/meals',
  fetchChefGuests: '/food/chef_guests',
  fetchRecipes: '/food/recipes',
  fetchRecipe: '/food/recipes',
  createRecipe: '/food/recipes',
  updateRecipe: '/food/recipes',
  fetchIngredients: '/food/ingredients',
  fetchShops: '/food/shops',
  fetchOrders: '/food/orders',
  fetchOrder: '/food/orders',
  createOrder: '/food/orders',
  updateOrder: '/food/orders',
  fetchStocks: '/food/stocks',
  createStock: '/food/stocks',
  updateStock: '/food/stocks',
  joinRoom: '/chefy/join',
  leaveRoom: '/chefy/leave',
  createFriendRequest: id => `/food/users/${id}/add_friend`,
  updateFriendship: id => `/food/users/${id}/update_friendship`,
  updateIngredient: '/food/ingredients',
  fetchCategories: '/food/categories',
  updateCategory: '/food/categories',
  deleteCategory: '/food/categories',
  createCategory: '/food/categories',
  fetchCategory: '/food/categories',
  fetchIngredient: '/food/ingredients',
  fetchDiets: '/food/diets',
  fetchDiet: '/food/diets',
  createDiet: '/food/diets',
  updateDiet: '/food/diets',
  fetchChefGuest: '/food/chef_guests',
  updateChefGuest: '/food/chef_guests',
  fetchRecipesCategories: '/food/recipes/categories',
  fetchRecipesCategory: '/food/recipes/category',
  updateRecipesCategory: '/food/recipes/category',
  createRecipesCategory: '/food/recipes/category',
  fetchShop: '/food/shops',
  updateShop: '/food/shops',
  scheduleMeals: '/food/meals/schedule',
  fetchChefs: '/food/chefs',
  fetchChef: '/food/chefs',
  updateChef: '/food/chefs',
  updateUserInfo: '/food/users/info',
}
