import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connectModule } from 'redux-modules';

import loopProvider from '../../decorators/loopProvider';
import mealsModule from '../../redux/modules/meals';

import Lifecycle from '../../decorators/Lifecycle';

const NotificationWebSocket = ({
    cableApp,
    updateApp,
    chef_id,
    actions: {
      recieveNotifications
    },
    ...props
  }) =>  {

  const connectNotification = () => {
    if (!cableApp.notification && !!chef_id) {
      cableApp.notification = cableApp.cable.subscriptions.create({
          channel: 'NotificationChannel',
          chef_id: chef_id
        },
        {
          received: json => {
            recieveNotifications(json)
          },
        }
      )
    }
  }
  return(
    <Lifecycle
      onUpdate={ () => connectNotification() }
    >
      <div></div>
    </Lifecycle>
  )
}

NotificationWebSocket.propTypes = {
  actions: PropTypes.shape({
    updateHostRoomSuccess: PropTypes.func,
    joinGuestRoomSuccess: PropTypes.func,
    recieveNotifications: PropTypes.func
  }),
  cableApp: PropTypes.shape({
    room: PropTypes.any,
    user: PropTypes.any,
    notification: PropTypes.any,
    cable: PropTypes.shape({
      subscriptions: PropTypes.shape({
        create: PropTypes.func
      })
    })
  }),
  user: PropTypes.shape({
    rooms: PropTypes.array,
    rooms_user: PropTypes.object
  }),
  updateApp: PropTypes.func,
  chef_id: PropTypes.number
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(NotificationWebSocket);
