import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connectModule } from 'redux-modules';
import { compose } from 'recompose';

import Lifecycle from '../../decorators/Lifecycle';
import loopProvider from '../../decorators/loopProvider';
import mealsModule from '../../redux/modules/meals';

import { postMessage } from '../../services/apiService';

import { CableApp } from '../../shared/utils';

import ChatWebsocket from './ChatWebsocket'

import ChatMessage from '../../components/Chat/ChatMessage';
import ChatUserList from '../../components/Chat/ChatUserList';

const I18n = window.I18n;

const initRoom = {users: [], messages: [], stats: 0};
const initMessage = {content: '', user_id: '', room_id: ''}

const ChatMainRoom = ({chef_name, user: { rooms, rooms_user }, actions,...props}) => {
  const [ room, setRoom ] = useState((rooms||[]).find( e => e.name === 'Main') || initRoom);
  const [ message, setMessage ] = useState(initMessage);
  const [ selected, setSelected ] = useState('')

  const inputRef = useRef();
  const messagesRef = useRef();

  const updateApp = ({room}) => { console.log(room); setRoom(room) }

  const submitMessage = () =>
    message.content && postMessage({message: message})
      .then( () => {
        setMessage(Object.assign(initMessage, {
          user_id: rooms_user.id,
          room_id: room.id
        }))
      })
      .then( () => {
        inputRef.current.value = ''
      });

  const onChange = e =>
    setMessage({
      content: e.target.value,
      user_id: rooms_user.id,
      room_id: room.id
    })

  let userScrolled = false

  const setUserScroll = () => {
      const divHeight = messagesRef.current.scrollTop + messagesRef.current.offsetHeight

      if (divHeight == messagesRef.current.scrollHeight) {
        userScrolled = false
      } else {
        userScrolled = true
      }
    };

  const updateScroll = () => {
    if (userScrolled) return

    new Promise(resolve => {
      resolve(messagesRef.current.removeEventListener('scroll', setUserScroll))
    }).then(setTimeout(() => {
      if (messagesRef.current) {
        messagesRef.current.scrollTop = messagesRef.current.scrollHeight
      }
    }, 100)).then(setTimeout(() => {
      if (messagesRef.current) {
        messagesRef.current.addEventListener('scroll', setUserScroll)
      }
    }, 300))
  };

  const joinMainRoom = (chef_name) => {
    // console.log('joinMainRoom', chef_name)
    if ((rooms||[]).length === 0 ) {
      actions.joinRoom({username: chef_name})
        .then( updateScroll )
        .then( () => {
          CableApp.room.send({content: 'Welcome Message'});
        })
        .then( () => { console.log('Content Sent') })
    }
  }

  const cleanUp = (rooms_user) => {
    if (messagesRef.current) {
      messagesRef.current.removeEventListener('scroll', setUserScroll)
    }
    actions.leaveRoom({username: rooms_user.name, roomname: 'Main'})
  }

  return (
    <Lifecycle
      onMount={ () => joinMainRoom(chef_name) }
      onUpdate={ updateScroll }
      onUnmount={ () => cleanUp(rooms_user) }
    >
      <div id='chat-stats'>Stats: Rooms: {(rooms||[]).length} Users: {room.stats.users||0}</div>
      <div id="chat-main">
        <ChatWebsocket
          cableApp={CableApp}
          updateApp={ props => updateApp(props) }
          chef_name={chef_name}
          room={room}
        />
        <div className='chat-sidebar'>
          <ChatUserList
            users={room.users}
            chef_name={chef_name}
            rooms_user={rooms_user}
            selected={selected}
            setSelected={setSelected}
          />
        </div>
        <div id='chat-feed'>
          <div className='messages' ref={messagesRef}>
            {
              room.messages.map( message =>
                <ChatMessage key={message.id}
                  message={message}
                  setSelected={setSelected}
                  setMessage={setMessage}
                  private_msg={false}
                />)
            }
          </div>
        </div>
        <form id='chat-form' onSubmit={ e => e.preventDefault() }>
          <div>
            <input ref={inputRef} id='message' type='text' name='content'
              onChange={ e => onChange(e) }
              autoComplete="off"
              value={message.content}
            />
            <button onClick={ submitMessage }>{I18n.t('views.cards.actions.send')}</button>
          </div>
        </form>
      </div>
    </Lifecycle>
  )
};

ChatMainRoom.propTypes = {
  actions: PropTypes.shape({
    fetchAI: PropTypes.func,
    joinRoom: PropTypes.func,
    leaveRoom: PropTypes.func
  }),
  user: PropTypes.shape({
    chefs: PropTypes.array,
    rooms_user: PropTypes.object,
  }),
  chef_name: PropTypes.string
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(ChatMainRoom);
