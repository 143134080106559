import React from 'react';
import PropTypes from 'prop-types';
import { connectModule } from 'redux-modules';
import { Route } from 'react-router';
import { BrowserRouter } from 'react-router-dom';

import { compose } from 'recompose';

import Lifecycle from '../../decorators/Lifecycle';
import loopProvider from '../../decorators/loopProvider';
import mealsModule from '../../redux/modules/meals';

import MembersList from './MembersList';
import MemberShow from './MemberShow';

import { ROOT_URL } from '../../shared/config';

const Members = ({actions, user: { chefs }, setRouteState }) => {
  const root = ROOT_URL.length > 1 ? ROOT_URL : '';

  return (
    <Lifecycle
      onMount={ () => {
          setRouteState()
        }
      }
    >
      <BrowserRouter
        initialEntries={[`${root}/members`]}
        initialIndex={0}
      >
        <Route path={`${root}/members`} exact render={ routeProps =>
          <MembersList {...routeProps}/>
        }/>
        <Route path={`${root}/members/:id`} exact render={ routeProps =>
          <MemberShow {...routeProps}/>
        }/>
      </BrowserRouter>
    </Lifecycle>
  )
};

Members.propTypes = {
  setRouteState: PropTypes.func,
  actions: PropTypes.shape({
    fetchAI: PropTypes.func
  }),
  user: PropTypes.shape({
    chefs: PropTypes.array
  })
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(Members);
