import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Lifecycle from '../../decorators/Lifecycle';

import { IngredientItem } from '../../components/Ingredients';
import { DashboardSubHeader } from '../../shared/components';
import { orderByName } from '../../shared/utils';

const I18n = window.I18n;

const AvailableIngredients = ({ fetchAI, chef, match: { path }, history }) => {
  const ingredients = chef.ai ? orderByName(chef.ai) : [];
  const selectRef = useRef();
  const onChange = e => {
    history.push(e.currentTarget.value)
  }

  const IngredientsList = ({ingredients}) => {
    return(
      <React.Fragment>
        {
          ingredients.map( ingr => <IngredientItem key={ingr.id} {...ingr} chef_id={chef.id}/>)
        }
      </React.Fragment>
    )
  }

  IngredientsList.propTypes = {
    ingredients: PropTypes.array
  }

  return(
    <Lifecycle
      onMount={ () => {
        fetchAI()
      }}
    >
      <DashboardSubHeader
        title={I18n.t('views.reports.available_ingredients')}
        onChange={onChange}
        selectRef={selectRef}
        path={path}
      />
      <IngredientsList ingredients={ingredients}/>
    </Lifecycle>
  )
}

AvailableIngredients.propTypes = {
  fetchAI: PropTypes.func,
  chef: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    ai: PropTypes.any
  }),
  match: PropTypes.shape({
    path: PropTypes.string
  }),
  history: PropTypes.shape({
    push: PropTypes.func
  })
}

export default AvailableIngredients;
