import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { compose } from 'recompose';
import { connectModule } from 'redux-modules';

import Lifecycle from '../../decorators/Lifecycle';

import loopProvider from '../../decorators/loopProvider';
import mealsModule from '../../redux/modules/meals';

import CardWrapper from '../../components/Navigation/CardWrapper';
import { formatMondayBaseWeekday, sundayBasedWeekday } from '../../shared/utils'

const I18n = window.I18n;

const DietsView = ({ diets=[], user, actions, match, history }) => {
  const diet = diets.find( e => e.id === parseInt(match.params.id) )||{ diet_items: [] };
  const {
    active_diets: { periods, seasons, days, meal_periods, offset, dietable_types }
  } = (user.reports || {
    active_diets: { periods: [], seasons: [], days: [], meal_periods: [], offset: 0, dietable_types: [] }
  });

  const RenderDay = ({ day, item, index }) => {
    const tOrEmpty = (value, scope) =>
      !value ? '' : I18n.t(value, scope)
    const nameOrEmpty = (value) =>
      !value ? '' : value.name


    return(
      <ul>
        <li className={cn('dietable-preriod', { bold: (index === 0)})}>
          {index === 0 ? day : ''}
        </li>
        <li className='dietable-meal'>
          { tOrEmpty(item.meal_period, { scope: 'views.cards.forms.diet.meals.meal_periods'}) }
        </li>
        <li className='dietable-type'>
          { tOrEmpty(item.dietable_type, { scope: 'views.cards.forms.diet.dietable_types'}) }
        </li>
        <li className='dietable-name'>
          { nameOrEmpty(item.dietable) }
        </li>
      </ul>
    )
  }

  RenderDay.propTypes = {
    day: PropTypes.string,
    period: PropTypes.number,
    item: PropTypes.object,
    index: PropTypes.number,
  }

  RenderDay.defaultProps = {
    item: {}
  }


  const RednerWeek = ({days, items}) => {
    return days.map( (day, index) => {
        const wday = sundayBasedWeekday(index)

        if ((items||[]).find( i => i.period === wday) ) {
          return(
            <React.Fragment key={`day_${day}_period_${wday}`}>
              {
                items.filter( (i) => i.period === wday )
                     .map( (item, index) =>
                       <RenderDay
                         key={`day_${day}_period_${wday}_${index}`}
                         day={formatMondayBaseWeekday(day, offset)}
                         period={wday}
                         item={item}
                         index={index}
                       />
                     )
              }
            </React.Fragment>
          )
        } else {
          return <RenderDay key={`day_${day}_period_${wday}`} day={formatMondayBaseWeekday(day, offset)} period={wday} index={0}/>
        }
      }
    )
  }

  RednerWeek.propTypes = {
    days: PropTypes.array,
    items: PropTypes.array
  }

  return (
    <Lifecycle
      onMount={()=> {
          actions.fetchDiet({ id: match.params.id }, { meta: 'whateva' })
        }}
      >
      <CardWrapper title={I18n.t('views.cards.diets')} className={'diets'} match={match} history={history}>
        <div className='fieldset'>
          <label htmlFor='diet-name'>{I18n.t('views.cards.forms.diet.name')}</label>
          <span>{diet.name}</span>
        </div>
        <div className='fieldset'>
          <label htmlFor='diet-period'>{I18n.t('views.cards.forms.diet.period')}</label>
          <span>{ I18n.t(diet.period, { scope: 'views.cards.forms.diet.periods'}) }</span>
        </div>
        <div className='fieldset'>
          <label htmlFor='diet-season'>{I18n.t('views.cards.forms.diet.season')}</label>
          <span>{ I18n.t(diet.season, { scope: 'views.cards.forms.diet.seasons'}) }</span>
        </div>
        <ul className='header'>
          <li className='dietable-preriod'>{I18n.t('views.cards.forms.diet.day')}</li>
          <li className='dietable-meal'>{I18n.t('views.cards.forms.diet.meals.meal_period')}</li>
          <li className='dietable-type'>{I18n.t('views.cards.forms.diet.diet_item')}</li>
          <li className='dietable-name'>{I18n.t('views.cards.forms.diet.diet_item_name')}</li>
        </ul>
        <RednerWeek days={days} items={diet.diet_items}/>
      </CardWrapper>
    </Lifecycle>
  );
}

DietsView.propTypes = {
  diets: PropTypes.array,
  actions: PropTypes.shape({
    fetchDiet: PropTypes.func,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }).isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      state: PropTypes.object
    })
  }).isRequired,
  user: PropTypes.shape({
    chef_id: PropTypes.number,
    reports: PropTypes.shape({
      active_diets: PropTypes.shape({
        periods: PropTypes.array,
        seasons: PropTypes.array,
        days: PropTypes.array,
        offset: PropTypes.number,
        meal_periods: PropTypes.array,
        dietable_types: PropTypes.array,
      }),
    })
  })
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(DietsView);
