import React from "react"
import PropTypes from "prop-types"
import { v4 } from "uuid"
import { ModuleProvider } from "redux-modules"
import { combineReducers } from "redux-loop"
import createStore from "./createStore"
import { curry } from "ramda"

function _getStore(providerKey, fixedLoggerOrder = false) {
  if (!window.stores) {
    window.stores = {}
  }

  if (!window.stores[providerKey]) {
    window.stores[providerKey] = createStore(
      {},
      state => state,
      fixedLoggerOrder
    )
  }

  return window.stores[providerKey]
}

export const bootstrap = (Component, fixedLoggerOrder = false) => {
  return class Bootstrapped extends React.Component {
    static propTypes = {
      providerKey: PropTypes.string.isRequired
    }

    static defaultProps = {
      providerKey: v4()
    }

    constructor(props) {
      super(props)
      this.store = _getStore(props.providerKey, fixedLoggerOrder)
    }

    render() {
      return (
        <ModuleProvider store={this.store} combineReducers={combineReducers}>
          <Component {...this.props} />
        </ModuleProvider>
      )
    }
  }
}

export default curry(bootstrap)
