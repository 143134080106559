import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connectModule } from 'redux-modules';
import { MemoryRouter, Route } from 'react-router';
import { compose } from 'recompose';

import Lifecycle from '../../decorators/Lifecycle';
import loopProvider from '../../decorators/loopProvider';
import mealsModule from '../../redux/modules/meals';

import Notification from '../../components/Notification/Notification';
import { Card } from '../../shared/components';
import CardControl from '../../components/CardControl';

const I18n = window.I18n;

import Chefs from './Chefs';
import AvailableIngredients from './AvailableIngredients';
import AvailableRecipes from './AvailableRecipes';
import UnavailableIngredients from './UnavailableIngredients';
import ActiveDiets from './ActiveDiets';
import Schedule from './Schedule';
import ScheduleMeals from './ScheduleMeals';

const Dashboard = ({actions, user: { chefs: all_chefs, chef_ids, reports }, ingredients, setRouteState, routeState }) => {
  const chefs = (all_chefs || [] ).filter( e => chef_ids.indexOf(e.id) > -1 )
  const chef  = chefs[0] || {};
  const [state, setCurrentChef] = useState(chef);

  state

  // console.log(reports)

  return (
    <Lifecycle
      onMount={ () => {
        setRouteState()
        actions.fetchReports()
      }
    }>
      <Card title={I18n.t('views.cards.dashboard')} className='dashboard'>
        <MemoryRouter
          initialEntries={["/schedule"]}
          initialIndex={0}
        >
          <Route path='/' render={ routeProps =>
            <Notification {...routeProps} types={['Chat::Room', 'Food::Ingredient']}/>
          }/>
          <Route path={'/schedule'} render={ routeProps =>
            <React.Fragment>
              <Chefs
                chefs={chefs}
                setCurrentChef={setCurrentChef}
                {...routeProps}
              />
              <Schedule
                chef={chef}
                reports={reports}
                routeState={routeState}
                {...routeProps}
              />
            </React.Fragment>
          }/>
          <Route path={'/activeDiets'} render={ routeProps =>
            <React.Fragment>
              <Chefs
                chefs={chefs}
                setCurrentChef={setCurrentChef}
                {...routeProps}
              />
              <ActiveDiets
                chef={chef}
                reports={reports}
                fetchAD={actions.fetchAD}
                fetchCategories={actions.fetchCategories}
                fetchRecipesCategories={actions.fetchRecipesCategories}
                fetchChefGuests={actions.fetchChefGuests}
                {...routeProps}
              />
            </React.Fragment>
          }/>
          <Route path={'/availableIngredients'} render={ routeProps =>
            <React.Fragment>
              <Chefs
                chefs={chefs}
                setCurrentChef={setCurrentChef}
                {...routeProps}
              />
              <AvailableIngredients
                chef={chef}
                fetchAI={actions.fetchAI}
                {...routeProps}
              />
            </React.Fragment>
          }/>
          <Route path={'/availableRecipes'} render={ routeProps =>
            <React.Fragment>
              <Chefs
                chefs={chefs}
                setCurrentChef={setCurrentChef}
                {...routeProps}
              />
              <AvailableRecipes
                chef={chef}
                fetchAR={actions.fetchAR}
                ingredients={ingredients}
                {...routeProps}
              />
            </React.Fragment>
          }/>
          <Route path={'/unavailableIngredients'} render={ routeProps =>
            <React.Fragment>
              <Chefs
                chefs={chefs}
                setCurrentChef={setCurrentChef}
                {...routeProps}
              />
              <UnavailableIngredients
                chef={chef}
                fetchUI={actions.fetchUI}
                {...routeProps}
              />
            </React.Fragment>
          }/>
          <Route path={'/scheduleMeals'} render={ routeProps =>
            <React.Fragment>
              <Chefs
                chefs={chefs}
                setCurrentChef={setCurrentChef}
                {...routeProps}
              />
              <ScheduleMeals
                chef={chef}
                reports={reports}
                {...routeProps}
              />
            </React.Fragment>
          }/>
          <Route path={'/:action/:id?'} render={ props =>
            <CardControl {...props} />
          }/>
        </MemoryRouter>
      </Card>
    </Lifecycle>
  )
};

Dashboard.propTypes = {
  actions: PropTypes.shape({
    fetchReports: PropTypes.func,
    fetchAI: PropTypes.func,
    fetchAR: PropTypes.func,
    fetchUI: PropTypes.func,
    fetchAD: PropTypes.func,
    fetchMeals: PropTypes.func,
    fetchRecipes: PropTypes.func,
    fetchCategories: PropTypes.func,
    fetchRecipesCategories: PropTypes.func,
    fetchChefGuests: PropTypes.func,
    setScheduleFormElement: PropTypes.func,
  }),
  user: PropTypes.shape({
    chefs: PropTypes.array,
    chef_ids: PropTypes.array,
    reports: PropTypes.shape({
      active_diets: PropTypes.object
    })
  }),
  ingredients: PropTypes.array,
  setRouteState: PropTypes.func
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(Dashboard);
