import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { connectModule } from 'redux-modules';
import { compose } from 'recompose';

import loopProvider from '../../../decorators/loopProvider';
import mealsModule from '../../../redux/modules/meals';

import Lifecycle from '../../../decorators/Lifecycle';
import CardWrapper from '../../../components/Navigation/CardWrapper';

const I18n = window.I18n;

const RecipeCategoryNew = ({ recipes, recipes_categories, match, actions, history }) => {
  const [recipeItems, setRecipeItems] = useState([]);
  const [selectOptions, setSelectOptions ] = useState(recipes);

  const formRef = useRef();
  const recipeRef = useRef();

  const onRecipeChange = e => {
    const value = e.currentTarget.value;
    const text  = recipes.find( e => e.id === parseInt(value) ).name

    setRecipeItems( prev => ([...prev, { id: value, name: text } ]) );
    setSelectOptions( prev => prev.filter( e => e.id !== parseInt(value)) );

    recipeRef.current.value = ''
  }

  useEffect( () => {
    actions.setRecipesCategoryFormElement(formRef.current)
  }, [])

  useEffect( () => {
    setSelectOptions(recipes)
  }, [recipes])

  return(
    <Lifecycle
      onMount={()=> {
        if (recipes.length === 0) {
          actions.fetchRecipes()
            .then( () => actions.fetchRecipesCategories() )
        } else {
          actions.fetchRecipesCategories()
        }
      }}
    >
      <CardWrapper title={I18n.t('views.cards.settings')} className={'categories'} match={match} history={history}>
        <h2>{I18n.t('views.cards.recipes_category')}</h2>
        <form ref={formRef} action="">
          <input type="text" name='recipes_category[id]' defaultValue={'null'} autoComplete="off" hidden={true}/>
          <ul>
            <li>{I18n.t('views.cards.forms.recipes_categories.name')}</li>
            <li>
              <input type="text"
                name="recipes_category[name]"
                autoComplete="off"
              />
            </li>
          </ul>
          <ul>
            <li>{I18n.t('views.cards.forms.recipes_categories.recipes')}</li>
            <li>
              <select onChange={onRecipeChange} ref={recipeRef}>
                <option key='null' value='' defaultValue=''>{I18n.t('views.cards.actions.quick_select')}</option>
                {
                  selectOptions.map( (i, index) =>
                    <option key={index} value={i.id}>{i.name}</option>
                  )
                }
              </select>
            </li>
          </ul>
          {
            recipeItems.map( (i, index) =>
              <ul key={index}>
                <li></li>
                <li>
                  <input type="text" name='recipes_category[recipes][id][]' defaultValue={i.id} autoComplete="off" hidden={true}/>
                  {i.name}
                </li>
              </ul>
            )
          }
        </form>
      </CardWrapper>
    </Lifecycle>
  )
}

RecipeCategoryNew.propTypes = {
  actions: PropTypes.shape({
    fetchRecipes: PropTypes.func,
    fetchRecipesCategories: PropTypes.func,
    fetchRecipesCategory: PropTypes.func,
      setRecipesCategoryFormElement: PropTypes.func
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.object
  }),
  recipes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  recipes_categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      recipes: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string
        })
      )
    })
  )
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(RecipeCategoryNew);
