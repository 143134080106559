import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router';
import { Switch } from 'react-router-dom';

import { compose } from 'recompose';
import { connectModule } from 'redux-modules';

import Lifecycle from '../decorators/Lifecycle';
import loopProvider from '../decorators/loopProvider';
import mealsModule from '../redux/modules/meals';

import CardWrapper from '../components/Navigation/CardWrapper';
import { ROOT_URL } from '../shared/config';

const I18n = window.I18n;

import DietsNew from './Diets/DietsNew';
import DietsEdit from './Diets/DietsEdit';
import DietsView from './Diets/DietsView';

const Diets = ({diets, actions, setRouteState}) => {
  const root = ROOT_URL.length > 1 ? ROOT_URL : '';

  const DietsList = ({diets, match, history}) => {
    return(
      <CardWrapper title={I18n.t('views.cards.diets')} className={'diets'} match={match} history={history}>
        {
          diets.map( (e,i) =>
            <a onClick={ () => history.push(`${root}/diets/${e.id}`, { whatever: true }) } className='item' key={`diet_${i}`}>
              <span>{e.name}</span>
              <span>{e.period}</span>
            </a>
          )
        }
      </CardWrapper>
    )
  };

  DietsList.propTypes = {
    diets: PropTypes.array,
    match: PropTypes.object,
    history: PropTypes.object
  }

  return(
    <Lifecycle
      onMount={()=> {
        actions.fetchDiets()
        setRouteState()
      }}
    >
      <Switch>
        <Route path={`${root}/diets`} exact render={ routeProps =>
          <DietsList
            diets={diets}
            {...routeProps}
          />
        }/>
        <Route path={`${root}/diets/new/:id?`} exact render={ routeProps =>
          <DietsNew
            diets={diets}
            {...routeProps}
          />
        }/>
        <Route path={`${root}/diets/edit/:id`} exact render={ routeProps =>
          <DietsEdit
            diets={diets}
            {...routeProps}
          />
        }/>
        <Route path={`${root}/diets/:id`} exact render={ routeProps =>
          <DietsView
            diets={diets}
            {...routeProps}
          />
        }/>
      </Switch>
    </Lifecycle>
  )
};

Diets.propTypes = {
  setRouteState: PropTypes.func,
  urls: PropTypes.object,
  diets: PropTypes.array,
  ingredients: PropTypes.array,
  actions: PropTypes.shape({
    fetchRecipes: PropTypes.func,
    fetchIngredients: PropTypes.func,
    fetchDiets: PropTypes.func,
  }),
  user: PropTypes.object
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(Diets)
