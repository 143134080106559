import React from 'react';
import PropTypes from 'prop-types';

import Lifecycle from '../../../decorators/Lifecycle';
import { ROOT_URL } from '../../../shared/config';

import CardWrapper from '../../../components/Navigation/CardWrapper';

const I18n = window.I18n;

const GuestsList = ({ chef_guests, actions, match, history}) => {
  const root = ROOT_URL.length > 1 ? ROOT_URL : '';

  return(
    <Lifecycle
      onMount={ () => {} }
    >
      <CardWrapper title={I18n.t('views.cards.settings')} className={'settings'} match={match} history={history}>
        <h2>{I18n.t('views.cards.guests')}</h2>
        {
          chef_guests.map( i =>
            <a className='item' key={i.id}
              onClick={ _ => history.push(`${root}/my/guests/${i.id}`)}>{i.name}</a>
          )
        }
      </CardWrapper>
    </Lifecycle>
  )
}

GuestsList.propTypes = {
  actions: PropTypes.shape({
    fetchChefGuests: PropTypes.func
  }),
  chef_guests: PropTypes.array,
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  match: PropTypes.object
}


GuestsList.defaultProps = {
  chef_guests: []
}

export default GuestsList
