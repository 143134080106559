import React from 'react';
import PropTypes from 'prop-types';

import { formatContent } from '../../shared/utils';

export const ChatMessage = ({message, setSelected, setMessage, private_msg}) => {
  return(
    <div key={message.id} className='message'>
      <span className='nick'
        onClick={ () => !private_msg && setSelected(message.sender)}
        onDoubleClick={ () => !private_msg && setMessage( prevSate => ({
            ...prevSate,
            content: prevSate.content.concat(message.sender).concat(' ')
          })
        )}
      >{message.sender} : </span>
      <span className='content'>{formatContent(message.content)}</span>
    </div>
  )
}

ChatMessage.propTypes = {
  message: PropTypes.shape({
    id: PropTypes.number,
    sender: PropTypes.string,
    content: PropTypes.string,
  }),
  setSelected: PropTypes.func,
  setMessage: PropTypes.func,
  private_msg: PropTypes.boolean
}

export default ChatMessage;
